import "./filter-field.css";
import { CrossX } from "../svg";
import classnames from "classnames";
import { createElement, useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { scrollIntoViewAndAlignInstant } from "../../utils/scroll";

export interface ValueData {
  key: string | number;
  object: any;
  displayValue: string;
}

export interface FilterFieldProps {
  inputValue: string;
  placeholder?: string;
  onValueChange(value: string): void;
  values: ValueData[];
  contentRenderer?(value: ValueData): React.ReactNode;
  onSelect?(key: string | number): void;
  isOpen?: boolean;
  icon?: string | React.FunctionComponent<any> | React.ComponentClass<any>;
  isDisabled?: boolean;
}

export function FilterField(props: FilterFieldProps) {
  const {
    inputValue,
    onValueChange,
    values,
    contentRenderer,
    onSelect,
    isOpen,
    placeholder,
    icon,
  } = props;

  let itemRefList: HTMLDivElement[] = [];
  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    if (selectedIndex !== -1)
      scrollIntoViewAndAlignInstant(itemRefList[selectedIndex]);
  });

  const renderContent = () => {
    itemRefList = [];
    return values.map((value: ValueData, index: number) => {
      return renderItem(value, index);
    });
  };

  const renderItem = (value: ValueData, index: number) => {
    const item = contentRenderer ? contentRenderer(value) : value.displayValue;
    return (
      <div
        className={classnames("item", {
          keySelect: selectedIndex === index,
        })}
        ref={(item: HTMLDivElement) => {
          itemRefList.push(item);
        }}
        onClick={() => {
          if (onSelect) onSelect(value.object);
        }}
        key={value.key}
      >
        {item}
      </div>
    );
  };

  const handleClear = () => {
    if (!props.isDisabled) {
      onValueChange("");
      setSelectedIndex(-1);
    }
  };

  return (
    <div
      className={classnames("filter-input-body", {
        disabled: props.isDisabled,
      })}
    >
      <div
        className={classnames("interaction-container", {
          disabled: props.isDisabled,
        })}
      >
        <div
          className={classnames("icon", {
            disabled: props.isDisabled,
            active: inputValue !== "",
          })}
        >
          {icon && createElement(icon)}
        </div>
        <input
          placeholder={placeholder}
          className={classnames("value-input", {
            disabled: props.isDisabled,
          })}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            var key = event.code;
            if (key === "ArrowDown") {
              event.preventDefault();
              if (selectedIndex < values.length - 1)
                setSelectedIndex(selectedIndex + 1);
            }
            if (key == "ArrowUp") {
              event.preventDefault();
              if (selectedIndex > 0) setSelectedIndex(selectedIndex - 1);
            }
            if (key == "Enter") {
              if (selectedIndex !== -1)
                if (onSelect) onSelect(values[selectedIndex].object);
            }
          }}
          value={inputValue}
          onChange={(event) => {
            onValueChange(event.target.value);
            setSelectedIndex(-1);
          }}
          disabled={props.isDisabled}
        ></input>
        {inputValue && (
          <div
            className={classnames("close-button", {
              disabled: props.isDisabled,
            })}
          >
            <IconButton disabled={props.isDisabled} onClick={handleClear}>
              <CrossX />
            </IconButton>
          </div>
        )}
      </div>
      {isOpen && (
        <div className="filter-field-content-container">{renderContent()}</div>
      )}
    </div>
  );
}
