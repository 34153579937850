import "./actionscell.css";
import { EditIcon, DeleteIcon, SaveIcon, CrossIcon } from "../assets";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import SimpleIconButton from "../../buttons/SimpleIconButton";
import { ReactNode } from "react";

export interface ButtonVisibility {
  edit: boolean;
  delete: boolean;
}

export interface ButtonAvailability {
  edit: boolean;
  delete: boolean;
}

export enum BUTTON_TYPE {
  EDIT,
  DELETE,
}

export interface CustomActionButton {
  typeId: number;
  node: ReactNode;
  availability: boolean;
}

export interface ActionsCellProps {
  onButtonClick?(buttonType: BUTTON_TYPE): void;
  buttonVisibility: ButtonVisibility;
  buttonAvailability?: ButtonAvailability;
  customButtons?: CustomActionButton[];
}

export function ActionsCell(props: ActionsCellProps) {
  const { buttonVisibility, customButtons } = props;

  return (
    <div className="actions-cell">
      <div className="actions-cell-button-container">
        {buttonVisibility.edit && EditButton(props)}
        {buttonVisibility.delete && DeleteButton(props)}
        {customButtons &&
          customButtons.map((button: CustomActionButton) => {
            return CustomButton(button, props);
          })}
      </div>
    </div>
  );
}

export function EditButton(props: ActionsCellProps) {
  const { onButtonClick } = props;

  return (
    <div className="edit-actions-button">
      <SimpleIconButton
        disabled={!props.buttonAvailability?.edit}
        onClick={() => {
          if (onButtonClick && props.buttonAvailability?.edit)
            onButtonClick(BUTTON_TYPE.EDIT);
        }}
      >
        <EditIcon
          className={classNames("action-button-icon edit-button-icon", {
            "locked-action-button": !props.buttonAvailability?.edit,
          })}
        />
      </SimpleIconButton>
    </div>
  );
}

export function DeleteButton(props: ActionsCellProps) {
  const { onButtonClick } = props;

  return (
    <div className="edit-actions-button">
      <SimpleIconButton
        disabled={!props.buttonAvailability?.delete}
        onClick={() => {
          if (onButtonClick && props.buttonAvailability?.delete)
            onButtonClick(BUTTON_TYPE.DELETE);
        }}
      >
        <DeleteIcon
          className={classNames("action-button-icon delete-button-icon", {
            "locked-action-button": !props.buttonAvailability?.delete,
          })}
        />
      </SimpleIconButton>
    </div>
  );
}

export function CustomButton(
  button: CustomActionButton,
  props: ActionsCellProps
) {
  const { onButtonClick } = props;

  return (
    <div className="edit-actions-button">
      <SimpleIconButton
        disabled={!button.availability}
        onClick={() => {
          if (onButtonClick && props.buttonAvailability?.edit)
            onButtonClick(button.typeId);
        }}
      >
        {button.node}
      </SimpleIconButton>
    </div>
  );
}
