import styles from "./client-profile-camera-settings.module.css";

import { ClientProfileStore } from "../../store/store";
import ClientProfileCameraSettingsHeader from "./client-profile-camera-settings-header";
import ClientProfileCameraSettingsContent from "./client-profile-camera-settings-content";
import { observer } from "mobx-react";
import { Prompt } from "react-router";
import { Redirect } from "react-router-dom";
import { Component } from "react";

interface IClientProfileCameraSettingsPageProps {
  history?: any;
  clientProfileStore: ClientProfileStore;
  saveCameraSettings(): void;
  cancelCameraSettings(): void;
  t?: any;
}

@observer
export class ClientProfileCameraSettingsPage extends Component<IClientProfileCameraSettingsPageProps> {
  public render() {
    const {
      clientProfileStore,
      history,
      saveCameraSettings,
      cancelCameraSettings,
    } = this.props;
    const { clientProfileDetails, clientProfileCameraSettings } =
      clientProfileStore;
    if (!clientProfileDetails.clientName && !clientProfileDetails.areaName) {
      const linkArr = this.props.history!.location.pathname.split("/");

      const link = `/client-profile/${linkArr[linkArr.length - 1]}`;
      return <Redirect exact to={link} />;
    }
    return (
      <div>
        <ClientProfileCameraSettingsHeader
          clientDetails={clientProfileDetails}
          title={"Video camera views"}
          saveCameraSettings={saveCameraSettings}
          cancelCameraSettings={cancelCameraSettings}
        />
        <div className={styles["client-profile-content"]}>
          <ClientProfileCameraSettingsContent
            cameraSettings={clientProfileCameraSettings}
            history={history}
          />
        </div>
        <Prompt when={clientProfileCameraSettings.hasChanges} message="" />
      </div>
    );
  }
}
