import styles from "./search-camera.module.css";

import { useState } from "react";
import { FilterField, ValueData } from "../../../components/form/filter-field";
import { observer, inject } from "mobx-react";
import { CameraDataStore } from "../store/store";
import { useTranslation } from "react-i18next";
import { Search } from "../../../components/svg";
import { CameraLocation, LocationPath } from "./camera-locations";
import { CameraData } from "../store/types";

export interface SearchCameraProps {
  onSearchChange(): void;
  cameraDataStore?: CameraDataStore;
}

function SearchSensor(props: SearchCameraProps) {
  const [searchValue, setSearchValue] = useState("");
  const [isSearchItemSelected, setIsSearchItemSelected] = useState(false);
  const { cameraDataStore, onSearchChange } = props;
  const { t } = useTranslation("search-camera");

  const searchInputChange = (value: any) => {
    setIsSearchItemSelected(false);
    setSearchValue(value);

    if (value === "") {
      clearSearchSelection();
    }
  };

  const clearSearchSelection = () => {
    const { cameraDataStore } = props;

    if (!cameraDataStore) return;

    cameraDataStore.setHilightCamera(null);
    onSearchChange();
  };

  const getSearchValues = () => {
    if (!searchValue || !cameraDataStore) return [];

    return cameraDataStore.cameras
      .filter((value: CameraData) => {
        return value.cameraName
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      })
      .sort((a: CameraData, b: CameraData) => {
        if (a.cameraName === b.cameraName) return 0;
        return a.cameraName > b.cameraName ? 1 : -1;
      })
      .map((val, index) => {
        return {
          key: index,
          object: val,
          displayValue: val.cameraName,
        };
      });
  };

  const setSearchSelection = (item: any) => {
    cameraDataStore!.setHilightCamera(item);

    setSearchValue(item.cameraName);
    setIsSearchItemSelected(true);
    onSearchChange();
  };

  const searchContentRender = (value: ValueData) => {
    return (
      <div>
        <div className={styles["camera-name"]}>{value.object.cameraName}</div>
        <div className={styles["camera-location"]}>
          <span className={styles["path"]}>
            {LocationPath(value.object.locations)}
          </span>
          <span className={styles["location"]}>
            {CameraLocation(value.object.locations, t)}
          </span>
        </div>
      </div>
    );
  };

  const searchValues = getSearchValues();

  return (
    <FilterField
      icon={Search}
      contentRenderer={searchContentRender}
      placeholder={t("Search camera")}
      inputValue={searchValue}
      values={searchValues}
      onValueChange={(value) => searchInputChange(value)}
      onSelect={setSearchSelection}
      isOpen={!isSearchItemSelected && searchValues.length !== 0}
    ></FilterField>
  );
}

export default inject("cameraDataStore")(observer(SearchSensor));
