import styles from "./page.module.css";

import { Prompt } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { MariDataStore } from "./store/store";
import MariGrid from "./components/manage-maris.grid";
import Header from "./components/header/header";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { TransitionStore } from "../../store";

interface IMariProps {
  mariDataStore: MariDataStore;
  transitionStore: TransitionStore;
}

function Maris(props: IMariProps) {
  const { ready: readyCameraGrid } = useTranslation("mari-grid");
  const { ready: readySearch } = useTranslation("search-mari");
  const { ready: readyCommon } = useTranslation("common");
  const { mariDataStore, transitionStore } = props;
  const {
    isDataLoading,
    isTranslationLoading,
    isSaveLoading,
    isDeleteLoading,
  } = mariDataStore;
  const languagesLoaded = readyCameraGrid && readySearch && readyCommon;

  useEffect(() => {
    return () => {
      const { mariDataStore } = props;

      mariDataStore.resetSelectedData();
      mariDataStore.closeExpanded();
    };
  }, []);

  useEffect(() => {
    if (!languagesLoaded) {
      mariDataStore.setIsTranslationLoading(true);
    } else {
      mariDataStore.setIsTranslationLoading(false);
    }

    if (
      isDataLoading ||
      isTranslationLoading ||
      isSaveLoading ||
      isDeleteLoading
    ) {
      transitionStore.setLoading(true);
    } else {
      transitionStore.setLoading(false);
    }
  });

  const render = () => {
    const { mariDataStore } = props;

    return (
      <>
        {languagesLoaded && (
          <>
            <Header />
            <Prompt
              message={"wait for confirmation"}
              when={mariDataStore.hasSelectedMari}
            />

            <div
              id="grid-container"
              className={styles["manage-maris-grid-container"]}
            >
              <MariGrid />
            </div>
          </>
        )}
      </>
    );
  };

  return render();
}

export default inject("mariDataStore", "transitionStore")(observer(Maris));
