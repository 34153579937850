import styles from "./page.module.css";

import { Prompt } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { CameraDataStore } from "./store/store";
import CameraGrid from "./components/ManageCamerasGrid";
import Header from "./components/header/header";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TransitionStore } from "../../store";
interface ICameraProps {
  cameraDataStore: CameraDataStore;
  transitionStore: TransitionStore;
}

function Cameras(props: ICameraProps) {
  const { ready: readyCameraGrid } = useTranslation("camera-grid");
  const { ready: readyFilter } = useTranslation("filter-camera");
  const { ready: readySearch } = useTranslation("search-camera");
  const { ready: readyCommon } = useTranslation("common");

  const { cameraDataStore, transitionStore } = props;
  const {
    isDataLoading,
    isTranslationLoading,
    isSaveLoading,
    isDeleteLoading,
  } = cameraDataStore;

  const languagesLoaded =
    readyCameraGrid && readyFilter && readySearch && readyCommon;

  useEffect(() => {
    return () => {
      const { cameraDataStore } = props;

      cameraDataStore.resetSelectedData();
    };
  }, []);

  useEffect(() => {
    if (!languagesLoaded) {
      cameraDataStore.setIsTranslationLoading(true);
    } else {
      cameraDataStore.setIsTranslationLoading(false);
    }

    if (
      isDataLoading ||
      isTranslationLoading ||
      isSaveLoading ||
      isDeleteLoading
    ) {
      transitionStore.setLoading(true);
    } else {
      transitionStore.setLoading(false);
    }
  });

  const render = () => {
    const { cameraDataStore } = props;

    return (
      <>
        {languagesLoaded && (
          <>
            <Header />
            <div
              id="grid-container"
              className={styles["manage-camera-grid-container"]}
            >
              <CameraGrid />
            </div>
            <Prompt
              message="wait for confirmation"
              when={cameraDataStore.hasSelectedCamera}
            />
          </>
        )}
      </>
    );
  };

  return render();
}

export default inject("cameraDataStore", "transitionStore")(observer(Cameras));
