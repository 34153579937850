import styles from "../search-clients.module.css";

import { runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ClientOverviewStore } from "../..";
import { BaseTable, SortHeader } from "../../../../components/table";
import { LocationCell } from "../../../../components/table/cells/LocationCell";
import { BasicHeader } from "../../../../components/table/headers/BasicHeader";
import { SORT_DIRECTION } from "../../../../components/table/headers/SortHeader";
import TransitionStore, {
  GlobalDialogData,
} from "../../../../store/transition-store";
import {
  NoConnectionModal,
  NO_CONNECTION_MODAL_ID,
} from "../../../../utils/NoConnectionDialog";
import ClientStatus from "./cells/client-status.cell";
import ClientProfileCell from "./cells/client-profile.cell";
import { ILastLocation } from "../../store/types";
import webResource from "../../web";
import { ResidentLink } from "../cells/ResidentLink";
import { ClientDataConverter } from "../../store/convertor";
import { ClientDataIn } from "../../store/typesIn";

interface IResidentStatusGridProps {
  clientOverviewStore?: ClientOverviewStore;
  transitionStore?: TransitionStore;
}

function ResidentStatusGrid(props: IResidentStatusGridProps) {
  const GRID_REFRESH_RATE_MS = 7500;
  const clientOverviewStore = props.clientOverviewStore!;
  const transitionStore = props.transitionStore!;

  const { t } = useTranslation("client-grid");
  const { t: statusesTranslation } = useTranslation("statuses");

  const history = useHistory();
  const { assignedClients } = clientOverviewStore;
  let gridRefreshTimer: any = undefined;

  useEffect(() => {
    fetchClients();
    resetRefreshTimeout();

    return () => {
      if (gridRefreshTimer) clearTimeout(gridRefreshTimer!);
      transitionStore.setDisableLoadingFeedback(false);
      clientOverviewStore.lastLocations = {};
      clientOverviewStore.lastLocatonsTimers.map((timeout) => {
        clearTimeout(timeout);
      });
      clientOverviewStore.lastLocatonsTimers = [];
    };
  }, []);

  const getSortDirection = (id: string) => {
    if (assignedClients.sortOptions[0].id === id) {
      if (assignedClients.sortOptions[0].desc) {
        return SORT_DIRECTION.DESCENDING;
      } else return SORT_DIRECTION.ASCENDING;
    }
    return SORT_DIRECTION.NONE;
  };

  const columnData: any[] = [
    {
      header: t("Client"),
      cellComponent: (value: any) => (
        <ResidentLink
          value={value.name}
          alertData={{
            alertId: value.alertId,
            alertDelayed: value.alertDelayed,
          }}
          click={() => {
            accessClientDashboard(value.id);
          }}
        />
      ),
      width: "25%",
      HeaderComponent: (
        <SortHeader
          text={t("Client")}
          selected={assignedClients.sortOptions[0].id === "name"}
          sortDirection={getSortDirection("name")}
          locked={false}
          onClick={() => {
            if (assignedClients.sortOptions[0].id === "name") {
              clientOverviewStore.setAssignedClientsSortOptions([
                {
                  id: "name",
                  desc: !assignedClients.sortOptions[0].desc,
                },
              ]);
            } else {
              clientOverviewStore.setAssignedClientsSortOptions([
                { id: "name", desc: false },
              ]);
            }
            fetchClients();
          }}
        />
      ),
    },
    {
      header: t("Room"),
      cellComponent: LocationCell,
      width: "40%",
      HeaderComponent: (
        <SortHeader
          text={t("Room")}
          selected={assignedClients.sortOptions[0].id === "room"}
          sortDirection={getSortDirection("room")}
          locked={false}
          onClick={() => {
            if (assignedClients.sortOptions[0].id === "room") {
              clientOverviewStore.setAssignedClientsSortOptions([
                {
                  id: "room",
                  desc: !assignedClients.sortOptions[0].desc,
                },
              ]);
            } else {
              clientOverviewStore.setAssignedClientsSortOptions([
                { id: "room", desc: false },
              ]);
            }
            fetchClients();
          }}
        />
      ),
    },
    {
      header: t("Status"),
      cellComponent: (props: any) => (
        <ClientStatus
          {...props}
          original={props}
          t={t}
          statusesTranslation={statusesTranslation}
          lastLocations={clientOverviewStore.lastLocations}
          showLastLocation={readClientLocation}
        />
      ),
      HeaderComponent: (
        <SortHeader
          text={t("Status")}
          selected={assignedClients.sortOptions[0].id === "status"}
          sortDirection={getSortDirection("status")}
          locked={false}
          onClick={() => {
            if (assignedClients.sortOptions[0].id === "status") {
              clientOverviewStore.setAssignedClientsSortOptions([
                {
                  id: "status",
                  desc: !assignedClients.sortOptions[0].desc,
                },
              ]);
            } else {
              clientOverviewStore.setAssignedClientsSortOptions([
                { id: "status", desc: false },
              ]);
            }
            fetchClients();
          }}
        />
      ),
    },
    {
      header: t("Client Profile"),
      width: "150px",
      cellComponent: (props: any) => {
        return ClientProfileCell({
          original: props,
          t,
          accessClientProfile: accessClientProfile,
        });
      },
      HeaderComponent: <BasicHeader text={t("Client Profile")} />,
    },
  ];

  const accessClientProfile = (clientId: number) => {
    history!.push(`/client-profile/${clientId}`);
  };

  const accessClientDashboard = (clientId: number) => {
    history!.push(`/client-dashboard/${clientId}`);
  };

  const readClientLocation = (clientId: number) => {
    webResource
      .showLastLocation(clientId)
      .then((ajaxResponse) => {
        clientOverviewStore.lastLocations[clientId] =
          ajaxResponse.data as ILastLocation;

        const timeout = setTimeout(() => {
          clientOverviewStore.lastLocations[clientId] = undefined;
        }, 15 * 1000 /*15 seconds*/);
        clientOverviewStore.lastLocatonsTimers.push(timeout);
      })
      .then(() => {
        fetchClients();
      });
  };

  const fetchClients = (silent: boolean = false) => {
    const { sortOptions } = clientOverviewStore.assignedClients;

    if (!silent) clientOverviewStore.setIsDataLoading(true);

    return webResource
      .readMyClients(sortOptions)
      .then((ajaxResponse) => {
        const response = ajaxResponse.data!;
        const { assignedClientsForNurse, isDataStale } = response;

        const clientList = assignedClientsForNurse.map(
          (client: ClientDataIn) => {
            return ClientDataConverter.toStore(client);
          }
        );

        runInAction(() => {
          clientOverviewStore.clients = clientList;
          clientOverviewStore.isDataStale = isDataStale;
        });
      })
      .then(() => {
        if (transitionStore.id === NO_CONNECTION_MODAL_ID) {
          transitionStore.hideGlobalDialog();
        }
        validateStaleData();
        clientOverviewStore.executeOnReload();
        scrollIntoViewSelectedClient();
      })
      .catch((ajaxError: any) => {
        const dialog: GlobalDialogData = NoConnectionModal(t);

        transitionStore?.showGlobalDialog(dialog);
      })
      .finally(() => {
        if (!silent) {
          clientOverviewStore.setIsDataLoading(false);
        }
      });
  };

  const scrollIntoViewSelectedClient = () => {
    setTimeout(() => {
      const selectedClientElement = document.querySelector("#selected-client");
      if (selectedClientElement) {
        selectedClientElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    });
  };

  const resetRefreshTimeout = () => {
    if (gridRefreshTimer) clearTimeout(gridRefreshTimer);

    const newGridRefreshTimer = setTimeout(
      silentFetchData,
      GRID_REFRESH_RATE_MS
    );
    gridRefreshTimer = newGridRefreshTimer;
  };

  const silentFetchData = () => {
    const resultPromise = fetchClients(true);
    resultPromise.then(() => {
      resetRefreshTimeout();
    });
  };

  const validateStaleData = () => {
    if (!clientOverviewStore.isDataStale) {
      return;
    }

    transitionStore.setGlobalWarningMessage(t("StaleDataMessage"));
  };

  const render = () => {
    const { selectedClient } = clientOverviewStore;
    return (
      <BaseTable
        data={clientOverviewStore.clients.slice()}
        columns={columnData}
        customRowId={(_index, rowData) => {
          if (rowData.id === selectedClient?.id) return "selected-client";
          return "";
        }}
        customRowClass={(_index, rowData) => {
          if (rowData.id === selectedClient?.id)
            return styles["selected-client"];
          return "";
        }}
        expandedRowComponent={<div />}
      ></BaseTable>
    );
  };

  return render();
}
export default inject(
  "clientOverviewStore",
  "transitionStore"
)(observer(ResidentStatusGrid));
