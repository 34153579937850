import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { SelectInput } from "../../../../../components/form-inputs";
import { FeatureStore } from "../../../../../store";
import { AlertDataStore } from "../../../store/store";
import {
  alertDataSchema,
  alertDataSchemaMandatoryTriggerPort,
} from "../../../validation";

export interface IDelayTypeDropDownProps {
  alertDataStore?: AlertDataStore;
  featureStore?: FeatureStore;
}

function DelayTypeDropDown(props: IDelayTypeDropDownProps) {
  const alertDataStore = props.alertDataStore!;
  const featureStore = props.featureStore!;
  const { t } = useTranslation("alert-grid");
  const availableDelayTypes = alertDataStore.delayTypes;
  const setSelectedDelayType = alertDataStore.selectedAlert.delayType;

  const defaultDelayType = availableDelayTypes.find((d) => d.id === 0);

  const buildSensorTypeDropdownData = () => {
    const dropdownData = availableDelayTypes.map((data) => ({
      id: data.id,
      value: t(data.name),
    }));

    return dropdownData;
  };

  const delayTypeValue = buildSensorTypeDropdownData().find((type) => type.id === setSelectedDelayType) || defaultDelayType

  return (
    <SelectInput
      options={buildSensorTypeDropdownData()}
      label={t("delayType").toUpperCase()}
      placeholder={t("delayType")}
      value={delayTypeValue}
      onChange={(_event: any, value: any) => {
        alertDataStore.setSelectedDelayType(value.id);
        let schema = alertDataSchema;

        if (featureStore.features.showTriggerPort) {
          schema = alertDataSchemaMandatoryTriggerPort;
        }
        alertDataStore.validateSelectedAlert(schema);
      }}

    ></SelectInput>
  );
}

export default inject(
  "alertDataStore",
  "featureStore"
)(observer(DelayTypeDropDown));
