import styles from "./statuses.module.css";

import { toJS } from "mobx";
import { StatusItem } from "./status-item";
import { Component } from "react";

interface StatusPanelProps {
  showStatuses?: boolean;
  t: any;
  data: any;
}

export class StatusesPanel extends Component<StatusPanelProps, {}> {
  public render() {
    const { data, t } = this.props;

    const statusPanelContent = (
      <ul className={styles["status-panel-content"]}>
        {data.map((item: any, index: any) => (
          <li key={`status-panel-item-${index}`}>
            <StatusItem original={toJS(item)} t={t} />
          </li>
        ))}
      </ul>
    );
    return <div className={styles["statuses-panel"]}>{statusPanelContent}</div>;
  }
}
