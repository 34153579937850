import styles from "./page.module.css";

import { Prompt } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TransitionStore } from "../../store";
import { AlertDataStore } from "./store/store";
import { inject, observer } from "mobx-react";
import ManageAlertsGrid from "./components/manage-alerts-grid/manage-alerts.grid";
import Header from "./components/header/header";
import { ViewItem, ViewSwitch } from "../../components/view-switch";
import { useEffect } from "react";
import DefaultAlertsGrid from "./components/default-alerts-grid/default-alerts.grid";

interface IAlertProps {
  alertDataStore: AlertDataStore;
  transitionStore: TransitionStore;
}

function Alerts(props: IAlertProps) {
  const { ready: readyCameraGrid } = useTranslation("alert-grid");
  const { ready: readySearch } = useTranslation("search-alert");
  const { ready: readyCommon } = useTranslation("common");
  const { transitionStore, alertDataStore } = props;
  const {
    isDataLoading,
    isTranslationLoading,
    isSaveLoading,
    isDeleteLoading,
  } = alertDataStore;
  const languagesLoaded = readyCameraGrid && readySearch && readyCommon;

  useEffect(() => {
    initializeTabs();
    return () => {
      const { alertDataStore } = props;

      alertDataStore.resetSelectedData();
      alertDataStore.closeExpanded();
    };
  }, []);

  useEffect(() => {
    if (!languagesLoaded) {
      alertDataStore.setIsTranslationLoading(true);
    } else {
      alertDataStore.setIsTranslationLoading(false);
    }

    if (
      isDataLoading ||
      isTranslationLoading ||
      isSaveLoading ||
      isDeleteLoading
    ) {
      transitionStore.setLoading(true);
    } else {
      transitionStore.setLoading(false);
    }
  });

  const render = () => {
    const { alertDataStore } = props;

    return (
      <>
        {languagesLoaded && (
          <>
            <Prompt message={""} when={alertDataStore.hasSelectedAlert} />
            <Header />
            <div
              id="grid-container"
              className={styles["manage-alerts-grid-container"]}
            >
              <ViewSwitch activeKey={alertDataStore.selectedTab}>
                <ViewItem itemKey="manage-alerts">
                  <ManageAlertsGrid />
                </ViewItem>
                <ViewItem itemKey="default-alerts">
                  <DefaultAlertsGrid />
                </ViewItem>
              </ViewSwitch>
            </div>
          </>
        )}
      </>
    );
  };

  const initializeTabs = () => {
    const { alertDataStore } = props;
    alertDataStore.setSelectedTab("manage-alerts");
  };

  return render();
}

export default inject("alertDataStore", "transitionStore")(observer(Alerts));
