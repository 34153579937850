import "./icon-input-field.css";
import { createRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AlertIcon from "../../modules/manage-alerts/components/cells/icon";
import { EditIcon, DeleteIcon } from "../table/assets";
import { Button } from "@mui/material";

interface IIconInputFieldProps {
  iconMemberName: string;
  currentIcon: string;

  onIconChanged(data: FormData): void;
}

export function IconInputField(props: IIconInputFieldProps) {
  const { onIconChanged, iconMemberName } = props;
  const browseRef = createRef<HTMLInputElement>();
  const { t } = useTranslation("common");

  const [currentIcon, setCurrentIcon] = useState<string>(
    props.currentIcon || ""
  );

  const [defaultIcon, setDefaultIcon] = useState<string>(
    props.currentIcon || ""
  );

  useEffect(() => {
    initializeDefaultIcon();
  }, []);

  let fileReader: FileReader;
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event || !event.target || !event.target.files) return;

    if (event.target.files.length === 0) {
      return;
    }

    const data = new FormData();
    data.append(iconMemberName, event.target.files[0]);

    fileReader = new FileReader();
    fileReader.onloadend = onFileLoaded;
    fileReader.readAsDataURL(event.target.files[0]);

    onIconChanged(data);
  };

  const onFileLoaded = (e: ProgressEvent<FileReader>) => {
    const context = fileReader.result;
    if (!context) return;

    setCurrentIcon(context.toString());
  };

  const onRemove = () => {
    setCurrentIcon(defaultIcon);
    const data = new FormData();

    const svgStr =
      '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z"/></svg>';
    const svg = new Blob([svgStr], { type: "image/svg+xml" });

    data.append(iconMemberName, svg);
    onIconChanged(data);
  };

  const browseForFile = () => {
    if (browseRef!.current! === null) return;
    browseRef!.current!.click();
  };

  const hiddenInputComponent = () => {
    return (
      <input
        ref={browseRef}
        className="input-icon-field-input-browse-button"
        accept="image/*"
        type="file"
        name="file"
        value=""
        onChange={onChange}
      />
    );
  };

  const initializeDefaultIcon = () => {
    const svgStr =
      '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z"/></svg>';
    const svg = new Blob([svgStr], { type: "image/svg+xml" });

    var reader = new FileReader();
    reader.readAsDataURL(svg);
    reader.onloadend = function () {
      var base64data = reader.result;
      if (currentIcon === "") {
        setCurrentIcon(base64data!.toString());
      }
      setDefaultIcon(base64data!.toString());
    };
  };

  const editIconComponent = () => {
    return (
      <div className="input-icon-field-button-container">
        <div className="input-icon-field-button">
          <Button
            onClick={browseForFile}
            startIcon={<EditIcon className="input-icon-field-action-icon" />}
          >
            {t("changeIcon").toUpperCase()}
          </Button>
          {hiddenInputComponent()}
        </div>
        <div className="input-icon-field-button">
          <Button
            onClick={onRemove}
            disabled={currentIcon === defaultIcon}
            startIcon={
              <DeleteIcon className="input-icon-field-action-icon input-icon-delete" />
            }
          >
            {t("removeIcon").toUpperCase()}
          </Button>
        </div>
      </div>
    );
  };

  const editIconElements = () => {
    return (
      <div className="input-icon-field-edit">
        <div className="input-icon-field-browse-style">
          <span className="input-icon-field-selected-file">
            <AlertIcon data={currentIcon} />
          </span>
        </div>
        {editIconComponent()}
      </div>
    );
  };

  return editIconElements();
}
