import styles from "./client-profile-camera-settings-content.module.css";

import classNames from "classnames";
import { ClientProfileCameraSettings } from "../../store/types";
import { WithTranslation, withTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Component } from "react";

interface IClientProfileSettingsContentProps extends WithTranslation {
  history?: any;
  cameraSettings: ClientProfileCameraSettings;
  t: any;
}

export enum CameraViewsOnSmartPhone {
  ALLOW_ON_ALARMS = 1,
  ALLOW_ANYTIME,
}

@observer
export class ClientProfileCameraSettingsContent extends Component<
  IClientProfileSettingsContentProps,
  any
> {
  public render() {
    return (
      <div>
        <ul className={styles["flex-container"]}>
          {this.renderCamerasSettingsTitle()}
          {this.renderCameraRadioSettings()}
        </ul>
      </div>
    );
  }

  private renderCamerasSettingsTitle() {
    const { t } = this.props;
    return (
      <li className={`${styles["item"]} ${styles["odd-tab"]}`}>
        <div className={styles["title"]}>
          {t("Video camera views on smartphone")}
        </div>
      </li>
    );
  }

  private renderCameraRadioSettings() {
    const { t } = this.props;
    return (
      <li className={`${styles["item"]} ${styles["even-tab"]}`}>
        <div className={styles["settings-col"]}>
          <div className={styles["settings-radio"]}>
            <input
              className={styles["radio-input"]}
              type="radio"
              checked={!this.isCameraViewsOnAnytime()}
              onChange={() =>
                this.changeCameraSettings(
                  CameraViewsOnSmartPhone.ALLOW_ON_ALARMS
                )
              }
            ></input>
            <div
              onClick={() =>
                this.changeCameraSettings(
                  CameraViewsOnSmartPhone.ALLOW_ON_ALARMS
                )
              }
              className={styles["time-alerts"]}
            >
              <span
                className={classNames({
                  [styles["radio-text"]]: this.isCameraViewsOnAnytime(),
                  [styles["selected-radio-text"]]:
                    !this.isCameraViewsOnAnytime(),
                })}
              >
                {t("Allow camera views only on alerts")}
              </span>
            </div>
          </div>

          <div className={styles["settings-radio"]}>
            <input
              className={styles["radio-input"]}
              type="radio"
              checked={this.isCameraViewsOnAnytime()}
              onChange={() =>
                this.changeCameraSettings(CameraViewsOnSmartPhone.ALLOW_ANYTIME)
              }
            ></input>
            <div
              onClick={() =>
                this.changeCameraSettings(CameraViewsOnSmartPhone.ALLOW_ANYTIME)
              }
              className={styles["time-alerts"]}
            >
              <span
                className={classNames({
                  [styles["radio-text"]]: !this.isCameraViewsOnAnytime(),
                  [styles["selected-radio-text"]]:
                    this.isCameraViewsOnAnytime(),
                })}
              >
                {t("Allow camera views anytime")}
              </span>
            </div>
          </div>
        </div>
      </li>
    );
  }
  private isCameraViewsOnAnytime = () => {
    const { cameraSettings } = this.props;
    return (
      cameraSettings.optionTypeId === CameraViewsOnSmartPhone.ALLOW_ANYTIME
    );
  };
  private changeCameraSettings = (type: CameraViewsOnSmartPhone) => {
    const { cameraSettings } = this.props;
    cameraSettings.optionTypeId = type;
  };
}
export default withTranslation(["client-profile"])(
  ClientProfileCameraSettingsContent
);
