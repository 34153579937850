import "./dropdown.css";
import classnames from "classnames";

import { ArrowDown } from "../../svg";
import { useEffect, useState } from "react";

interface IDropdownProps {
  allowClear?: boolean;
  clearSelectionText?: string;
  defaultValue: any;
  data: any[];
  onChange(callback: (value: any) => void): any;
  showTooltip?: boolean;
  tooltipDelay?: number;
  filterFunction?(elem: any, keyword: string): boolean;
}

enum DropdownDirection {
  NONE,
  UP,
  DOWN,
}

const DROPDOWN_MAX_HEIGHT = 300;
const DROPDOWN_MIN_HEIGHT = 150;
const DROPDOWN_PADDING = 5;
const DROPDOWN_BOTTOM_OFFSET = 1;
const DROPDOWN_TOP_OFFSET = 3;

export function Dropdown(props: IDropdownProps) {
  const contentRef: { [key: string]: any } = {};
  const [isInputVisible, setInputVisible] = useState(false);
  const [tooltipVisibility, setTooltipVisibility] = useState(false);
  const [direction, setDirrection] = useState(DropdownDirection.NONE);
  const [dropDownItems, setDropDownItems] = useState<any[]>([]);
  const [containerHeight, setContainerHeight] = useState(
    DROPDOWN_MAX_HEIGHT + "px"
  );

  const { defaultValue } = props;
  let tooltipRef: any;
  let dropdownRef: any;

  const render = () => {
    return (
      <div className="dropdown">
        {header()}
        {content()}
      </div>
    );
  };

  const setDirectionOfContent = () => {
    if (!document.getElementById("grid-container")) {
      setDirrection(DropdownDirection.DOWN);
      return;
    }

    const fieldRect = dropdownRef.getBoundingClientRect();
    const fieldTop = fieldRect.top;
    const fieldHeight = fieldRect.height;

    const container = document.getElementById("grid-container")!;
    const containerTop = container.getBoundingClientRect().top;
    const containerScrollTop = container.scrollTop;

    const heightShown = container.scrollHeight;

    const bottomSize =
      heightShown -
      (fieldTop + fieldHeight - containerTop + containerScrollTop) -
      DROPDOWN_BOTTOM_OFFSET -
      DROPDOWN_PADDING;

    const topSize =
      fieldTop -
      (containerTop - containerScrollTop) -
      DROPDOWN_TOP_OFFSET -
      DROPDOWN_PADDING;

    if (bottomSize > DROPDOWN_MAX_HEIGHT) {
      setDirrection(DropdownDirection.DOWN);
    } else if (topSize > DROPDOWN_MAX_HEIGHT) {
      setDirrection(DropdownDirection.UP);
    } else {
      if (bottomSize < DROPDOWN_MIN_HEIGHT && topSize < DROPDOWN_MIN_HEIGHT) {
        setContainerHeight(DROPDOWN_MIN_HEIGHT + "px");
        setDirrection(DropdownDirection.DOWN);
        return;
      }
      if (bottomSize > topSize) {
        setContainerHeight(bottomSize + "px");
        setDirrection(DropdownDirection.DOWN);
      } else {
        setContainerHeight(topSize + "px");
        setDirrection(DropdownDirection.UP);
      }
    }
  };

  useEffect(() => {
    if (direction !== DropdownDirection.NONE)
      window.addEventListener("click", handleClickOutside);

    return () => window.removeEventListener("click", handleClickOutside);
  }, [direction]);

  useEffect(() => {
    if (!tooltipRef || !props.showTooltip) return;

    const bounds = dropdownRef.getBoundingClientRect();
    tooltipRef.style.left = bounds.left + bounds.width / 2 + "px";
    tooltipRef.style.top = bounds.top + 10 + "px";

    if (tooltipVisibility === true) tooltipRef.style.visibility = "visible";
  }, [tooltipVisibility]);

  const renderDataItem = () => {
    const { onChange, defaultValue, clearSelectionText, data } = props;

    let totalItems = dropDownItems;

    if (totalItems.length === 0) {
      totalItems = [
        {
          id: 0,
          value: clearSelectionText,
        },
      ];
    }

    const list = totalItems.map((item) => (
      <li
        key={"Dropdown_" + item.id}
        ref={(refItem) => (contentRef[item.id] = refItem)}
        className={classnames("amstel-dropdown-menu-item", "pt-menu-item", {
          "selected-item": item.value === defaultValue,
        })}
        onClick={() => {
          onChange(item);
        }}
      >
        {item.value}
      </li>
    ));

    if (direction !== DropdownDirection.NONE) {
      setTimeout(() => {
        const ref = data.find((item) => item.value === defaultValue);
        if (!ref || !contentRef[ref.id]) return;

        contentRef[ref.id].scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
          alignToTop: true,
        });
      }, 100);
    }

    return list;
  };

  const resetFilter = () => {
    const { data, allowClear, clearSelectionText } = props;
    let items;
    if (allowClear) {
      items = [
        {
          id: 0,
          value: clearSelectionText,
        },
        ...data,
      ];
    } else {
      items = data;
    }
    setDropDownItems(items);
  };

  const toggleDropdown = () => {
    if (direction !== DropdownDirection.NONE) hideContent();
    else showContent();
  };

  const hideContent = () => {
    setInputVisible(false);
    setDirrection(DropdownDirection.NONE);
  };

  const showContent = () => {
    setInputVisible(true);
    tooltipRef.style.visibility = "hidden";
    setTooltipVisibility(false);
    resetFilter();
    setDirectionOfContent();
  };

  const handleClickOutside = () => {
    if (direction === DropdownDirection.NONE) {
      return;
    }
    hideContent();
  };

  const handleFieldClick = () => {
    toggleDropdown();
  };

  const handleMouseEnter = () => {
    if (direction === DropdownDirection.NONE) setTooltipVisibility(true);
  };

  const handleMouseLeave = () => {
    tooltipRef.style.visibility = "hidden";
    if (tooltipVisibility) setTooltipVisibility(false);
  };

  const handleInputChange = (event: any) => {
    const { filterFunction, data } = props;

    if (!filterFunction) return;

    const filterValue = event.target.value;

    setDropDownItems(
      data.filter((item) => {
        return filterFunction(item, filterValue);
      })
    );
    if (filterValue === "") {
      resetFilter();
    }
  };

  const handleStopPropagation = (event: any) => {
    event.stopPropagation();
  };

  const simpleHeader = () => {
    return (
      <>
        <div className="text-container">{defaultValue}</div>
        <div className="icon-container">
          <ArrowDown className="arrow-svg" />
        </div>
      </>
    );
  };

  const searchHeader = () => {
    return (
      <>
        {!isInputVisible && (
          <div className="text-container">{defaultValue}</div>
        )}
        {isInputVisible && (
          <div className="filter-container">
            <input
              className="filter-input"
              onClick={handleStopPropagation}
              onChange={handleInputChange}
            ></input>
          </div>
        )}
        <div className="icon-container">
          <ArrowDown className="arrow-svg" />
        </div>
      </>
    );
  };

  const header = () => {
    const { filterFunction } = props;

    return (
      <div
        ref={(ref: any) => (dropdownRef = ref)}
        className="dropdown-header"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleFieldClick}
      >
        {filterFunction && searchHeader()}
        {!filterFunction && simpleHeader()}
        <div ref={(ref: any) => (tooltipRef = ref)} className="tooltip">
          {defaultValue}
        </div>
      </div>
    );
  };

  const content = () => {
    return (
      <>
        {direction === DropdownDirection.UP && (
          <div className={"dropdown-container"}>
            <div
              style={{ maxHeight: containerHeight }}
              className={"dropdown-content up"}
            >
              <ul className="item-list"> {renderDataItem()}</ul>
            </div>
          </div>
        )}
        {direction === DropdownDirection.DOWN && (
          <div className={"dropdown-container"}>
            <div
              style={{ maxHeight: containerHeight }}
              className={"dropdown-content down"}
            >
              <ul className="item-list"> {renderDataItem()}</ul>
            </div>
          </div>
        )}
      </>
    );
  };

  return render();
}
