import styles from "./page.module.css";

import { Prompt } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { useTranslation } from "react-i18next";
import { TransitionStore } from "../../store";
import { SubstatusDataStore } from "./store/store";
import SubstatusGrid from "./components/statuses-grid/manage-substatuses.grid";
import Header from "./components/header/header";
import { ViewItem, ViewSwitch } from "../../components/view-switch";
import DefaultStatusesGrid from "./components/default-statuses-grid/default-statuses.grid";
import { useEffect } from "react";
interface ISubstatusProps {
  substatusDataStore: SubstatusDataStore;
  transitionStore: TransitionStore;
}

function Substatuses(props: ISubstatusProps) {
  const { ready: readyCameraGrid } = useTranslation("substatus-grid");
  const { ready: readySearch } = useTranslation("search-substatus");
  const { ready: readyCommon } = useTranslation("common");
  const { transitionStore, substatusDataStore } = props;
  const {
    isDataLoading,
    isTranslationLoading,
    isSaveLoading,
    isDeleteLoading,
  } = substatusDataStore;

  const languagesLoaded = readyCameraGrid && readySearch && readyCommon;

  useEffect(() => {
    initializeTabs();
    return () => {
      substatusDataStore.resetSelectedData();
      substatusDataStore.closeExpanded();
    };
  }, []);

  useEffect(() => {
    if (!languagesLoaded) {
      substatusDataStore.setIsTranslationLoading(true);
    } else {
      substatusDataStore.setIsTranslationLoading(false);
    }

    if (
      isDataLoading ||
      isTranslationLoading ||
      isSaveLoading ||
      isDeleteLoading
    ) {
      transitionStore.setLoading(true);
    } else {
      transitionStore.setLoading(false);
    }
  });

  const render = () => {
    return (
      <>
        {languagesLoaded && (
          <>
            <Header />
            <div
              id="grid-container"
              className={styles["manage-substatuses-grid-container"]}
            >
              <ViewSwitch activeKey={substatusDataStore.selectedTab}>
                <ViewItem itemKey="manage-substatuses">
                  <SubstatusGrid />
                </ViewItem>
                <ViewItem itemKey="default-substatuses">
                  <DefaultStatusesGrid />
                </ViewItem>
              </ViewSwitch>
            </div>
            <Prompt
              message="wait for confirmation"
              when={substatusDataStore.hasSelectedSubstatus}
            />
          </>
        )}
      </>
    );
  };

  const initializeTabs = () => {
    substatusDataStore.setSelectedTab("manage-substatuses");
  };

  return render();
}

export default inject(
  "substatusDataStore",
  "transitionStore"
)(observer(Substatuses));
