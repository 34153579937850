import styles from "./client-profile-sensor-issues-tab.module.css";

import classNames from "classnames";
import { SensorIssuesInfo } from "../../store/types";
import { WithTranslation, withTranslation } from "react-i18next";
import { SearchStore, AuthStore } from "../../../../store";
import { inject } from "mobx-react";
import * as H from "history";
import { ReactComponent as WarningIcon } from "../../../manage-sensors/svg/warning.svg";
import { ReactComponent as LowBattery } from "../../../manage-sensors/svg/lowbattery.svg";
import { ReactComponent as Inactive } from "../../../manage-sensors/svg/inactive.svg";
import { ReactComponent as Disconnected } from "../../../manage-sensors/svg/disconnected.svg";
import { Component } from "react";

interface IClientProfileSensorIssuesTabProps extends WithTranslation {
  t: any;
  searchStore?: SearchStore;
  authStore?: AuthStore;
  sensorIssues: SensorIssuesInfo[];
  history?: H.History;
}

export enum SensorState {
  Undefined = 0xff,
  Active = 0x0001,
  Inactive = 0x0002,
  Storage = 0x0004,
  Disconnected = 0x0008,
  BatteryLow = 0x0010,
  NotAvailable = 0x0020,
  SetForTooLong = 0x0040,
}

@inject("searchStore")
@inject("authStore")
export class ClientProfileSensorIssuesTab extends Component<IClientProfileSensorIssuesTabProps> {
  public render() {
    const { sensorIssues, authStore, t } = this.props;

    if (!sensorIssues) return null;

    const items = sensorIssues.map((issue) => {
      const StatusIcon = this.getIcon(issue.status);
      const status = this.getStatusText(issue.status);
      const type = this.getTypeText(issue.type, issue.id, issue.template);
      const onClickHandler = authStore!.isNurseAdmin()
        ? () => this.goToManageSensors(issue.id)
        : () => {};
      const isWarningStatus = this.isWarning(issue.status);

      return (
        <div
          className={classNames(styles["sensor-issue"], {
            [styles["clickable"]]: authStore!.isNurseAdmin(),
          })}
          key={issue.id}
          onClick={onClickHandler}
        >
          <div className={styles["sensor-issues-icon"]}>
            <StatusIcon
              className={classNames(styles["text"], {
                [styles["warning"]]: isWarningStatus,
                [styles["critical"]]: !isWarningStatus,
              })}
              width="40px"
              height="40px"
            />
          </div>

          <div className={styles["text"]}>
            {type} - {status}
          </div>
        </div>
      );
    });

    if (sensorIssues === undefined || !sensorIssues.length) {
      return null;
    }

    return (
      <div className={styles["tabs-container"]}>
        <li className={`${styles["item"]} ${styles["odd-tab"]}`}>
          <div className={styles["title"]}> {t("Sensor issues")} </div>
        </li>
        <li className={`${styles["item"]} ${styles["even-tab"]}`}>
          <div className={`${styles["col-1"]} ${styles["critical"]}`}>
            <WarningIcon width="40px" height="40px" />
          </div>
          <div className={styles["col-234"]}>{items}</div>
          <div className={styles["col-4"]} />
        </li>
      </div>
    );
  }

  private hasFlag(state: number, expectedState: SensorState): boolean {
    return (state & expectedState) === expectedState;
  }

  private goToManageSensors = (sensorId: string) => {
    const { searchStore, history } = this.props;
    searchStore!.sensorSearch = sensorId;

    setTimeout(() => {
      history!.push(`/manage-sensors`);
    });
  };

  private getIcon(status: number): any {
    if (status === SensorState.Undefined) return;

    if (this.hasFlag(status, SensorState.Inactive)) return Inactive;
    if (this.hasFlag(status, SensorState.Disconnected)) return Disconnected;
    if (this.hasFlag(status, SensorState.SetForTooLong)) return WarningIcon;
    if (this.hasFlag(status, SensorState.BatteryLow)) return LowBattery;
  }

  private isWarning(status: number) {
    if (status === SensorState.Undefined) return false;

    return (
      this.hasFlag(status, SensorState.Inactive) === false &&
      this.hasFlag(status, SensorState.Disconnected) === false
    );
  }

  private getStatusText(status: number) {
    const { t } = this.props;
    if (status === SensorState.Undefined) return;

    if (this.hasFlag(status, SensorState.Inactive)) return t("Inactive");

    if (this.hasFlag(status, SensorState.Disconnected))
      return t("Disconnected");

    if (this.hasFlag(status, SensorState.SetForTooLong))
      return t("SetForTooLong");

    if (this.hasFlag(status, SensorState.BatteryLow)) return t("Battery low");
  }

  private getTypeText(type: number, id: string, template: string) {
    const { t } = this.props;
    switch (type) {
      case 0:
        return t("Unknown");
      case 1:
        return t("Bed");
      case 2:
        return t("Room");
      case 4:
        return t("Door");
      case 8:
        return t("Bathroom");
      case 16:
        return t("Chair");
      case 32:
        return t("Pendant");
      case 64:
        return t("Acoustic");
      case 128:
        return t("OtherSensor");
      case 16773120:
        if (template && template !== "") {
          return t(template);
        } else {
          return t("VirtualSensor");
        }
      default:
        return id;
    }
  }
}
export default withTranslation(["client-profile"])(
  ClientProfileSensorIssuesTab
);
