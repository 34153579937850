import { inject, observer } from "mobx-react";
import { IconUploadField } from "../../../../../components/form/edit-fields/icon-upload-field/IconUploadField";
import { SubstatusDataStore } from "../../../store/store";

export interface BrowseForFileProps {
  substatusDataStore?: SubstatusDataStore;
}

function BrowseForFile(props: BrowseForFileProps) {
  const substatusDataStore = props.substatusDataStore!;

  const onIconChanged = (data: FormData): void => {
    substatusDataStore.setSelectedSubstatusIcon(data);
    substatusDataStore.validateSelectedSubstatus();
  };

  return (
    <IconUploadField
      currentIcon={substatusDataStore.selectedSubstatus.statusIcon}
      iconMemberName="statusIcon"
      onIconChanged={onIconChanged}
    />
  );
}

export default inject("substatusDataStore")(observer(BrowseForFile));
