import styles from "./client-profile-alert-settings.module.css";

import { ClientProfileStore } from "../../store/store";
import ClientProfileAlertSettingsContent from "./client-profile-alert-settings-content";
import { observer } from "mobx-react";
import { WithTranslation, withTranslation } from "react-i18next";
import ClientProfileAlertSettingsHeader from "./client-profile-alert-settings-header";
import { Prompt } from "react-router";
import { Redirect } from "react-router-dom";
import { TransitionStore } from "../../../../store";
import webResource from "../../web";
import { ClientProfileAlertsData } from "../../store/types";
import { NoConnectionModal } from "../../../../utils/NoConnectionDialog";
import { GlobalDialogData } from "../../../../store/transition-store";
import { Component } from "react";
import { ReadAllClientProfileAlertSettingsCallOut } from "../../store/typesOut";
import { AlertCallIn } from "../../store/typesIn";

interface IClientProfileAlertSettingsPageProps extends WithTranslation {
  history: any;
  clientProfileStore: ClientProfileStore;
  saveAlertsSettings(): void;
  cancelAlertChanges(): void;
  t: any;
  transitionStore?: TransitionStore;
}
@observer
export class ClientProfileAlertSettingsPage extends Component<IClientProfileAlertSettingsPageProps> {
  constructor(props: IClientProfileAlertSettingsPageProps) {
    super(props);
    this.fetchData();
  }

  componentWillUnmount() {
    const { clientProfileStore } = this.props;
    const { clientProfileAlerts } = clientProfileStore;

    clientProfileAlerts.clientId = NaN;
    clientProfileAlerts.intervalId = NaN;
    clientProfileAlerts.intervalType = NaN;
    clientProfileAlerts.alerts = [];
  }

  private fetchData() {
    const { clientProfileStore, t, transitionStore, history } = this.props;

    const { clientProfileAlerts } = clientProfileStore;
    const clientId = clientProfileStore.clientProfileDetails.clientId;
    if (
      !clientProfileStore.clientProfileDetails.clientName &&
      !clientProfileStore.clientProfileDetails.areaName
    ) {
      return;
    }
    const { editingPeriodId } = history.location.state;
    const clientSettings = clientProfileStore.clientProfileSettings;
    const isDaytimeInterval =
      clientSettings.getInterval(editingPeriodId).isDaytimeInterval;

    transitionStore?.setLoading(true);

    const params: ReadAllClientProfileAlertSettingsCallOut = {
      clientId: clientId! | 0,
      intervalType: isDaytimeInterval ? 1 : 2,
    };

    return webResource
      .readAllClientProfileAlertSettings(params)
      .then((ajaxResponse) => {
        const response: AlertCallIn = ajaxResponse.data!;

        clientProfileAlerts.clientId = response.clientId;
        clientProfileAlerts.intervalType = response.intervalType;
        clientProfileAlerts.alerts = response.alerts;

        transitionStore?.setLoading(false);
      })
      .catch((ajaxError: any) => {
        const dialog: GlobalDialogData = NoConnectionModal(t);

        transitionStore?.showGlobalDialog(dialog);
        transitionStore?.setLoading(false);
      });
  }

  public render() {
    const {
      t,
      clientProfileStore,
      history,
      saveAlertsSettings,
      cancelAlertChanges,
    } = this.props;
    const clientDetails = clientProfileStore.clientProfileDetails;
    const clientSettings = clientProfileStore.clientProfileSettings;

    if (!clientDetails.clientName && !clientDetails.areaName) {
      const linkArr = this.props.history!.location.pathname.split("/");

      const link = `/client-profile/${linkArr[linkArr.length - 1]}`;
      return <Redirect exact to={link} />;
    }

    const { editingPeriodId } = history.location.state;
    const isDaytimeInterval =
      clientSettings.getInterval(editingPeriodId).isDaytimeInterval;
    const prefix = isDaytimeInterval ? "Day" : "Night";
    const { acousticSettingsValid } = clientProfileStore.dataValidationStatus;

    return (
      <div>
        <ClientProfileAlertSettingsHeader
          title={t(prefix + " time alerts")}
          clientDetails={clientDetails}
          saveAlertsSettings={saveAlertsSettings}
          cancelAlertChanges={cancelAlertChanges}
        />
        <div className={styles["client-profile-content"]}>
          <ClientProfileAlertSettingsContent
            isDaytimeInterval={isDaytimeInterval}
            clientId={clientDetails.clientId! | 0}
            clientSettings={clientSettings}
            clientProfileAlerts={clientProfileStore.clientProfileAlerts}
            history={history}
            canUpdateVoiceAlerts={acousticSettingsValid}
          />
        </div>

        <Prompt when={clientSettings.hasChanges} message="" />
      </div>
    );
  }
}
export default withTranslation(["client-profile"])(
  ClientProfileAlertSettingsPage
);
