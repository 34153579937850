import style from "./search-sensors.module.css";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import EditSensor from "./edit-sensor/edit-sensor";
import Web from "../web";
import { EmptySensorData, SensorDataStore, SensorsSort } from "../store/store";
import { AuthStore, SearchStore, TransitionStore } from "../../../store";
import { SensorStatus } from "./cells";
import { IDeleteSensorData, SensorData } from "../store/types";
import { CreateConfirmationDialog } from "../../../utils/ConfirmationDialog";
import webResource from "../web";
import { GlobalDialogData } from "../../../store/transition-store";
import { NoConnectionModal } from "../../../utils/NoConnectionDialog";
import { NotificationDialog } from "../../../utils/NotificationDialog";
import {
  SortHeader,
  SORT_DIRECTION,
} from "../../../components/table/headers/SortHeader";
import {
  ActionsCell,
  ButtonAvailability,
  ButtonVisibility,
  BUTTON_TYPE,
} from "../../../components/table/cells/ActionsCell";
import {
  BaseTable,
  ColumnData,
  ExpandedState,
} from "../../../components/table/table";
import { TextCell } from "../../../components/table/cells/TextCell";
import { LocationCell } from "../../../components/table/cells/LocationCell";
import { RoomDataConverter, SensorDataConverter } from "../store/convertor";
import { RoomDataIn, SensorDataIn, SensorListCallIn } from "../store/typesIn";
import { SensorListCallOut } from "../store/typesOut";
import IconTooltipCell from "./cells/IconTooltipCell";
import {
  BasicHeader,
  ColumnAlign,
} from "../../../components/table/headers/BasicHeader";
import { runInAction } from "mobx";

interface IManageSensorsGridProps {
  authStore?: AuthStore;
  sensorDataStore?: SensorDataStore;
  transitionStore?: TransitionStore;
  searchStore?: SearchStore;
}

function ManageSensorsGrid(props: IManageSensorsGridProps) {
  const { t } = useTranslation(["sensor-grid"]);
  const sensorDataStore = props.sensorDataStore!;
  const transitionStore = props.transitionStore!;
  const authStore = props.authStore!;
  const searchStore = props.searchStore!;
  const { isLoading } = transitionStore;

  const { sensorsSort, errors, selectedSensor, oldSensorData, validated } =
    sensorDataStore;

  useEffect(() => {
    readAllSensors();

    return function unmount() {
      sensorDataStore.setHiglight(null);
    };
  }, []);

  const readAllSensors = async () => {
    sensorDataStore.setIsDataLoading(true);

    const params: SensorListCallOut = {
      SortOptions: sensorDataStore.sensorsSort,
      LocationId: sensorDataStore.filteringLocationId,
    };

    const noSensors: SensorData[] = [];

    webResource
      .readAll(params)
      .then((ajaxResponse) => {
        const response: SensorListCallIn = ajaxResponse.data!;

        const sensorList = response.data.map((sensor: SensorDataIn) => {
          return SensorDataConverter.toStore(sensor);
        });

        sensorDataStore.setSensors(sensorList);

        const availableRoomList = response.availableRooms.map(
          (availableRoom: RoomDataIn) => {
            return RoomDataConverter.toStore(availableRoom);
          }
        );

        sensorDataStore.setAvailableRooms(availableRoomList);
        sensorDataStore.setSensorTypes(response.sensorTypes);
        sensorDataStore.setIsDataLoading(false);

        if (searchStore.sensorSearch) {
          const filteredSensors = sensorDataStore.sensors.filter((value) => {
            if (!value.sensorId) return false;
            return value.sensorId
              .toLowerCase()
              .includes(searchStore.sensorSearch.toLowerCase());
          });

          if (filteredSensors && filteredSensors.length > 0) {
            sensorDataStore.setHiglight(filteredSensors[0]);
          }
          scrollHighlightIntoView();
        }
      })
      .catch((ajaxError) => {
        const dialog: GlobalDialogData = NoConnectionModal(t);

        sensorDataStore.setSensors(noSensors);
        transitionStore.showGlobalDialog(dialog);
        sensorDataStore.setIsDataLoading(false);
      });
  };

  const scrollHighlightIntoView = () => {
    const selectedElement = document.querySelector("#selected-sensor");
    if (selectedElement) {
      selectedElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };

  const getSortDirection = (accessor: string) => {
    if (accessor !== sensorsSort[0].id) return SORT_DIRECTION.NONE;

    if (sensorsSort[0].desc) return SORT_DIRECTION.DESCENDING;
    else return SORT_DIRECTION.ASCENDING;
  };

  const actionButtonsAvailability = (
    index: number,
    selectedIndex: number
  ): ButtonAvailability => {
    let editAvailability: boolean = false;
    let deleteAvailability: boolean = false;

    if (selectedIndex === ExpandedState.COLLAPSED && !isLoading) {
      editAvailability = true;
      deleteAvailability = true;
    }

    if (!authStore.isAdmin()) {
      deleteAvailability = false;
    }

    return {
      edit: editAvailability,
      delete: deleteAvailability,
    };
  };

  const saveDisabled = () => {
    let saveAvailability = false;

    if (JSON.stringify(selectedSensor) === JSON.stringify(oldSensorData)) {
      saveAvailability = true;
    }

    if (!validated) {
      saveAvailability = true;
    }

    if (errors && Object.keys(errors).length !== 0) {
      saveAvailability = true;
    }

    return saveAvailability;
  };

  const actionButtonsVisibility = (
    index: number,
    selectedIndex: number
  ): ButtonVisibility => {
    let editVisibility: boolean = false;
    let deleteVisibility: boolean = false;

    if (index !== selectedIndex) {
      editVisibility = true;
      deleteVisibility = true;
    }

    if (!authStore.isAdmin()) {
      deleteVisibility = false;
    }

    return {
      edit: editVisibility,
      delete: deleteVisibility,
    };
  };

  const save = () => {
    const { selectedSensor, selectedSensorHasChanges } = sensorDataStore;

    if (!selectedSensorHasChanges) return;

    if (selectedSensor.locationId === 0) {
      sensorDataStore.clearSelectedSensorLocationId();
    }

    sensorDataStore.setIsSaveLoading(true);

    const sensorData = SensorDataConverter.toOut(selectedSensor);

    Web.update(sensorData).then(
      () => {
        sensorDataStore.setSelectedSensorHasChanges(false);
        sensorDataStore.setIsNewSensor(false);
        clearSelectedSensor();

        readAllSensors();
        sensorDataStore.setIsSaveLoading(false);
      },
      (err: any) => {
        sensorDataStore.setIsSaveLoading(false);
        err.message = t("Could not save or update sensor");
        resolveAjaxError(err.data);
        runInAction(() => {
          sensorDataStore.selectedSensor.isNewSensor = true;
        });
      }
    );
  };

  const clearSelectedSensor = () => {
    sensorDataStore.setSelectedSensor(EmptySensorData);
    sensorDataStore.closeExpanded();
    sensorDataStore.bringHighlightedSensorIntoView = true;
  };

  const resolveAjaxError = (error: any) => {
    const manageStatusErrors = error.ManageSensor;

    if (manageStatusErrors === undefined || manageStatusErrors.length === 0) {
      reportAjaxError(error.message);
      return;
    }

    const errorData = manageStatusErrors[0];
    reportAjaxError(errorData);
  };
  const reportAjaxError = (errorMessage: string) => {
    const dialog: GlobalDialogData = NotificationDialog(
      t("ErrorTitleMessage"),
      t(errorMessage),
      t("Ok")
    );

    transitionStore?.showGlobalDialog(dialog);
  };

  const historyAccessor = "history";
  const storageAccessor = "storage";
  const sensorIdAccesor = "sensorId";
  const sensorTypeAccessor = "sensorType";
  const sensorLocationAccessor = "locationName";
  const sensorStatusAccessor = "status";

  const columnData: ColumnData[] = [
    {
      header: t("History"),
      width: "73px",
      HeaderComponent: (
        <BasicHeader text={t("History")} align={ColumnAlign.Center} />
      ),
      accesor: historyAccessor,
      cellComponent: (value) => {
        return <IconTooltipCell sensorId={value.sensorId} />;
      },
    },
    {
      header: t("OnOff"),
      accesor: storageAccessor,
      width: "11%",
      HeaderComponent: (
        <SortHeader
          text={t("OnOff")}
          selected={sensorsSort[0].id === storageAccessor}
          sortDirection={getSortDirection(storageAccessor)}
          locked={sensorDataStore.hasSelectedSensor}
          onClick={() => updateSorting(storageAccessor)}
        />
      ),
      cellComponent: (value: SensorData) => {
        if (value.storage) return <TextCell text={t("Storage")} />;
        else return <TextCell text={t("On")} />;
      },
    },
    {
      header: t("SensorId"),
      accesor: sensorIdAccesor,
      width: "12%",
      HeaderComponent: (
        <SortHeader
          text={t("SensorId")}
          selected={sensorsSort[0].id === sensorIdAccesor}
          sortDirection={getSortDirection(sensorIdAccesor)}
          locked={sensorDataStore.hasSelectedSensor}
          onClick={() => updateSorting(sensorIdAccesor)}
        />
      ),
      cellComponent: (value, index) => (
        <TextCell text={value.sensorId!.toUpperCase()} />
      ),
    },
    {
      header: t("SensorType"),
      accesor: sensorTypeAccessor,
      width: "12%",
      HeaderComponent: (
        <SortHeader
          text={t("SensorType")}
          selected={sensorsSort[0].id === sensorTypeAccessor}
          sortDirection={getSortDirection(sensorTypeAccessor)}
          locked={sensorDataStore.hasSelectedSensor}
          onClick={() => updateSorting(sensorTypeAccessor)}
        />
      ),
      cellComponent: (value: SensorData) => (
        <TextCell text={t(value.sensorType)} />
      ),
    },
    {
      header: t("Room"),
      width: "24%",
      HeaderComponent: (
        <SortHeader
          text={t("Room")}
          selected={sensorsSort[0].id === sensorLocationAccessor}
          sortDirection={getSortDirection(sensorLocationAccessor)}
          locked={sensorDataStore.hasSelectedSensor}
          onClick={() => updateSorting(sensorLocationAccessor)}
        />
      ),
      cellComponent: LocationCell,
    },
    {
      header: t("Status"),
      accesor: sensorStatusAccessor,
      HeaderComponent: (
        <SortHeader
          text={t("Status")}
          selected={sensorsSort[0].id === sensorStatusAccessor}
          sortDirection={getSortDirection(sensorStatusAccessor)}
          locked={sensorDataStore.hasSelectedSensor}
          onClick={() => updateSorting(sensorStatusAccessor)}
        />
      ),
      cellComponent: (value: SensorData) => (
        <SensorStatus
          statusId={value.status!}
          inactivityPeriod={value.inactivityPeriod}
        />
      ),
    },
    {
      header: t("Actions"),
      width: "140px",
      HeaderComponent: (
        <BasicHeader text={t("Actions")} align={ColumnAlign.Center} />
      ),
      cellComponent: (value: SensorData, index: number) => (
        <ActionsCell
          buttonVisibility={actionButtonsVisibility(
            index,
            sensorDataStore.expandedSensorIndex
          )}
          buttonAvailability={actionButtonsAvailability(
            index,
            sensorDataStore.expandedSensorIndex
          )}
          onButtonClick={(type) => onActionButtonClicked(value, index, type)}
        />
      ),
    },
  ];

  const onActionButtonClicked = (
    sensorData: SensorData,
    index: number,
    buttonType: BUTTON_TYPE
  ) => {
    switch (buttonType) {
      case BUTTON_TYPE.EDIT: {
        sensorDataStore.bringHighlightedSensorIntoView = false;

        const data: SensorData = {
          id: sensorData.id,
          sensorId: sensorData.sensorId,
          sensorType: sensorData.sensorType,
          storage: sensorData.storage,
          status: sensorData.status,
          inactivityPeriod: sensorData.inactivityPeriod,
          availableSensorTypes: sensorData.availableSensorTypes,

          locationId: sensorData.locationId,
          locationName: sensorData.locationName,
          areaName: sensorData.areaName,
        };

        sensorDataStore.setSelectedSensor(data);
        sensorDataStore.setSelectedSensorHasChanges(false);
        sensorDataStore.setExpandedSensorIndex(index);
        break;
      }

      case BUTTON_TYPE.DELETE: {
        onDeleteSensor(sensorData);
        break;
      }
    }
  };

  const updateSorting = (name: string) => {
    if (sensorsSort[0].id === name) {
      sensorDataStore.setSensorsSort([
        new SensorsSort(name, !sensorsSort[0].desc),
      ]);
    } else {
      sensorDataStore.setSensorsSort([new SensorsSort(name, false)]);
    }
    readAllSensors();
  };

  const onDeleteSensor = (sensor: SensorData) => {
    const title = t("Remove sensor");
    const content = t("DeleteSensorDialogQuestion", {
      sensorId: sensor.sensorId,
    });

    const data = CreateConfirmationDialog(
      title,
      content,
      t("Remove"),
      t("Cancel"),
      false,
      () => {
        const data: IDeleteSensorData = {
          sensorId: sensor.sensorId!,
          locationId: sensor.locationId!,
        };
        sensorDataStore.setIsDeleteLoading(true);
        Web.remove(data).then(() => {
          readAllSensors();
          sensorDataStore.setIsDeleteLoading(false);
        });
      }
    );

    transitionStore?.showGlobalDialog(data);
  };

  const highlightedItem = sensorDataStore.highlightSensor;

  return (
    <BaseTable
      expandedRows={sensorDataStore.expandedSensorIndex}
      data={sensorDataStore.sensors.slice()}
      columns={columnData}
      customRowId={(_index, rowData: SensorData) => {
        if (rowData.sensorId === highlightedItem?.sensorId)
          return "selected-sensor";
        return "";
      }}
      customRowClass={(_index, rowData: SensorData) => {
        if (rowData.sensorId === highlightedItem?.sensorId)
          return style["selected-sensor"];
        return "";
      }}
      expandedRowComponent={
        <EditSensor
          onSaveClick={() => {
            save();
          }}
          saveLocked={saveDisabled()}
          onCancelClick={() => {
            sensorDataStore.closeExpanded();
          }}
        />
      }
    />
  );
}

export default inject(
  "sensorDataStore",
  "transitionStore",
  "authStore",
  "searchStore"
)(observer(ManageSensorsGrid));
