import moment, { Moment } from "moment";
import styles from "../daily.module.css";

export interface DateLabelProps {
  info: string;
}

export function DateLabel(props: DateLabelProps) {
  return <div className={styles["date"]}>{props.info}</div>;
}

const formatMonth = (date: Moment): string => {
  return moment(date)
    .locale(navigator.language.substring(0, 2))
    .utc()
    .format("MMM");
};

export const formatDate = (dateStart: Moment, dateEnd: Moment): string => {
  const lastMonth = formatMonth(dateEnd);
  const dateEndDelta = dateEnd.clone().subtract(15, "minute");
  const dateStartDelta = dateStart.clone().add(15, "minute");

  if (
    dateStartDelta.day() === dateEndDelta.day() &&
    dateStartDelta.month() == dateEndDelta.month()
  ) {
    return `${dateStart.date()} ${lastMonth} ${dateStart.year()}`;
  }
  if (dateStartDelta.month() === dateEndDelta.month()) {
    return `${dateStart.date()}-${dateEnd.date()} ${lastMonth} ${dateEnd.year()}`;
  }
  const firstMonth = formatMonth(dateStartDelta);
  return `${dateStart.date()} ${firstMonth}-${dateEnd.date()} ${lastMonth} ${dateEnd.year()}`;
};
