import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import AuthorizedRoute, { ROLES } from "../../components/authorized-route";
import { inject } from "mobx-react";

import Clients from "./page.highorder";
import { FeatureStore } from "../../store";

export interface ProtectedManageClientsProps {
  featureStore?: FeatureStore;
}

@inject("featureStore")
class ProtectedManageClients extends React.Component<ProtectedManageClientsProps> {
  public render() {
    const { manageClients } = this.props.featureStore!.features;

    return (
      <AuthorizedRoute
        requiredRoles={[ROLES.NURSEADMIN]}
        exact
        path="/"
        component={Clients}
        shouldRedirect={manageClients === false}
        {...this.props}
      />
    );
  }
}

const clientsRoute: React.ReactElement<Route> = (
  <Route exact path="/manage-residents" component={ProtectedManageClients} />
);

export default clientsRoute;
