import styles from "./client-profile-alerts-tab.module.css";

import * as H from "history";
import classNames from "classnames";
import { ClientProfileSettings, ClientProfileDetails } from "../../store/types";
import { observer } from "mobx-react";
import TimeAlertRadio from "../../cells/time-alerts-radio";
import ClientAlertStatusIcons from "../../cells/client-status-group";
import { Edit } from "../../../../components/svg";
import { AuthStore, TransitionStore } from "../../../../store";
import { WithTranslation, withTranslation } from "react-i18next";
import { CreateConfirmationDialog } from "../../../../utils/ConfirmationDialog";
import { ChangeEvent, Component } from "react";
import { SwitchInput } from "../../../../components/form-inputs";

interface IClientProfileAlertsTabProps extends WithTranslation {
  clientDetails: ClientProfileDetails;
  clientSettings: ClientProfileSettings;
  history: H.History;
  t: any;
  transitionStore: TransitionStore;
  authStore: AuthStore;
  toggleAlertStatus(): void;
  switchAlertTimeStatus(): void;
}

interface IClientProfileAlertsTabState {
  toggleValue: boolean;
  radioValue: string;
}

@observer
export class ClientProfileAlertsTab extends Component<
  IClientProfileAlertsTabProps,
  IClientProfileAlertsTabState
> {
  constructor(props: IClientProfileAlertsTabProps) {
    super(props);
    this.state = {
      radioValue: "",
      toggleValue: false,
    };
  }

  public render() {
    const { t } = this.props;
    return (
      <div className={styles["tabs-container"]}>
        <li className={`${styles["item"]} ${styles["odd-tab"]}`}>
          <div className={styles["title"]}>
            {t("Alerts triggered from the room")}
          </div>
        </li>
        <li className={`${styles["item"]} ${styles["even-tab"]}`}>
          {this.renderAlertsToggleButton()}
          {this.renderTimeIntervalSwitches()}
          {this.renderAlertsStatusIcons()}
          {this.renderEditAlertSettingsButtons()}
        </li>
      </div>
    );
  }
  private renderAlertsToggleButton = () => {
    const { clientDetails, toggleAlertStatus, authStore, transitionStore } =
      this.props;

    const { dialogTitle, dialogContent, okButtonText, cancelButtonText } =
      this.prepareToggleDialogInformation();

    const confirmationDialogData = CreateConfirmationDialog(
      dialogTitle,
      dialogContent,
      okButtonText,
      cancelButtonText,
      false,
      () => {
        clientDetails.isAlarmProfileEnabled = !clientDetails.isAlarmProfileEnabled;
        toggleAlertStatus();
      }
    );

    return (
      <div className={`${styles["toggle-button"]} ${styles["col-1"]}`}>
        <div className={styles["row-1"]}>
          <SwitchInput
            checked={clientDetails.isAlarmProfileEnabled}
            disabled={!authStore.hasAdminRights()}
            onChange={
              (event: ChangeEvent<HTMLInputElement>) => {
                transitionStore.showGlobalDialog(confirmationDialogData);
              }
            } />
        </div>
        <div className={styles["row-2"]} />
      </div>
    );
  };
  private prepareToggleDialogInformation = () => {
    const { clientDetails, t } = this.props;

    const toggleStatus = !clientDetails.isAlarmProfileEnabled
      ? t("common:On")
      : t("common:Off");
    const action = !clientDetails.isAlarmProfileEnabled
      ? t("common:Start")
      : t("common:Stop");

    const dialogTitle = t("AlertsToggleDialogTitle", {
      toggleStatus: toggleStatus,
    });
    const okButtonText = t("AlertsToggleDialogConfirmation", {
      toggleStatus: toggleStatus,
    });
    const cancelButtonText = t("common:Cancel");

    const dialogContent = `${t("AlertsToggleDialogAction", { action: action })}
    ${t("AlertsToggleDialogQuestion", {
      toggleStatus: toggleStatus,
      clientName: clientDetails.clientName,
    })}`;

    return { dialogTitle, dialogContent, okButtonText, cancelButtonText };
  };

  private renderTimeIntervalSwitches = () => {
    const { clientSettings, t, transitionStore } = this.props;
    const dayInterval = clientSettings.getDaytimeInterval();
    const nightInterval = clientSettings.getNighttimeInterval();

    const { dialogTitle, dialogContent, okButtonText, cancelButtonText } =
      this.prepareSwitchDialogInformation();
    const confirmationDialogData = CreateConfirmationDialog(
      dialogTitle,
      dialogContent,
      okButtonText,
      cancelButtonText,
      false,
      () => {
        this.changeAlertStatus(this.state.radioValue);
      }
    );

    return (
      <div className={`${styles["text"]} ${styles["col-2"]}`}>
        <div
          className={classNames({
            [styles["row-1"]]: this.isAlertTabEnabled(),
            [`${styles["row-1"]} ${styles["disabled"]}`]:
              !this.isAlertTabEnabled(),
          })}
        >
          <TimeAlertRadio
            title={t("Day time alerts")}
            checked={this.isPeriodActive(dayInterval.intervalId)}
            onChange={(event: { target: { value: any } }) => {
              transitionStore.showGlobalDialog(confirmationDialogData);
              this.setState({
                radioValue: "Day",
              });
            }}
            startTime={this.formatHour(dayInterval.startTime)}
            endTime={this.formatHour(nightInterval.startTime)}
          />
        </div>
        <div
          className={classNames({
            [styles["row-2"]]: this.isAlertTabEnabled(),
            [`${styles["row-2"]} ${styles["disabled"]}`]:
              !this.isAlertTabEnabled(),
          })}
        >
          <TimeAlertRadio
            title={t("Night time alerts")}
            checked={this.isPeriodActive(nightInterval.intervalId)}
            onChange={(event: { target: { value: any } }) => {
              transitionStore.showGlobalDialog(confirmationDialogData);
              this.setState({
                radioValue: "Night",
              });
            }}
            startTime={this.formatHour(nightInterval.startTime)}
            endTime={this.formatHour(dayInterval.startTime)}
          />
        </div>
      </div>
    );
  };
  private prepareSwitchDialogInformation = () => {
    const { clientDetails, clientSettings, t } = this.props;
    const dayInterval = clientSettings.getDaytimeInterval();
    const nightInterval = clientSettings.getNighttimeInterval();
    const selectedPeriod = this.isPeriodActive(dayInterval.intervalId)
      ? t("Night")
      : t("Day");
    const endHour = this.isPeriodActive(dayInterval.intervalId)
      ? dayInterval.startTime
      : nightInterval.startTime;
    const nextPeriod = this.isPeriodActive(dayInterval.intervalId)
      ? t("Day")
      : t("Night");

    const dialogContent = `${t("AlertsSwitchDialogAction", {
      selectedPeriod: selectedPeriod,
      endHour: this.formatHour(endHour),
      nextPeriod: nextPeriod,
    })} 
    ${t("AlertsSwitchDialogQuestion", {
      selectedPeriod: selectedPeriod,
      clientName: clientDetails.clientName,
    })}`;

    const dialogTitle = t("AlertsSwitchDialogTitle", {
      selectedPeriod: selectedPeriod,
    });
    const okButtonText = t("AlertsSwitchDialogConfirmation", {
      selectedPeriod: selectedPeriod,
    });
    const cancelButtonText = t("common:Cancel");

    return { dialogTitle, dialogContent, okButtonText, cancelButtonText };
  };
  private renderAlertsStatusIcons = () => {
    const { clientSettings, clientDetails } = this.props;
    const dayInterval = clientSettings.getDaytimeInterval();
    const nightInterval = clientSettings.getNighttimeInterval();

    return (
      <div className={styles["col-3"]}>
        <div className={styles["row-1"]}>
          <ClientAlertStatusIcons
            key={"DayStatusIcons"}
            clientSettings={clientSettings}
            disabled={
              this.isPeriodActive(nightInterval.intervalId) ||
              !clientDetails.isAlarmProfileEnabled
            }
            alertPeriodId={dayInterval.intervalId}
          />
        </div>
        <div className={styles["row-2"]}>
          <ClientAlertStatusIcons
            key={"NightStatusIcons"}
            clientSettings={clientSettings}
            disabled={
              this.isPeriodActive(dayInterval.intervalId) ||
              !clientDetails.isAlarmProfileEnabled
            }
            alertPeriodId={nightInterval.intervalId}
          />
        </div>
      </div>
    );
  };
  private renderEditAlertSettingsButtons = () => {
    const { t, history, clientSettings, clientDetails, authStore } = this.props;

    if (!authStore.hasAdminRights()) {
      return <div className={styles["col-4"]} />;
    }
    const dayInterval = clientSettings.getDaytimeInterval();
    const nightInterval = clientSettings.getNighttimeInterval();

    return (
      <div className={styles["col-4"]}>
        <div className={`${styles["row-1"]} ${styles["edit-text"]}`}>
          <Edit className={styles["edit-day-time-icon"]} />
          <div
            className={styles["edit-day-time-text"]}
            onClick={() => {
              history.push(
                "/client-profile/alert-settings/" + clientDetails.clientId,
                { editingPeriodId: dayInterval!.intervalId }
              );
            }}
          >
            {t("Edit day time alerts")}
          </div>
        </div>
        <div className={`${styles["row-2"]} ${styles["edit-text"]}`}>
          <Edit className={styles["edit-day-time-icon"]} />
          <div
            className={styles["edit-day-time-text"]}
            onClick={() => {
              history.push(
                "/client-profile/alert-settings/" + clientDetails.clientId,
                { editingPeriodId: nightInterval.intervalId }
              );
            }}
          >
            {t("Edit night time alerts")}
          </div>
        </div>
      </div>
    );
  };

  public changeAlertStatus = (value: string) => {
    const { clientSettings, clientDetails } = this.props;
    clientDetails.activeAlertPeriodId =
      value === "Day"
        ? clientSettings.getDaytimeInterval().intervalId
        : clientSettings.getNighttimeInterval().intervalId;
    this.props.switchAlertTimeStatus();
  };
  public isAlertTabEnabled() {
    const { isAlarmProfileEnabled } = this.props.clientDetails;
    return isAlarmProfileEnabled;
  }
  public isPeriodActive(periodId: number) {
    const { activeAlertPeriodId } = this.props.clientDetails;
    return activeAlertPeriodId === periodId;
  }

  private formatHour(hour: string) {
    return hour.length > 5 ? hour.substring(0, 5) : hour;
  }
}
export default withTranslation(["client-profile"])(ClientProfileAlertsTab);
