import styles from "./logs.module.css";

import web from "../../web";
import { StatusesPanel } from "./statuses.panel";
import { observer, inject } from "mobx-react";
import { ClientDashboardStore } from "../../store/store";
import { TabContainer } from "../../components/tabs/tab-container";
import { Component } from "react";
import { StatusesAndAlertsConverter } from "../../store/convertor";

interface ILogsView {
  clientId: number;
  clientDashboardStore?: ClientDashboardStore;
  t: any;
}

@inject("clientDashboardStore")
@observer
export class LogsView extends Component<ILogsView, {}> {
  constructor(props: ILogsView) {
    super(props);
    const { clientId, clientDashboardStore } = this.props;

    web
      .readStatusesAndAlerts(clientId, 31)
      .then((result) => {
        const { lastPeriodStatusesAndAlerts } = result.data!;

        const statusesAndAlertsList = lastPeriodStatusesAndAlerts.map(
          (statusAndAlert) => {
            return StatusesAndAlertsConverter.toStore(statusAndAlert);
          }
        );

        clientDashboardStore!.setStatusesAndAlers(statusesAndAlertsList);
      })
      .catch((issues: any) => {});
  }

  public render() {
    const { t } = this.props;
    const { statusesAndAlerts } = this.props.clientDashboardStore!;

    return (
      <div className={styles["logs-view"]}>
        <TabContainer
          defaultTab={0}
          tabList={[
            {
              component: (
                <StatusesPanel data={statusesAndAlerts} showStatuses t={t} />
              ),
              title: t("Statuses"),
            },
            {
              component: (
                <StatusesPanel
                  data={statusesAndAlerts.filter((item) => item.type === 2)}
                  showStatuses={false}
                  t={t}
                />
              ),
              title: t("Alerts"),
            },
          ]}
        ></TabContainer>
      </div>
    );
  }
}
