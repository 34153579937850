import styles from "./client-profile-access-settings.module.css";

import { ClientProfileStore } from "../../store/store";
import ClientProfileAccessSettingsContent from "./client-profile-access-settings-content";
import { observer } from "mobx-react";
import { WithTranslation, withTranslation } from "react-i18next";
import ClientProfileAccessSettingsHeader from "./client-profile-access-settings-header";
import { Prompt } from "react-router";
import { Redirect } from "react-router-dom";
import { Component } from "react";

interface IClientProfileAccessSettingsPageProps extends WithTranslation {
  history?: any;
  clientProfileStore: ClientProfileStore;
  saveAccessSettings(): void;
  cancelAccessSettings(): void;
  t: any;
}
@observer
export class ClientProfileAccessSettingsPage extends Component<IClientProfileAccessSettingsPageProps> {
  public render() {
    const {
      t,
      clientProfileStore,
      history,
      saveAccessSettings,
      cancelAccessSettings,
    } = this.props;
    const clientDetails = clientProfileStore.clientProfileDetails;
    const clientAccess = clientProfileStore.clientProfileAccessDetails;
    const { isEditingDay } = history.location.state;
    const prefix = isEditingDay ? "Day" : "Night";

    if (!clientDetails.clientName && !clientDetails.areaName) {
      const linkArr = this.props.history!.location.pathname.split("/");

      const link = `/client-profile/${linkArr[linkArr.length - 1]}`;
      return <Redirect exact to={link} />;
    }

    return (
      <>
        <ClientProfileAccessSettingsHeader
          title={t(prefix + " time access")}
          clientDetails={clientDetails}
          saveAccessSettings={saveAccessSettings}
          cancelAccessSettings={cancelAccessSettings}
        />
        <div className={styles["client-profile-content"]}>
          <ClientProfileAccessSettingsContent
            clientAccess={clientAccess}
            history={history}
          />
        </div>
        <Prompt when={clientAccess.hasChanges} message="" />
      </>
    );
  }
}
export default withTranslation(["client-profile"])(
  ClientProfileAccessSettingsPage
);
