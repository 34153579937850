import { ReactElement } from "react";
import { Route } from "react-router-dom";

import Login from "./login";

const loginRoute: ReactElement<Route> = (
  <Route exact path="/login" component={Login} />
);

export default loginRoute;
