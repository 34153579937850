import { makeObservable, observable } from "mobx";

export default class FeatureStore {
  constructor() {
    makeObservable(this, {
      features: observable,
    });
  }

  public features: IFeatures = {
    uniteIntegrationEnabled: false,
    manageClients: true,
    manageCameraViewSettings: true,
    showLastLocation: true,
    isVoiceAlertAvailable: true,
    manageAccessSettings: true,
    manageMaris: true,
    managePeripherals: true,
    showTriggerPort: true,
    initialConfigCompleted: false
  };
}

export interface IFeatures {
  uniteIntegrationEnabled: boolean;
  manageClients: boolean;
  manageCameraViewSettings: boolean;
  showLastLocation: boolean;

  isVoiceAlertAvailable: boolean;
  manageAccessSettings: boolean;
  manageMaris: boolean;
  managePeripherals: boolean;
  showTriggerPort: boolean;
  initialConfigCompleted: boolean;
}
