import {
  ObservableMap,
  computed,
  makeObservable,
  observable,
  action,
} from "mobx";
import {
  flatErrorMap,
  FlatErrorValidationResult,
} from "../../../utils/validation/flatErrorMap";
import { validateData } from "../../../utils/validation/validate";
import { clientDataSchema } from "../validation";
import { IClientDataManageClients, IClientDataInactiveClients } from "./types";
import { ExpandedState } from "../../../components/table/table";

export const EmptyClientData: ClientStoreData = {
  clientId: -1,
  clientName: "",
  locationName: "",
  locationId: null,
  areaName: "",
};
export interface ClientStoreData {
  clientId: number;
  clientName: string;
  locationName: string;
  locationId: number | null;
  areaName: string;
}

export interface AvailableRoomData {
  areaName: string;
  locationId: number;
  locationName: string;
}

export class ClientDataStore {
  constructor() {
    makeObservable(this, {
      validationErrors: observable,
      clients: observable,
      inactiveClients: observable,
      rooms: observable,
      editData: observable,
      selectedClient: observable,
      oldClientData: observable,
      modifiedSelectedClient: observable,
      selectedTab: observable,
      loading: observable,
      highlightInactiveClient: observable,
      residentsSort: observable,
      inactiveResidentsSort: observable,
      expandedResident: observable,
      errors: observable,
      errorMessages: observable,
      validated: observable,
      isTranslationLoading: observable,
      isDataLoading: observable,
      isSaveLoading: observable,
      isDeleteLoading: observable,

      hasSelectedClient: computed,
      isNewClient: computed,
      hasSelectedDataChanges: computed,

      setSelectedClient: action,
      setOldClientData: action,
      setSelectedTab: action,
      setInactiveClients: action,
      setHighlightInactiveClient: action,
      setClients: action,
      setSelectedClientName: action,
      setSelectedClientLocationData: action,
      addNewEmptyClient: action,
      setResidentsSort: action,
      setInactiveResidentsSort: action,
      setExpandedResident: action,
      closeExpanded: action,
      validateSelectedClient: action,
      setValidated: action,
      setIsTranslationLoading: action,
      setIsDataLoading: action,
      setIsSaveLoading: action,
      setIsDeleteLoading: action,
    });
  }

  public isTranslationLoading: boolean = false;
  public isDataLoading: boolean = false;
  public isSaveLoading: boolean = false;
  public isDeleteLoading: boolean = false;
  public validated: boolean = true;
  public errorMessages: FlatErrorValidationResult | null = null;
  public validationErrors: ObservableMap<{}> = observable.map();
  public clients: IClientDataManageClients[] = [];
  public inactiveClients: IClientDataInactiveClients[] = [];
  public rooms: AvailableRoomData[] = [];
  public editData: IClientDataManageClients = EmptyClientData;
  public selectedClient: IClientDataManageClients = EmptyClientData;
  public oldClientData: IClientDataManageClients | null = null;
  public modifiedSelectedClient: boolean = false;
  public selectedTab: string = "";
  public loading: boolean = false;
  public highlightInactiveClient: IClientDataInactiveClients | null = {
    clientId: -1,
    clientName: "",
    dateInactivated: "",
  };
  public residentsSort: any[] = [{ id: "clientName", desc: false }];
  public inactiveResidentsSort: any[] = [{ id: "clientName", desc: false }];
  public expandedResident: number = ExpandedState.COLLAPSED;
  public errors: FlatErrorValidationResult | null = null;

  public get hasSelectedClient() {
    return this.expandedResident !== ExpandedState.COLLAPSED;
  }

  public get isNewClient() {
    return this.selectedClient.clientId === null;
  }

  public get hasSelectedDataChanges() {
    return (
      JSON.stringify(this.selectedClient) !== JSON.stringify(this.oldClientData)
    );
  }

  public setSelectedClient(value: IClientDataManageClients) {
    this.selectedClient = value;
  }

  public setOldClientData(value: IClientDataManageClients) {
    this.oldClientData = value;
  }

  public setSelectedTab(tab: string) {
    this.selectedTab = tab;
  }

  public setInactiveClients(value: IClientDataInactiveClients[]) {
    this.inactiveClients = value;
  }

  public setHighlightInactiveClient(value: IClientDataInactiveClients | null) {
    this.highlightInactiveClient = value;
  }

  public setClients(value: IClientDataManageClients[]) {
    this.clients = value;
  }

  public setSelectedClientName(value: string) {
    this.selectedClient = { ...this.selectedClient };
    this.selectedClient.clientName = value;
    this.modifiedSelectedClient = true;
  }

  public setSelectedClientLocationData(
    locationId: number,
    locationName: string,
    areaName: string
  ) {
    this.selectedClient = { ...this.selectedClient };
    this.selectedClient.locationId = locationId;
    this.selectedClient.locationName = locationName;
    this.selectedClient.areaName = areaName;

    this.modifiedSelectedClient = true;
  }

  public addNewEmptyClient() {
    this.selectedClient = {
      clientId: null,
      clientName: "",
      locationName: "",
      locationId: null,
      areaName: "",
    };

    this.oldClientData = this.selectedClient;
    this.modifiedSelectedClient = false;
    this.expandedResident = ExpandedState.TOP;
  }

  public setResidentsSort(value: any[]) {
    this.residentsSort = value;
  }

  public setInactiveResidentsSort(value: any[]) {
    this.inactiveResidentsSort = value;
  }

  public setExpandedResident(value: number) {
    this.expandedResident = value;
  }

  public closeExpanded() {
    this.expandedResident = ExpandedState.COLLAPSED;
  }

  public setValidated(value: boolean) {
    this.validated = value;
  }

  public setIsTranslationLoading(value: boolean) {
    this.isTranslationLoading = value;
  }

  public setIsDataLoading(value: boolean) {
    this.isDataLoading = value;
  }

  public setIsSaveLoading(value: boolean) {
    this.isSaveLoading = value;
  }

  public setIsDeleteLoading(value: boolean) {
    this.isDeleteLoading = value;
  }

  public validateSelectedClient(value: string = "") {
    const validationResult = validateData<IClientDataManageClients>(
      this.selectedClient,
      clientDataSchema
    );

    if (validationResult) this.errors = flatErrorMap(validationResult);
    else this.errors = {};

    if (value !== "") {
      if (this.errorMessages === null) this.errorMessages = {};
      this.errorMessages![value] = this.errors![value];
    }
  }
}
