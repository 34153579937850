import * as React from "react";
import { Route } from "react-router-dom";
import { ClientOverviewStore, IClientData } from './store/store';
import Home from "./page.highorder";
import AuthorizedRoute, { ROLES } from "../../components/authorized-route";

const clientOverviewStore = new ClientOverviewStore();
export { clientOverviewStore };
  export { ClientOverviewStore };
  export type { IClientData };

const ProtectedHome = (props : any) => (<AuthorizedRoute requiredRoles={[ROLES.NURSE,ROLES.NURSEADMIN]} path="/" component={Home} {...props} />);
const homeRoute: React.ReactElement<Route> =
  <Route exact path="/" component={ProtectedHome} />;

  export default homeRoute;
