import { ISubstatusData, SubstatusData } from "./types";
import { SubstatusDataIn } from "./typesIn";

export interface SubstatusDataConverter {
  toStore(inData: SubstatusDataIn): ISubstatusData;
}

export const SensorDataConverter: SubstatusDataConverter = {
  toStore(inData: SubstatusDataIn) {
    return {
      alertId: inData.alertId || null,
      parentStatusName: inData.parentStatusName,
      statusIcon: inData.statusIcon,
      statusIdentifier: inData.statusIdentifier,
      statusName: inData.statusName,
      parentStatusId: inData.parentStatusId || 0,
      statusId: inData.statusId || null,

      statusIconChanged: false,
      newStatusIcon: null,
    };
  },
};
