import { ClientProfileStore } from "./store/store";
import { inject, observer } from "mobx-react";
import { ClientProfileAccessSettingsPage } from "./components/clientProfileAccessSettings";
import webApi from "./web";
import { Lambda, observe } from "mobx";
import { returnToClientProfile } from "./return-to-client-profile";
import { Component } from "react";
import { ChangeClientProfileAccessSettingCallOut } from "./store/typesOut";

interface IClientProfileEditAccessProps {
  history: any;
  clientProfileStore: ClientProfileStore;
  match: any;
}

@inject("clientProfileStore")
@observer
export class ClientProfileEditAccess extends Component<
  IClientProfileEditAccessProps,
  any
> {
  private _observationDisposers: Lambda[] = [];

  public componentDidMount() {
    this.disposeOfObservations();
    this.recordObservations();
  }

  public componentWillUnmount() {
    this.disposeOfObservations();
  }

  private disposeOfObservations = () => {
    if (this._observationDisposers.length === 0) {
      return;
    }
    this._observationDisposers.forEach((disposeObservation) =>
      disposeObservation()
    );
  };

  private recordObservations() {
    const { clientProfileStore } = this.props;
    const { clientProfileAccessDetails } = clientProfileStore;

    if (clientProfileStore.clientProfileDetails.clientId === -1) {
      return;
    }

    let observationDisposer = null;

    clientProfileAccessDetails.clientProfileAssignmentAreas.forEach(
      (accessLocation) => {
        observationDisposer = observe(accessLocation, (change) => {
          clientProfileAccessDetails.hasChanges = true;
        });
        this._observationDisposers.push(observationDisposer);
      }
    );
  }

  public render() {
    const { clientProfileStore, history, match } = this.props;
    if (clientProfileStore.clientProfileDetails.clientId === -1) {
      returnToClientProfile(history, match.params.id);
      return <div />;
    }
    return (
      <ClientProfileAccessSettingsPage
        clientProfileStore={clientProfileStore}
        history={history}
        saveAccessSettings={this.saveAccessSettings}
        cancelAccessSettings={this.cancelAccessSettings}
      />
    );
  }

  public saveAccessSettings = () => {
    const { history } = this.props;
    const clientDetails = this.props.clientProfileStore.clientProfileDetails;
    const clientAccess =
      this.props.clientProfileStore.clientProfileAccessDetails;

    const { isEditingDay } = history.location.state;
    const access = isEditingDay
      ? clientAccess.getDaytimeInterval()
      : clientAccess.getNighttimeInterval();

    const accessSettings: ChangeClientProfileAccessSettingCallOut = {
      clientId: clientDetails.clientId!,
      accessGroupId: access.accessGroupId,
      locationId: access.accessLocationId,
      startTime: access.accessAreaStartTime,
    };
    webApi
      .changeClientProfileAccessSetting(accessSettings)
      .then(() => {
        clientAccess.hasChanges = false;
        history.goBack();
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  public cancelAccessSettings = () => {
    const { history, clientProfileStore } = this.props;
    const { clientProfileAccessDetails } = clientProfileStore;

    clientProfileAccessDetails.hasChanges = false;
    history.goBack();
  };
}
