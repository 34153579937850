import styles from "./daily.module.css";

import moment, { Moment } from "moment";
import { IDailyChart, IActivity } from "./daily-chart.panel.data";
import { observer } from "mobx-react";
import web from "../../web";
import {
  WithTranslation,
  useTranslation,
  withTranslation,
} from "react-i18next";
import { PortraitMode, LandscapeMode } from "../../../../media-queries";
import { CanvasChart } from "./canvas-daily-chart/canvas-chart";
import * as TimeConversion from "./utils/time-conversions";
import { useEffect, useState } from "react";
import { ArrowButton, GetArrow } from "./dashboard-components/arrow-button";
import { TodayButton } from "./dashboard-components/today-button";
import { DateLabel, formatDate } from "./dashboard-components/date-component";
import { Category, GetCategories } from "./dashboard-components/categories";
import { ClientActivityDataIn } from "../../store/typesIn";

export interface IDailyView extends WithTranslation {
  clientId: number;
}

function DailyView(props: IDailyView) {
  const { clientId } = props;
  const [chartData, setChartData] = useState<IDailyChart>({
    data: [],
    hasBathroom: true,
    coveredHours: 0,
  });

  const daysShiftLimit = 31;

  const currentDate = (): Moment => {
    return moment().locale(navigator.language.substring(0, 2));
  };

  const timezoneOffsetInMinutes = (): number => {
    return -new Date().getTimezoneOffset();
  };

  const [displayedDate, setDisplayedDate] = useState<Moment>(currentDate());
  const [leftArrowEnabled, setLeftArrowEnabled] = useState<boolean>(false);
  const [rightArrowEnabled, setRightArrowEnabled] = useState<boolean>(false);
  const [todayButtonEnabled, setTodayButtonEnabled] = useState<boolean>(false);
  const [currentDateContent, setCurrentDateContent] = useState<string>("");
  const [categories, setCategories] = useState<Category[]>([]);

  const { t } = useTranslation("dashboard");

  const IN_ROOM_INDEX = 2;

  useEffect(() => {
    setCategories(GetCategories({ t }));
    getData();
  }, []);

  const render = () => {
    const { t } = props;
    return (
      <div className={styles["daily-view-container"]}>
        <div className={styles["chart-info"]}>
          <div className={styles["daily-view-title"]}>{t("Daily View")}</div>
          {LeftArrow()}
          {DateComponent()}
          {ArrowRight()}
          {Today()}
        </div>
        <div className={styles["daily-chart"]}>
          <LandscapeMode>
            <CanvasChart
              canvasId="normal-chart"
              parts={1440}
              barData={getMappedData()}
              categories={getCategories()}
              yLabels={getYLabels()}
              drawBarConnectionLines={true}
            ></CanvasChart>
          </LandscapeMode>
          <PortraitMode>
            <CanvasChart
              canvasId="small-chart"
              parts={1440}
              barData={getSmallChartMappedData()}
              categories={getCategories()}
              yLabels={getSmallChartYLabels()}
            ></CanvasChart>
          </PortraitMode>
        </div>
      </div>
    );
  };

  const LeftArrow = () => {
    return ArrowButton({
      isEnabled: leftArrowEnabled,
      onArrowClick: onClickLeftArrow,
      styleName: "arrow-left",
      data: GetArrow(),
    });
  };

  const DateComponent = () => {
    return DateLabel({
      info: currentDateContent,
    });
  };

  const ArrowRight = () => {
    return ArrowButton({
      isEnabled: rightArrowEnabled,
      onArrowClick: onClickRightArrow,
      styleName: "arrow-right",
      data: GetArrow(),
    });
  };

  const Today = () => {
    return TodayButton({
      isEnabled: todayButtonEnabled,
      onButtonClick: onClickToday,
      t: t,
    });
  };

  const getSmallChartYLabels = () => {
    const labels = new Map<number, string>();

    for (let hour = 0; hour <= 24; hour += 2) {
      labels.set(TimeConversion.hourToMinutes(hour), hour + "h");
    }

    return labels;
  };

  const getSmallChartMappedData = () => {
    const itemList: any[] = [];

    cleanCategoryCounts();
    if (chartData && chartData.data)
      chartData.data.forEach((data: any) => {
        const start = data.timeStart;
        const end = data.timeEnd;

        categories[data.type].count += 1;
        categories[data.type].total += Math.floor(
          TimeConversion.millisecondsToMinutes(end - start)
        );
      });

    categories.forEach((category, index) => {
      let item = { category: 0, start: 0, end: 0, label: "" };

      item.start = 0;
      item.end = category.total;
      item.category = index;

      itemList.push(item);
    });

    return itemList;
  };

  const getItemLabel = (start: number, end: number) => {
    let label = "";

    const minutesLabel = Math.floor(
      TimeConversion.millisecondsToMinutes(end - start)
    );
    const hours = Math.floor(TimeConversion.minutesToHour(minutesLabel));
    if (hours > 0) {
      label = `${hours}h`;
    }

    const hoursInMinutes = TimeConversion.hourToMinutes(hours);
    if (minutesLabel - hoursInMinutes > 0) {
      label = label + `${minutesLabel - hoursInMinutes}m`;
    }

    return label;
  };

  const getMappedData = () => {
    const upperLimit = displayedDate.valueOf();
    const lowerLimit = upperLimit - TimeConversion.MILLISECONDS_IN_DAY;
    const itemList: any[] = [];

    cleanCategoryCounts();
    if (!chartData || !chartData.data) return itemList;

    chartData.data.forEach((data: IActivity) => {
      let item = { category: 0, start: 0, end: 0, label: "" };

      const start = data.timeStart;
      const end = data.timeEnd;

      if (start <= lowerLimit) {
        item.start = 0;
      } else {
        item.start = Math.floor(
          TimeConversion.millisecondsToMinutes(start - lowerLimit)
        );
      }

      item.end = Math.floor(
        TimeConversion.millisecondsToMinutes(end - lowerLimit)
      );

      item.label = getItemLabel(start, end);

      if (end - start > 0) {
        categories[data.type].count += 1;
        categories[data.type].total += Math.floor(
          TimeConversion.millisecondsToMinutes(end - start)
        );
      }

      item.category = data.type;
      itemList.push(item);
    });

    return itemList;
  };

  const cleanCategoryCounts = () => {
    categories.forEach((category) => {
      category.count = 0;
      category.total = 0;
    });
  };

  const getCategories = () => {
    return categories.map((category, index) => {
      let label = [];

      if (index === IN_ROOM_INDEX) {
        label = [t(category.name)];
      } else {
        const minutes = getMinutesToString(category.total);
        if (minutes === "") {
          label = [t(category.name)];
        } else {
          const totalCount = getCountToString(category.count);
          label = [`${minutes} ${totalCount}`, t(category.name)];
        }
      }

      return {
        color: category.color,
        icon: category.imageSrc,
        labels: label,
      };
    });
  };

  const getCountToString = (count: number) => {
    if (count) return `(${count}x)`;
    return "";
  };

  const getMinutesToString = (minutes: number) => {
    let label = "";

    const hours = Math.floor(TimeConversion.minutesToHour(minutes));
    if (hours > 0) {
      label = `${hours}h`;
    }
    if (minutes - TimeConversion.hourToMinutes(hours) > 0) {
      label = label + `${minutes - TimeConversion.hourToMinutes(hours)}m`;
    }
    return label;
  };

  const getHoursToString = (hour: number, lowerHour: number) => {
    let shownHour = hour + lowerHour;
    if (shownHour > 24) shownHour = shownHour - 24;

    if (shownHour === 24) return "00:00";
    if (shownHour < 10) return `0${shownHour}:00`;
    return `${shownHour}:00`;
  };

  const getYLabels = () => {
    const labels = new Map<number, string>();
    const lowerHour = displayedDate.hour();

    for (let hour = 0; hour <= 24; hour += 2) {
      labels.set(
        TimeConversion.hourToMinutes(hour),
        getHoursToString(hour, lowerHour)
      );
    }

    return labels;
  };

  const handleDateChange = (
    date: Moment,
    callback: (data: ClientActivityDataIn | null) => void
  ) => {
    let requestDate = date
      .clone()
      .subtract(timezoneOffsetInMinutes(), "minute");

    const timestamp = formatRequestDate(requestDate);
    const request = {
      clientId,
      timestamp,
      coveredHours: 24,
      coveredDays: 31,
    };

    web
      .readClientActivity(request)
      .then((result) => {
        const data = result.data!.clientActivityData as ClientActivityDataIn;
        if (data.clientActivities.length > 0) {
          const lastTimestamp = moment(
            data.clientActivities[data.clientActivities.length - 1].timeEnd
          );
          setDisplayedDate(lastTimestamp);
          callback(data);
        } else {
          callback(null);
        }
      })
      .catch(() => {});
  };

  const getData = () => {
    const date = moment().locale(navigator.language.substring(0, 2));
    handleDateChange(date, (receivedData: ClientActivityDataIn | null) => {
      if (receivedData === null) {
        setTodayButtonEnabled(false);
        setLeftArrowEnabled(false);
        setRightArrowEnabled(false);
        return;
      }

      if (receivedData.coveredDays === 0) {
        setLeftArrowEnabled(false);
      } else {
        setLeftArrowEnabled(true);
      }

      setChartData({
        data: receivedData.clientActivities,
        hasBathroom: receivedData.hasBathroom,
        coveredHours: receivedData.coveredHours,
      });

      if (receivedData.clientActivities.length === 0) {
        return;
      }

      handleDisplayDate(date, receivedData);
    });
  };

  const handleDisplayDate = (
    requestDate: Moment,
    receivedData: ClientActivityDataIn
  ): void => {
    const firstActivity = moment(receivedData.clientActivities[0].timeStart);
    const displayDate = formatDate(firstActivity, requestDate);
    setCurrentDateContent(displayDate);
  };

  const onClickLeftArrow = () => {
    if (!leftArrowEnabled) {
      return;
    }

    let newDate = displayedDate
      .clone()
      .subtract(1, "day")
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0);

    if (displayedDate.diff(newDate, "hour") > 24) {
      newDate = displayedDate
        .clone()
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);
    }

    handleDateChange(newDate, (receivedData: ClientActivityDataIn | null) => {
      if (receivedData === null) {
        setLeftArrowEnabled(false);
        return;
      }

      const duration = moment.duration(currentDate().diff(newDate));
      if (duration.asDays() > daysShiftLimit) {
        setLeftArrowEnabled(false);
      }

      setTodayButtonEnabled(true);
      setRightArrowEnabled(true);

      setChartData({
        data: receivedData.clientActivities,
        hasBathroom: receivedData.hasBathroom,
        coveredHours: receivedData.coveredHours,
      });

      if (receivedData.clientActivities.length === 0) {
        return;
      }

      handleDisplayDate(newDate, receivedData);
    });
  };

  const onClickRightArrow = () => {
    if (!rightArrowEnabled) {
      return;
    }

    const newDate = displayedDate
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
      .add(1, "day");

    handleDateChange(newDate, (receivedData: ClientActivityDataIn | null) => {
      if (receivedData === null) {
        return;
      }

      const duration = moment.duration(currentDate().diff(newDate));
      if (duration.asDays() < 1) {
        setRightArrowEnabled(false);
      }

      setLeftArrowEnabled(true);
      setChartData({
        data: receivedData.clientActivities,
        hasBathroom: receivedData.hasBathroom,
        coveredHours: receivedData.coveredHours,
      });

      if (receivedData.clientActivities.length === 0) {
        return;
      }

      handleDisplayDate(newDate, receivedData);
    });
  };

  const onClickToday = () => {
    if (!todayButtonEnabled) {
      return;
    }

    const date = currentDate().minute(0).second(0).millisecond(0);
    handleDateChange(date, (receivedData: ClientActivityDataIn | null) => {
      if (receivedData === null) {
        return;
      }

      setRightArrowEnabled(false);
      setLeftArrowEnabled(true);
      setTodayButtonEnabled(false);

      setChartData({
        data: receivedData.clientActivities,
        hasBathroom: receivedData.hasBathroom,
        coveredHours: receivedData.coveredHours,
      });

      if (receivedData.clientActivities.length === 0) {
        return;
      }

      handleDisplayDate(date, receivedData);
    });
  };

  return render();
}

const formatRequestDate = (dateTime: moment.Moment) => {
  return dateTime.format("YYYY-MM-DDTHH:mm:ss");
};

export default withTranslation(["dashboard"])(observer(DailyView));
