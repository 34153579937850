import { postRequest, readRequest } from "../../web";
import { ILoginModel } from "./types";

const loginUrl = "/api/auth/logon";
const currentUrl = "/api/auth/current";
const logoutUrl = "/api/auth/logout";
const resourceUrl = "/api/feature/";

const webCalls = {
  requestAuthentication(model: ILoginModel) {
    return postRequest(loginUrl, model);
  },

  requestCurrent() {
    return postRequest(currentUrl);
  },

  requestLogout() {
    return postRequest(logoutUrl);
  },

  readFeatureList() {
    return readRequest(resourceUrl);
  },
};

export default webCalls;
