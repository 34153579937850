import styles from "./alert-settings-cell.module.css";

import classnames from "classnames";
import { inject, observer } from "mobx-react";
import { ReactComponent as ClientVoiceAlarm } from "../../../svg/client-voice-alarm.svg";
import { ReactComponent as ClientInactiveAlarm } from "../../../svg/client-inactive.svg";
import { ReactComponent as ClientOutOfBedAlarm } from "../../../svg/client-out-of-bed.svg";
import { ReactComponent as ClientInBathroomAlarm } from "../../../svg/client-in-bathroom.svg";
import { ReactComponent as ClientInBedAlarm } from "../../../svg/client-in-bed.svg";
import { ReactComponent as ClientOutOfRoomAlarm } from "../../../svg/client-out-of-room.svg";
import { ReactComponent as ClientVisitorDetectedAlarm } from "../../../svg/client-visitor-detected.svg";
import { FeatureStore } from "../../../../../store";

export interface AlarmSettingCellProps {
  featureStore?: FeatureStore;
  data: any;
  nightTime?: boolean;
}

export enum AlarmStatus {
  ACTIVE = 1,
  DELAYED,
  INACTIVE,
}

export enum AlarmId {
  OUTOFBEDALARM = 1,
  INBATHROOMALARM = 2,
  OUTOFROOMALARM = 3,
  INACTIVEALARM = 4,
  VISITORDETECTEDALARM = 5,
  VOICEALARM = 7,
  INBEDALARM = 8,
}

function AlarmSettingsCell(props: AlarmSettingCellProps) {
  const statuses = [0, 0, 0, 0, 0, 0, 0, 0];
  const { data, nightTime, featureStore } = props;
  const { features } = featureStore!;

  if (!data) return null;

  const alertData = nightTime ? data.nighttimeAlerts : data.daytimeAlerts;

  alertData.forEach((alert: any) => {
    statuses[alert.alertId] = alert.status;
  });

  const getClasses = (alarmId: number) => {
    if (!data.isActive) {
      return styles.inactive;
    }
    return classnames({
      [styles.inactive]: statuses[alarmId] === AlarmStatus.INACTIVE,
      [styles.active]: statuses[alarmId] === AlarmStatus.ACTIVE,
      [styles.delayed]: statuses[alarmId] === AlarmStatus.DELAYED,
    });
  };

  return (
    <div className={styles.alarmSettingsCell}>
      {features.isVoiceAlertAvailable && (
        <ClientVoiceAlarm className={getClasses(AlarmId.VOICEALARM)} />
      )}
      {!!statuses[AlarmId.INACTIVEALARM] && (
        <ClientInactiveAlarm className={getClasses(AlarmId.INACTIVEALARM)} />
      )}
      <ClientOutOfBedAlarm className={getClasses(AlarmId.OUTOFBEDALARM)} />
      <ClientInBathroomAlarm className={getClasses(AlarmId.INBATHROOMALARM)} />
      <ClientInBedAlarm className={getClasses(AlarmId.INBEDALARM)} />
      <ClientOutOfRoomAlarm className={getClasses(AlarmId.OUTOFROOMALARM)} />
      <ClientVisitorDetectedAlarm
        className={getClasses(AlarmId.VISITORDETECTEDALARM)}
      />
    </div>
  );
}
export default inject("featureStore")(observer(AlarmSettingsCell));
