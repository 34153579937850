import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { AlertDataStore } from "../../../store/store";
import { FeatureStore } from "../../../../../store";
import {
  alertDataSchema,
  alertDataSchemaMandatoryTriggerPort,
} from "../../../validation";
import { TextFieldInput } from "../../../../../components/form-inputs";

export interface ITriggerPortInputProps {
  alertDataStore?: AlertDataStore;
  featureStore?: FeatureStore;
}

function TriggerPortInput(props: ITriggerPortInputProps) {
  const alertDataStore = props.alertDataStore!;
  const featureStore = props.featureStore!;
  const { t } = useTranslation("alert-grid");
  const triggerPort =
    alertDataStore.selectedAlert.triggerPort?.toString() || "";
  const { errorMessages } = alertDataStore;

  return (
    <TextFieldInput
      value={triggerPort}
      mandatory
      errorMessage={
        (errorMessages &&
          errorMessages["triggerPort"] &&
          t("Required information")) ||
        ""
      }
      inputProps={{ pattern: "^[0-9]{1,9}" }}
      label={t("triggerPort").toUpperCase()}
      placeholder={t("triggerPort")}
      onChange={(event: any) => {
        if (!event.target.validity.valid) return;
        const port = parseInt(event.target.value);

        if (isNaN(port)) alertDataStore.setSelectedTriggerPort(undefined);
        else alertDataStore.setSelectedTriggerPort(port);

        alertDataStore.setValidated(false);
      }}
      onValueChangeDebounced={() => {
        let schema = alertDataSchema;

        if (featureStore.features.showTriggerPort) {
          schema = alertDataSchemaMandatoryTriggerPort;
        }

        alertDataStore.validateSelectedAlert(schema, "triggerPort");
        alertDataStore.setValidated(true);
      }}
    />
  );
}

export default inject(
  "alertDataStore",
  "featureStore"
)(observer(TriggerPortInput));
