import styles from "../search-alert.module.css";

import { ReactComponent as InBed } from "../../../clients-overview/svg/client-in-bed.svg";
import { ReactComponent as InBathroom } from "../../../clients-overview/svg/client-in-bathroom.svg";
import { ReactComponent as Inactive } from "../../../clients-overview/svg/client-inactive.svg";
import { ReactComponent as OutOfBed } from "../../../clients-overview/svg/client-out-of-bed.svg";
import { ReactComponent as OutOfRoom } from "../../../clients-overview/svg/client-out-of-room.svg";
import { ReactComponent as VisitorDetected } from "../../../clients-overview/svg/client-visitor-detected.svg";
import { ReactComponent as ClientAlarm } from "../../../clients-overview/svg/client-alarm.svg";
import { ReactComponent as VoiceAlarm } from "../../../clients-overview/svg/client-voice-alarm.svg";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { NotificationDialog } from "../../../../utils/NotificationDialog";
import { BaseTable, SortHeader } from "../../../../components/table";
import {
  ActionsCell,
  ButtonAvailability,
  ButtonVisibility,
  BUTTON_TYPE,
} from "../../../../components/table/cells/ActionsCell";
import { SORT_DIRECTION } from "../../../../components/table/headers/SortHeader";
import TransitionStore, {
  GlobalDialogData,
} from "../../../../store/transition-store";
import { NoConnectionModal } from "../../../../utils/NoConnectionDialog";
import { AlertDataStore, AlertsSort, EmptyAlert } from "../../store/store";
import EditAlert from "../edit-alert/edit-alert";
import webResource from "../../web";
import { TextCell } from "../../../../components/table/cells/TextCell";
import { AlertData, getDelayName } from "../../store/types";
import { useEffect } from "react";
import { ColumnData, ExpandedState } from "../../../../components/table/table";
import { CreateConfirmationDialog } from "../../../../utils/ConfirmationDialog";
import { useTranslation } from "react-i18next";
import AlertIcon from "../cells/icon";
import {
  BasicHeader,
  ColumnAlign,
} from "../../../../components/table/headers/BasicHeader";
import { AlertDataConverter, DelayTypeConverter } from "../../store/convertor";
import { FeatureStore } from "../../../../store";

interface IManageAlertsGridProps {
  alertDataStore?: AlertDataStore;
  transitionStore?: TransitionStore;
  featureStore?: FeatureStore;
}

function chooseAlertIcon(alertId: number): any {
  switch (alertId) {
    case 1:
      return <OutOfBed width="40px" height="40px" />;
    case 2:
      return <InBathroom width="40px" height="40px" />;
    case 3:
      return <OutOfRoom width="40px" height="40px" />;
    case 4:
      return <Inactive width="40px" height="40px" />;
    case 5:
      return <VisitorDetected width="40px" height="40px" />;
    case 6:
      return <ClientAlarm width="40px" height="40px" />;
    case 7:
      return <VoiceAlarm width="40px" height="40px" />;
    case 8:
      return <InBed width="40px" height="40px" />;
    default:
      return <ClientAlarm width="40px" height="40px" />;
  }
}

function ManageAlertsGrid(props: IManageAlertsGridProps) {
  const alertDataStore = props.alertDataStore!;
  const transitionStore = props.transitionStore!;
  const featureStore = props.featureStore!;
  const { t } = useTranslation("alert-grid");
  const { errors } = alertDataStore;
  const { isLoading } = transitionStore;

  const {
    alertsSort,
    selectedAlertHasChanges,
    selectedAlert,
    oldAlertData,
    validated,
  } = alertDataStore;

  useEffect(() => {
    alertDataStore.setAlertsSort([new AlertsSort("alertName", false)]);
    readAllAlerts();

    return function unmount() {
      alertDataStore.setHilightAlert(null);
      alertDataStore.errors = null;
      alertDataStore.closeExpanded();
      alertDataStore.searchValue = "";
    };
  }, []);

  const readAllAlerts = async () => {
    alertDataStore.setIsDataLoading(true);

    const params = {
      SortOptions: alertDataStore.alertsSort,
    };

    const noAlerts: AlertData[] = [];

    try {
      const ajaxResponse = await webResource.readAll(params);
      const response = ajaxResponse.data!;

      const alertList = response.alerts.map((alertData) => {
        return AlertDataConverter.toStore(alertData);
      });

      const delayTypeList = response.delayTypes.map((delayType) => {
        return DelayTypeConverter.toStore(delayType);
      });

      alertDataStore!.setDelayTypes(delayTypeList);
      alertDataStore!.setAlerts(alertList);
      alertDataStore.setIsDataLoading(false);
    } catch (ajaxError) {
      const dialog: GlobalDialogData = NoConnectionModal(t);

      alertDataStore!.setAlerts(noAlerts);
      transitionStore.showGlobalDialog(dialog);
      alertDataStore.setIsDataLoading(false);
    }
  };

  const getSortDirection = (accessor: string) => {
    if (accessor !== alertsSort[0].id) return SORT_DIRECTION.NONE;

    if (alertsSort[0].desc) return SORT_DIRECTION.DESCENDING;
    else return SORT_DIRECTION.ASCENDING;
  };

  const actionButtonsAvailability = (
    index: number,
    selectedIndex: number
  ): ButtonAvailability => {
    let editAvailability: boolean = false;
    let deleteAvailability: boolean = false;

    console.log(isLoading);

    if (selectedIndex === ExpandedState.COLLAPSED && isLoading === false) {
      editAvailability = true;
      deleteAvailability = true;
    }

    return {
      edit: editAvailability,
      delete: deleteAvailability,
    };
  };

  const saveDisabled = () => {
    let saveAvailability = false;
    const { iconChanged, delayIconChanged, ...selectedAlertValues } =
      selectedAlert;

    const {
      iconChanged: unusedValue1,
      delayIconChanged: unusedValue2,
      ...oldAlertValues
    } = oldAlertData;

    if (
      JSON.stringify(selectedAlertValues) === JSON.stringify(oldAlertValues)
    ) {
      saveAvailability = true;
    }

    if (!validated) {
      saveAvailability = true;
    }

    if (errors && Object.keys(errors).length !== 0) {
      saveAvailability = true;
    }

    return saveAvailability;
  };

  const actionButtonsVisibility = (
    index: number,
    selectedIndex: number
  ): ButtonVisibility => {
    let editVisibility: boolean = false;
    let deleteVisibility: boolean = false;

    if (index !== selectedIndex) {
      editVisibility = true;
      deleteVisibility = true;
    }
    return {
      edit: editVisibility,
      delete: deleteVisibility,
    };
  };

  const save = () => {
    const { selectedAlert } = alertDataStore;

    if (!selectedAlertHasChanges) return;

    var alert = selectedAlert;

    const message = createUpdateInstance(alert);
    saveOrUpdate(message);
  };

  const createUpdateInstance = (alert: AlertData) => {
    let data: FormData = new FormData();

    if (alert.newIcon !== null && alert.newIcon !== undefined) {
      alert.newIcon.forEach((value, key) => {
        data.append(key, value);
      });
    }

    if (alert.newDelayIcon !== null && alert.newDelayIcon !== undefined) {
      alert.newDelayIcon.forEach((value, key) => {
        data.append(key, value);
      });
    }

    if (alert.alertId !== undefined && alert.alertId !== null) {
      data.append("alertId", alert.alertId!.toString());
    } else {
      data.append("alertId", "-1");
    }

    if (!featureStore.features.showTriggerPort) {
      alert.triggerPort = null;
    } else {
      data.append("triggerPort", alert.triggerPort!.toString());
    }

    data.append("alertname", alert.alertName);
    data.append("delayType", alert.delayType.toString());
    data.append("iconChanged", `${alert.iconChanged}`);
    data.append("delayIconChanged", `${alert.delayIconChanged}`);

    return data;
  };

  const saveOrUpdate = (alert: any) => {
    alertDataStore.setIsSaveLoading(true);
    const subscription = webResource.update(alert);
    subscription
      .then(
        (result: any) => {
          alertDataStore.resetSelectedData();
          clearSelectedAlert();

          readAllAlerts();
        },
        (err: any) => {
          err.message = t("Could not save or update alert");
          resolveAjaxError(err.data);
        }
      )
      .finally(() => {
        alertDataStore.setIsSaveLoading(false);
      });
  };

  const clearSelectedAlert = () => {
    alertDataStore.setSelectedAlert(EmptyAlert);
    alertDataStore.closeExpanded();
  };

  const resolveAjaxError = (error: any) => {
    const manageAlertErrors = error.ManageAlert;

    if (manageAlertErrors === undefined || manageAlertErrors.length === 0) {
      reportAjaxError(error.message);
      return;
    }

    const errorData = manageAlertErrors[0];
    reportAjaxError(errorData);
  };
  const reportAjaxError = (errorMessage: string) => {
    const dialog: GlobalDialogData = NotificationDialog(
      t("ErrorTitleMessage"),
      t(errorMessage),
      t("Ok")
    );

    transitionStore?.showGlobalDialog(dialog);
  };

  const columnData: ColumnData[] = [
    {
      header: t("alertName"),
      accesor: "alertName",
      HeaderComponent: (
        <SortHeader
          text={t("alertName")}
          selected={alertsSort[0].id === "alertName"}
          sortDirection={getSortDirection("alertName")}
          locked={alertDataStore.hasSelectedAlert}
          onClick={() => {
            if (alertsSort[0].id === "alertName") {
              alertDataStore.setAlertsSort([
                new AlertsSort("alertName", !alertsSort[0].desc),
              ]);
            } else {
              alertDataStore.setAlertsSort([
                new AlertsSort("alertName", false),
              ]);
            }
            readAllAlerts();
          }}
        />
      ),
      cellComponent: (value: AlertData) => {
        return <TextCell text={value.alertName} />;
      },
    },
    {
      header: t("triggerPort"),
      accesor: "triggerPort",
      width: "12%",
      HeaderComponent: (
        <SortHeader
          text={t("triggerPort")}
          selected={alertsSort[0].id === "triggerPort"}
          sortDirection={getSortDirection("triggerPort")}
          locked={alertDataStore.hasSelectedAlert}
          onClick={() => {
            if (alertsSort[0].id === "triggerPort") {
              alertDataStore.setAlertsSort([
                new AlertsSort("triggerPort", !alertsSort[0].desc),
              ]);
            } else {
              alertDataStore.setAlertsSort([
                new AlertsSort("triggerPort", false),
              ]);
            }
            readAllAlerts();
          }}
        />
      ),
      cellComponent: (value: AlertData) => (
        <TextCell text={isNaN(value.triggerPort!) ? "" : value.triggerPort} />
      ),
    },
    {
      header: t("delayType"),
      width: "13%",
      accesor: "delayType",
      HeaderComponent: (
        <SortHeader
          text={t("delayType")}
          selected={alertsSort[0].id === "delayType"}
          sortDirection={getSortDirection("delayType")}
          locked={alertDataStore.hasSelectedAlert}
          onClick={() => {
            if (alertsSort[0].id === "delayType") {
              alertDataStore.setAlertsSort([
                new AlertsSort("delayType", !alertsSort[0].desc),
              ]);
            } else {
              alertDataStore.setAlertsSort([
                new AlertsSort("delayType", false),
              ]);
            }
            readAllAlerts();
          }}
        />
      ),
      cellComponent: (value: AlertData) => (
        <TextCell
          text={t(getDelayName(alertDataStore.delayTypes, value.delayType))}
        />
      ),
    },
    {
      header: t("icon"),
      width: "18%",
      accesor: "icon",
      HeaderComponent: (
        <BasicHeader text={t("icon")} align={ColumnAlign.Center} />
      ),
      cellComponent: (value: AlertData) => {
        if (value.alertId && value.alertId <= 8)
          return <AlertIcon icon={chooseAlertIcon(value.alertId)} />;
        return <AlertIcon data={value.icon} />;
      },
    },
    {
      header: t("delayIcon"),
      width: "18%",
      accesor: "delayIcon",
      HeaderComponent: (
        <BasicHeader text={t("delayIcon")} align={ColumnAlign.Center} />
      ),
      cellComponent: (value: AlertData) => <AlertIcon data={value.delayIcon} />,
    },
    {
      header: t("actions"),
      width: "140px",
      HeaderComponent: (
        <BasicHeader text={t("actions")} align={ColumnAlign.Center} />
      ),
      cellComponent: (value: any, index: number) => {
        if (value.alertId && value.alertId <= 8) return <span></span>;
        return (
          <ActionsCell
            buttonVisibility={actionButtonsVisibility(
              index,
              alertDataStore.expandedAlertIndex
            )}
            buttonAvailability={actionButtonsAvailability(
              index,
              alertDataStore.expandedAlertIndex
            )}
            onButtonClick={(type: BUTTON_TYPE) => {
              switch (type) {
                case BUTTON_TYPE.EDIT: {
                  const data: AlertData = {
                    alertId: value.alertId,
                    alertName: value.alertName,
                    triggerPort: value.triggerPort,
                    delayType: value.delayType,
                    icon: value.icon,
                    delayIcon: value.delayIcon,
                    iconChanged: false,
                    newIcon: null,
                    delayIconChanged: false,
                    newDelayIcon: null,
                  };

                  alertDataStore.setSelectedAlert(data);
                  alertDataStore.selectedAlertHasChanges = false;

                  alertDataStore.setExpandedAlertIndex(index);
                  break;
                }

                case BUTTON_TYPE.DELETE: {
                  const data = CreateConfirmationDialog(
                    t("removeAlert"),
                    t("alert-grid:removeAlertDialogQuestion", {
                      alertName: value.alertName,
                    }),
                    t("remove"),
                    t("cancel"),
                    false,
                    () => {
                      alertDataStore.setIsDeleteLoading(true);
                      webResource.remove(value.alertId).then(() => {
                        alertDataStore.setIsDeleteLoading(false);
                        readAllAlerts();
                      });
                    }
                  );

                  transitionStore.showGlobalDialog(data);
                  break;
                }
              }
            }}
          />
        );
      },
    },
  ];

  if (featureStore.features.showTriggerPort === false) {
    const triggerPortColumnIndex = columnData.findIndex((column) => {
      if (column.accesor === "triggerPort") return true;
    });

    if (triggerPortColumnIndex >= 0) {
      columnData.splice(triggerPortColumnIndex, 1);
    }
  }

  const highlightedItem = alertDataStore.highlightAlert;

  const alertsList = alertDataStore.alerts.filter((alert: AlertData) => {
    if (alert.alertId === null || alert.alertId > 8) return true;
    return false;
  });

  return (
    <BaseTable
      expandedRows={alertDataStore.expandedAlertIndex}
      data={alertsList}
      columns={columnData}
      customRowId={(_index, rowData: AlertData) => {
        if (rowData.alertId === highlightedItem?.alertId)
          return "selected-alert";
        return "";
      }}
      customRowClass={(_index, rowData) => {
        if (rowData.alertId === highlightedItem?.alertId)
          return styles["selected-alert"];
        return "";
      }}
      expandedRowComponent={
        <EditAlert
          saveLocked={saveDisabled()}
          onSaveClick={() => {
            save();
          }}
          onCancelClick={() => {
            alertDataStore.closeExpanded();
          }}
        />
      }
    />
  );
}

export default inject(
  "alertDataStore",
  "transitionStore",
  "featureStore"
)(observer(ManageAlertsGrid));
