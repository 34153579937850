import { inject, observer } from "mobx-react";
import { PeripheralDataStore } from "../../../store/store";
import { SwitchInput } from "../../../../../components/form-inputs";
import { ChangeEvent, useEffect, useState } from "react";
import { PeripheralData } from "../../../store/types";

export interface UsedToggleProps {
  peripheralDataStore?: PeripheralDataStore;
  lockedToggle?: boolean;
}

function UsedToggle(props: UsedToggleProps) {
  const peripheralDataStore = props.peripheralDataStore!;
  const isNewPeripheral =
    peripheralDataStore.selectedPeripheral.peripheralId === 0;
  const peripheralList = peripheralDataStore.peripherals;
  const selectedLocationName =
    peripheralDataStore.selectedPeripheral.locationName;
  const selectedAreaName = peripheralDataStore.selectedPeripheral.areaName;
  const selectedLocationId = peripheralDataStore.selectedPeripheral.locationId;
  const isUsed = peripheralDataStore.selectedPeripheral.isUsed;
  const [startValue, setStartValue] = useState(false);

  useEffect(() => {
    setStartValue(isUsed);
  }, []);

  let lockedToggle = props.lockedToggle;

  const existingPeripheralRoom = peripheralList.find(
    (peripheral: PeripheralData) => {
      if (
        selectedLocationName === peripheral.locationName &&
        selectedAreaName === peripheral.areaName
      ) {
        return true;
      }
      return false;
    }
  );

  if (isNewPeripheral) {
    if (selectedLocationId === undefined) {
      lockedToggle = false;
    } else if (existingPeripheralRoom === undefined) {
      lockedToggle = true;
      peripheralDataStore.selectedPeripheral.isUsed = true;
    } else {
      lockedToggle = false;
    }
  } else {
    if (startValue) {
      lockedToggle = true;
    }
  }

  return (
    <SwitchInput
      checked={isUsed}
      disabled={lockedToggle}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        peripheralDataStore.setSelectedPeripheralUsed(event.target.checked);
        peripheralDataStore.validateSelectedPeripheral();
      }}
    ></SwitchInput>
  );
}

export default inject("peripheralDataStore")(observer(UsedToggle));
