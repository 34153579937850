import styles from "./client-settings-alert-status.module.css";

import {
  AlertSettings,
  AlertType,
  ClientProfileAlertsData,
  ClientProfileSettings,
  DelayType,
} from "../store/types";
import { inject, observer } from "mobx-react";
import classnames from "classnames";
import Delay from "./alert-delay";
import Sound from "./alert-sound";
import { WithTranslation, withTranslation } from "react-i18next";
import { ReactComponent as Check } from "../svg/check.svg";
import { ReactComponent as Warning } from "../svg/warning.svg";
import { ReactComponent as Dash } from "../svg/dash.svg";
import { ChangeEvent, Component } from "react";
import { FeatureStore } from "../../../store";
import { SwitchInput } from "../../../components/form-inputs";

interface IClientAlertGroupProps extends WithTranslation {
  featureStore?: FeatureStore;
  clientSettings: ClientProfileSettings;
  clientProfileAlerts: ClientProfileAlertsData;
  t: any;
  canUpdateVoiceAlerts: boolean;
}

export interface IAlertInfo {
  settings: AlertSettings;
  alertName: string;
  enabled: boolean;
}
@inject("featureStore")
@observer
class ClientSettingsAlertGroup extends Component<IClientAlertGroupProps> {
  private createSortedAlerts() {
    const { t, canUpdateVoiceAlerts, clientProfileAlerts, featureStore } =
      this.props;
    const { features } = featureStore!;
    const profileAlerts = clientProfileAlerts.alerts;

    const internalAlerts = [
      { alertId: 7, name: t("Voice alert"), enabled: canUpdateVoiceAlerts },
      { alertId: 4, name: t("Inactivity"), enabled: true },
      { alertId: 1, name: t("Out of bed"), enabled: true },
      { alertId: 2, name: t("Bathroom"), enabled: true },
      { alertId: 8, name: t("Long time in bed"), enabled: true },
      { alertId: 3, name: t("Out of room"), enabled: true },
      { alertId: 5, name: t("Visitor detected"), enabled: true },
    ];

    if (features.isVoiceAlertAvailable === false) {
      const voiceAlertIndex = internalAlerts.findIndex((alert) => {
        return alert.alertId === 7;
      });

      if (voiceAlertIndex >= 0) {
        internalAlerts.splice(voiceAlertIndex, 1);
      }
    }

    const alerts: IAlertInfo[] = [];

    internalAlerts.forEach((alert) => {
      const data = profileAlerts.find((pa) => pa.alertId === alert.alertId);
      if (!!data)
        alerts.push({
          settings: data,
          alertName: alert.name,
          enabled: alert.enabled,
        });
    });

    profileAlerts
      .filter((d) => d.type === AlertType.External)
      .sort((a: AlertSettings, b: AlertSettings) => {
        if (a.name < b.name) {
          return -1;
        }

        if (a.name === b.name) {
          return 0;
        }

        return 1;
      })
      .forEach((alert) => {
        alerts.push({ settings: alert, alertName: alert.name, enabled: true });
      });

    return alerts;
  }

  public render() {
    const { clientProfileAlerts } = this.props;
    if (!clientProfileAlerts || !clientProfileAlerts.alerts) return null;

    const alerts = this.createSortedAlerts();
    const renderAlerts = alerts.map((alert) => {
      return (
        <div
          className={`${styles["item"]} ${styles["even-tab"]} ${styles["settings-even-tab"]}`}
          key={alert.settings.alertId}
        >
          <div
            className={classnames(
              `${styles["settings-text"]} ${styles["settings-col-1"]}`,
              {
                disabled: !alert.settings.isActive,
              }
            )}
          >
            {alert.alertName}
          </div>
          {this.renderAlertStatus(alert)}
          {this.renderAlertSettings(alert)}
          {this.renderAlertSensorsStatus(alert)}
        </div>
      );
    });
    return renderAlerts;
  }

  renderAlertStatus(alert: IAlertInfo) {
    return this.renderToggleSwitch(alert);
  }

  renderToggleSwitch(alert: IAlertInfo) {
    return (
      <div className={styles["settings-col-2"]}>
        <SwitchInput
          checked={alert.settings.isActive}
          disabled={!alert.enabled}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            alert.settings.isActive = !alert.settings.isActive;
            this.props.clientSettings.hasChanges = true;
          }}
        />
      </div>
    );
  }

  renderAlertSettings(alert: IAlertInfo) {
    return (
      <div
        className={classnames(
          `${styles["settings-text"]} ${styles["settings-col-3"]}`,
          {
            disabled: !alert.settings.isActive,
          }
        )}
      >
        {alert.settings.delayType !== DelayType.None && (
          <Delay
            alert={alert}
            onChange={() => {
              this.props.clientSettings.hasChanges = true;
            }}
          />
        )}
        <Sound
          alert={alert}
          onChange={() => {
            this.props.clientSettings.hasChanges = true;
          }}
        />
      </div>
    );
  }

  renderAlertSensorsStatus(alert: IAlertInfo) {
    return (
      <div
        className={classnames(
          `${styles["settings-text"]} ${styles["settings-col-4"]}`,
          {
            disabled: !alert.settings.isActive,
          }
        )}
      >
        {this.getAlertSensorsStatus(alert)}
      </div>
    );
  }

  getAlertSensorsStatus(alert: IAlertInfo) {
    if (
      alert.enabled &&
      alert.settings.hasSensors &&
      alert.settings.areSensorsActive
    )
      return <Check width="40px" height="40px" />;

    if (
      alert.enabled &&
      alert.settings.hasSensors &&
      !alert.settings.areSensorsActive
    )
      return <Warning className="critical" width="40px" height="40px" />;

    if (alert.enabled || !alert.settings.hasSensors)
      return <Dash width="40px" color="#173544" />;
  }
}

export default withTranslation(["client-profile"])(ClientSettingsAlertGroup);
