import { observable, action, makeObservable } from "mobx";
import { ReactNode } from "react";

export enum ButtonType {
  DEFAULT = 0,
  OK_BUTTON = 1,
}

export interface GlobalDialogData {
  actionButtonData: ActionButton[];
  title: string;
  message: ReactNode;
  canBeClosed?: boolean;
  id?: string;
}

export interface ActionButton {
  text: string;
  callback?(): void;
  closeDialog?: boolean;
  type?: ButtonType;
}

export default class TransitionStore {
  constructor() {
    makeObservable(this, {
      disableLoadingFeedback: observable,
      isLoading: observable,
      showConfirmation: observable,
      warningDialog: observable,
      globalDialogVisibility: observable,
      actionButtonData: observable,
      title: observable,
      message: observable,
      canBeClosed: observable,
      globalWarningMessage: observable,

      showGlobalDialog: action,
      hideGlobalDialog: action,
      setLoading: action,
      setDisableLoadingFeedback: action,
      setGlobalWarningMessage: action,
    });
  }

  public disableLoadingFeedback = false;
  public isLoading: boolean = false;
  public showConfirmation: boolean = false;
  public warningDialog: WarningInformation = {
    errorView: null,
  };
  public continueTransition(proceed: boolean) {}

  public globalDialogVisibility: boolean = false;
  public actionButtonData: ActionButton[] = [];
  public title: string = "";
  public message: ReactNode = "";
  public canBeClosed: boolean = true;
  public globalWarningMessage: string = "";
  public id: string = "";

  showGlobalDialog(data: GlobalDialogData) {
    this.actionButtonData = data.actionButtonData;
    this.globalDialogVisibility = true;
    this.title = data.title;
    this.message = data.message;
    this.canBeClosed = data.canBeClosed || false;
    this.id = data.id ? data.id : "";
  }

  hideGlobalDialog() {
    this.globalDialogVisibility = false;
    this.actionButtonData = [];
    this.title = "";
    this.message = "";
    this.id = "";
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  setGlobalWarningMessage(message: string) {
    this.globalWarningMessage = message;
  }

  setDisableLoadingFeedback(value: boolean) {
    this.disableLoadingFeedback = value;
  }

  isGlobalDialogVisible = (): boolean => {
    return this.globalDialogVisibility;
  };
}

interface WarningInformation {
  errorView: any;
}
