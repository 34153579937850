import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SelectInput } from "../../../../../components/form-inputs";
import { DropDownField } from "../../../../../components/form/edit-fields/dropdown-field/DropdownField";
import { SubstatusDataStore } from "../../../store/store";
import { IAlertData } from "../../../store/types";

export interface IAlertDropdownProps {
  substatusDataStore?: SubstatusDataStore;
}

interface DropdownElement {
  id: number | null;
  value: string;
}

function AlertDropdown(props: IAlertDropdownProps) {
  const substatusDataStore = props.substatusDataStore!;
  const { t } = useTranslation("substatus-grid");
  const alertId = substatusDataStore!.selectedSubstatus.alertId;

  const [alertDropdownData, setAlertDropdownData] = useState<DropdownElement[]>(
    []
  );

  useEffect(() => {
    const dropdownData = buildAlertDropdownData();
    setAlertDropdownData(dropdownData);
  }, []);

  const buildAlertDropdownData = () => {
    const { alerts, selectedSubstatus } = substatusDataStore;

    let alertsList: IAlertData[] = alerts;
    const foundIndex = alertsList.findIndex(
      (al: any) => al.alertId === selectedSubstatus.alertId
    );

    if (selectedSubstatus.alertId && foundIndex === -1) {
      const alertName = substatusDataStore.getAlertName(
        selectedSubstatus.alertId
      );
      alertsList = alertsList.concat({
        alertId: selectedSubstatus.alertId,
        alertName: alertName,
      } as IAlertData);
    }

    const dropdownData = alertsList
      .map((value: any) => ({
        id: value.alertId,
        value: value.alertName,
      }))
      .sort((a: DropdownElement, b: DropdownElement) => {
        if (a.value < b.value) {
          return -1;
        }

        if (a.value === b.value) {
          return 0;
        }

        return 1;
      });

    dropdownData.unshift({ id: null, value: t("noAlertAssigned") });

    return dropdownData;
  };

  const emptyAlert: DropdownElement = {
    id: null,
    value: t("noAlertAssigned"),
  };
  const selectedAlert =
    alertDropdownData.find((value: any) => value.id === alertId) || emptyAlert;

  return (
    <SelectInput
      options={alertDropdownData}
      label={t("alertName").toUpperCase()}
      placeholder={t("alertName")}
      value={selectedAlert}
      onChange={(_event: any, value: DropdownElement) => {
        substatusDataStore.setSelectedSubstatusAlertId(value.id!);
        substatusDataStore.validateSelectedSubstatus();
      }}

    ></SelectInput>
  );
}

export default inject("substatusDataStore")(observer(AlertDropdown));
