import styles from "./client-profile-access-settings-content.module.css";

import { TimePicker } from "../../../../components/time-picker";
import { ClientProfileAccessSettings, AccessLocations } from "../../store/types";
import { WithTranslation, withTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Dropdown } from "../../../../components/form/dropdown/dropdown";
import React, { ChangeEvent } from "react";
import { SwitchInput } from "../../../../components/form-inputs";

interface IClientProfileSettingsContentProps extends WithTranslation {
  history?: any;
  clientAccess: ClientProfileAccessSettings;
  t: any;
}

@observer
export class ClientProfileAccessSettingsContent extends React.Component<
  IClientProfileSettingsContentProps,
  any
> {
  public render() {
    return (
      <div>
        <ul className={styles["flex-container"]}>
          {this.renderTimeIntervalTitle()}
          {this.renderTimeIntervalSettings()}

          {this.renderAccessLocationsTitle()}
          {this.renderAccessLocationSettings()}
        </ul>
      </div>
    );
  }
  private isEditingDayPeriod() {
    const { history } = this.props;
    const { isEditingDay } = history.location.state;
    return isEditingDay;
  }

  private renderTimeIntervalTitle() {
    const { t } = this.props;

    const prefix = this.isEditingDayPeriod() ? "Day " : "Night ";

    return (
      <li
        className={`${styles["item"]} ${styles["odd-tab"]} ${styles["settings-odd-tab"]}`}
      >
        <div
          className={`${styles["settings-title"]} ${styles["settings-col-1"]}`}
        >
          {t(prefix + "time interval")}
        </div>
        <div
          className={`${styles["settings-title"]} ${styles["settings-col-2"]}`}
        >
          {t("Start hour")}
        </div>
        <div
          className={`${styles["settings-title"]} ${styles["settings-col-3"]}`}
        >
          {t("End hour")}
        </div>
      </li>
    );
  }
  private renderTimeIntervalSettings() {
    const { t, clientAccess } = this.props;

    const daytimeInterval = clientAccess.getDaytimeInterval();
    const nighttimeInterval = clientAccess.getNighttimeInterval();

    const currentAccessArea = this.getCurrentEditingInterval();

    const endHour = this.isEditingDayPeriod()
      ? nighttimeInterval.accessAreaStartTime
      : daytimeInterval.accessAreaStartTime;
    const formattedEndHour =
      endHour.length > 5 ? endHour.substring(0, 5) : endHour;

    const endHourAccessType = this.isEditingDayPeriod()
      ? t("Night time access start")
      : t("Day time access start");

    return (
      <li
        className={`${styles["item"]} ${styles["even-tab"]} ${styles["settings-even-tab"]}`}
      >
        <div
          className={`${styles["settings-text"]} ${styles["settings-col-1"]}`}
        >
          {t("Active interval")}
        </div>
        <div className={styles["settings-col-2"]}>
          <TimePicker
            value={currentAccessArea.accessAreaStartTime}
            limitAt={endHour}
            onChange={(value) => {
              currentAccessArea.accessAreaStartTime = value;
            }}
          />
        </div>
        <div
          className={`${styles["settings-text"]} ${styles["settings-col-3"]}`}
        >
          {formattedEndHour + " - " + t(endHourAccessType)}
        </div>
      </li>
    );
  }

  private renderAccessLocationsTitle() {
    const { t } = this.props;

    const timeAccess = this.isEditingDayPeriod()
      ? "Day time access"
      : "Night time access";
    return (
      <li
        className={`${styles["item"]} ${styles["odd-tab"]} ${styles["settings-odd-tab"]}`}
      >
        <div
          className={`${styles["settings-title"]} ${styles["settings-col-1"]}`}
        >
          {t(timeAccess)}
        </div>
        <div
          className={`${styles["settings-title"]} ${styles["settings-col-2"]}`}
        >
          {t("Active")}
        </div>
        <div
          className={`${styles["settings-title"]} ${styles["settings-col-3"]}`}
        >
          {t("Access locations")}
        </div>
      </li>
    );
  }
  private renderAccessLocationSettings() {
    const { clientAccess } = this.props;

    const currentAccessArea = this.getCurrentEditingInterval();

    const dropdownData = this.convertToDropdownValues(
      clientAccess.clientProfileAccessLocations
    );

    const currentDropdownValue =
      currentAccessArea.accessLocationId === 0
        ? dropdownData[0].value
        : dropdownData.find(
          (x: any) => x.id === currentAccessArea.accessLocationId
        ).value;

    return (
      <li
        className={`${styles["item"]} ${styles["even-tab"]} ${styles["settings-even-tab"]}`}
      >
        <div
          className={`${styles["settings-text"]} ${styles["settings-col-1"]}`}
        ></div>
        <div className={styles["settings-col-2"]}>
          <SwitchInput
            checked={currentAccessArea.accessLocationId > 0}
            onChange={
              (event: ChangeEvent<HTMLInputElement>) => {
                if (!event.target.checked) {
                  currentAccessArea.accessLocationId = 0;
                }
                if (event.target.checked) {
                  currentAccessArea.accessLocationId = dropdownData[0].id;
                }
              }
            } />
        </div>
        <div className={styles["settings-col-3"]}>
          <Dropdown
            data={dropdownData}
            defaultValue={currentDropdownValue}
            onChange={this.onChangeAccessLocation}
          />
        </div>
      </li>
    );
  }

  private onChangeAccessLocation = (event: any) => {
    const currentAccessArea = this.getCurrentEditingInterval();
    currentAccessArea.accessLocationId = event.id;
  };

  private convertToDropdownValues(object: AccessLocations[]) {
    if (object.length === 0) {
      const { t } = this.props;
      return [{ id: 0, value: t("No access available") }];
    }

    const dropdownValue: any = [];
    object.forEach((location) => {
      dropdownValue.push({
        value: location.name,
        id: location.wireless_access_id,
      });
    });
    return dropdownValue;
  }

  private getCurrentEditingInterval() {
    const { clientAccess } = this.props;
    const daytimeInterval = clientAccess.getDaytimeInterval();
    const nighttimeInterval = clientAccess.getNighttimeInterval();

    const interval = this.isEditingDayPeriod()
      ? daytimeInterval
      : nighttimeInterval;

    return interval;
  }
}
export default withTranslation(["client-profile"])(
  ClientProfileAccessSettingsContent
);
