import { Autocomplete, TextField, Tooltip } from "@mui/material";
import formInputStyles from "../form-input.module.css";
import styles from "./select.module.css";
import { withStyles } from "@material-ui/styles";
import { useState } from "react";

interface SelectInputProps {
  label?: string;
  mandatory?: boolean;
  showToolTip?: boolean;
  errorMessage?: string;
}

const SelectTooltip = withStyles({
  tooltip: {
    backgroundColor: "#173544",
  },
})(Tooltip);

function SelectInput(props: SelectInputProps | any) {
  const { errorMessage, label, showToolTip, mandatory, ...rest } = props;
  const [open, setOpen] = useState(false);
  const [selectOpen, setSelectOpen] = useState(false);

  const handleOpenTooltip = () => {
    const { disabled } = props;
    if (showToolTip && !disabled && !selectOpen) setOpen(true);
  };

  const handleCloseTooltip = () => {
    setOpen(false);
  };

  const handleOpenSelect = () => {
    setOpen(false);
    setSelectOpen(true);
  };

  const handleCloseSelect = () => {
    setSelectOpen(false);
  };

  const getLabel = () => {
    const { label } = props;
    if (label && mandatory) return label + " *";
    return label;
  };

  const render = () => {
    return (
      <div className={styles["select-input"]}>
        <SelectTooltip
          open={open}
          title={props.value?.value}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <div
            onMouseEnter={handleOpenTooltip}
            onMouseLeave={handleCloseTooltip}
          >
            <Autocomplete
              onOpen={handleOpenSelect}
              onClose={handleCloseSelect}
              disablePortal
              disableClearable
              getOptionLabel={(option: any) => option.value}
              autoFocus={false}
              {...rest}
              renderInput={(params) => {
                return (
                  <TextField
                    error={errorMessage !== undefined && errorMessage !== ""}
                    helperText={errorMessage}
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    label={getLabel()}
                    autoFocus={false}
                  />
                );
              }}
            />
          </div>
        </SelectTooltip>
      </div>
    );
  };

  return render();
}

export default SelectInput;
