import "./locationcell.css";
import { ReactComponent as Dash } from "../../svg/dash.svg";

export function LocationCell(props: any) {
  if (props.areaName == "") {
    return (
        <div className="location-cell">
            <div className="dash-icon">
                <Dash />
            </div>
        </div>
    )
  }
  else {
    return (
        <div className="location-cell">
            <span className="location-cell-areaName">{props.areaName}</span>
            <span className="location-cell-locationName">{props.locationName}</span>
        </div>
    );
  }
}