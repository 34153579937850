import * as React from "react";
import { Route } from "react-router-dom";
import AuthorizedRoute, { ROLES } from "../../components/authorized-route";

import Substatuses from "./page.highorder";

const ProtectedSubstatuses = (props: any) => (
  <AuthorizedRoute
    requiredRoles={[ROLES.ADMIN]}
    path="/"
    component={Substatuses}
    {...props}
  />
);
const substatusesRoute: React.ReactElement<Route> = (
  <Route exact path="/manage-statuses" component={ProtectedSubstatuses} />
);

export default substatusesRoute;
