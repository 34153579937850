import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { SelectInput } from "../../../../../components/form-inputs";
import { SensorDataStore } from "../../../store/store";
import { IRoomData } from "../../../store/types";

export interface RoomDropDownInputProps {
  sensorDataStore?: SensorDataStore;
}

function RoomDropDown(props: RoomDropDownInputProps) {
  const sensorDataStore = props.sensorDataStore!;
  const { t } = useTranslation("sensor-grid");
  const locationId = sensorDataStore!.selectedSensor.locationId;
  const { rooms, selectedSensor, errorMessages } = sensorDataStore;

  const formatRoomDisplay = (room: IRoomData) => {
    return `${room.areaName} - ${room.locationName}`;
  };

  const buildLocationDropdownData = () => {
    let roomsList: any = rooms;

    if (
      selectedSensor.locationId &&
      roomsList.findIndex(
        (loc: any) => loc.locationId === selectedSensor.locationId
      ) === -1
    ) {
      roomsList = roomsList.concat(selectedSensor);
    }

    const dropdownData = roomsList
      .map((value: any) => ({
        id: value.locationId,
        areaName: value.areaName,
        locationId: value.locationId,
        locationName: value.locationName,
        value: formatRoomDisplay(value),
      }))
      .sort((a: any, b: any) => {
        if (a.value < b.value) {
          return -1;
        }

        if (a.value === b.value) {
          return 0;
        }

        return 1;
      });
    dropdownData.unshift({ id: null, value: t("NoRoomAssigned") });
    return dropdownData;
  };

  const locationDropdownData = buildLocationDropdownData();
  const emptyRoom = { id: null, value: t("NoRoomAssigned") };
  const selectedRoom =
    locationDropdownData.find((value: any) => value.id === locationId) ||
    emptyRoom;

  return (
    <SelectInput
      mandatory
      showToolTip
      options={locationDropdownData}
      label={t("Room").toUpperCase()}
      placeholder={t("NoRoomAssigned")}
      value={selectedRoom}
      onChange={(_event: any, value: any) => {
        if (value === null) {
          sensorDataStore!.setSelectedSensorLocationData(0, "", "");
          sensorDataStore!.validateSelectedSensor("locationId");
          return;
        }

        sensorDataStore.setSelectedSensorLocationData(
          value.locationId,
          value.locationName,
          value.areaName
        );
        sensorDataStore.setSelectedSensorHasChanges(true);
        sensorDataStore.validateSelectedSensor("locationId");
      }}
      errorMessage={
        (errorMessages &&
          errorMessages["locationId"] &&
          t("Required information")) ||
        ""
      }
    ></SelectInput>
  );
}

export default inject("sensorDataStore")(observer(RoomDropDown));
