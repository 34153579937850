import styles from "./client-profile-camera-tab.module.css";

import * as H from "history";
import classNames from "classnames";
import { observer } from "mobx-react";
import {
  ClientProfileCameraSettings,
  ClientProfileDetails,
} from "../../store/types";
import { WithTranslation, withTranslation } from "react-i18next";
import { Edit } from "../../../../components/svg";
import { AuthStore, FeatureStore, TransitionStore } from "../../../../store";
import { CreateConfirmationDialog } from "../../../../utils/ConfirmationDialog";
import { ChangeEvent, Component } from "react";
import { SwitchInput } from "../../../../components/form-inputs";

interface IClientProfileCameraTabProps extends WithTranslation {
  t: any;
  history: H.History;
  authStore: AuthStore;
  clientCameraSettings: ClientProfileCameraSettings;
  clientDetails: ClientProfileDetails;
  toggleCameraStatus(): void;
  featuresStore: FeatureStore;
  transitionStore: TransitionStore;
}

interface IClientProfileCameraTabState {
  toggleValue: boolean;
}

@observer
export class ClientProfileCameraTab extends Component<
  IClientProfileCameraTabProps,
  IClientProfileCameraTabState
> {
  constructor(props: IClientProfileCameraTabProps) {
    super(props);
    this.state = {
      toggleValue: false,
    };
  }

  public render() {
    const { t, featuresStore } = this.props;

    if (featuresStore.features.manageCameraViewSettings === false)
      return <div />;

    return (
      <div className={styles["tabs-container"]}>
        <li className={`${styles["item"]} ${styles["odd-tab"]}`}>
          <div className={styles["title"]}>
            {" "}
            {t("Video camera views on smartphone")}{" "}
          </div>
        </li>
        <li className={`${styles["item"]} ${styles["even-tab"]}`}>
          {this.renderCameraStatusToggleButton()}
          {this.renderCameraStatusDescription()}
          <div className={styles["col-3"]}></div>
          {this.renderCameraSettingsEditButton()}
        </li>
      </div>
    );
  }

  private renderCameraStatusToggleButton = () => {
    const {
      clientCameraSettings,
      toggleCameraStatus,
      authStore,
      transitionStore,
    } = this.props;
    const { dialogTitle, dialogContent, okButtonText, cancelButtonText } =
      this.prepareToggleDialogInformation();

    const confirmationDialogData = CreateConfirmationDialog(
      dialogTitle,
      dialogContent,
      okButtonText,
      cancelButtonText,
      false,
      () => {
        clientCameraSettings.isActive = !clientCameraSettings.isActive;
        toggleCameraStatus();
      }
    );

    return (
      <div className={`${styles["toggle-button"]} ${styles["col-1"]}`}>
        <SwitchInput
          checked={clientCameraSettings.isActive}
          disabled={!authStore.hasAdminRights()}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            transitionStore.showGlobalDialog(confirmationDialogData);
          }}
        ></SwitchInput>
      </div>
    );
  };

  private prepareToggleDialogInformation = () => {
    const { clientDetails, clientCameraSettings, t } = this.props;
    const toggleStatus = !clientCameraSettings.isActive
      ? t("common:On")
      : t("common:Off");
    const action = !clientCameraSettings.isActive
      ? t("common:Start")
      : t("common:Stop");

    const dialogTitle = t("CameraToggleDialogTitle", {
      toggleStatus: toggleStatus,
    });
    const okButtonText = t("CameraToggleDialogConfirmation", {
      toggleStatus: toggleStatus,
    });
    const cancelButtonText = t("common:Cancel");
    const dialogContent = `${t("CameraToggleDialogAction", {
      action: action,
    })} ${t("CameraToggleDialogQuestion", {
      toggleStatus: toggleStatus,
      clientName: clientDetails.clientName,
    })}`;

    return { dialogTitle, okButtonText, cancelButtonText, dialogContent };
  };

  private renderCameraStatusDescription = () => {
    const { clientCameraSettings } = this.props;

    const description = this.cameraDescriptionById(
      clientCameraSettings.optionTypeId
    );
    return (
      <div
        className={classNames({
          [`${styles["col-2"]} ${styles["text"]}`]:
            clientCameraSettings.isActive,
          [`${styles["col-2"]} ${styles["text"]} ${styles["disabled"]}`]:
            !clientCameraSettings.isActive,
        })}
      >
        {description}
      </div>
    );
  };

  private cameraDescriptionById(typeId: number) {
    const { t } = this.props;
    switch (typeId) {
      case 1:
        return t("Allow camera views only on alerts");
      case 2:
        return t("Allow camera views anytime");
      default:
        return "";
    }
  }

  private renderCameraSettingsEditButton = () => {
    const { t, history, clientDetails, authStore } = this.props;
    if (!authStore.hasAdminRights()) {
      return <div className="col-4" />;
    }
    return (
      <div className={styles["col-4"]}>
        <div className={`${styles["row-1"]} ${styles["edit-text"]}`}>
          <Edit className={styles["edit-day-time-icon"]} />
          <div
            className={styles["edit-day-time-text"]}
            onClick={() => {
              history.push(
                "/client-profile/camera-settings/" + clientDetails.clientId
              );
            }}
          >
            {t("Edit camera views")}
          </div>
        </div>
      </div>
    );
  };
}

export default withTranslation(["client-profile"])(ClientProfileCameraTab);
