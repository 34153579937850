import styles from "./edit-mari.module.css";

import { inject, observer } from "mobx-react";
import { MariDataStore } from "../../store/store";
import MariNameInput from "./components/mari-name-input";
import BaseAddressInput from "./components/base-address-input";
import UsernameInput from "./components/username-input";
import PasswordInput from "./components/password-input";
import UniteIdInput from "./components/unite-id-input";
import { useEffect } from "react";
import CancelButton from "../../../../components/buttons/CancelButton";
import SaveButton from "../../../../components/buttons/SaveButton";
import NismIp from "./components/nism-ip";
import { scrollIntoViewAndAlign } from "../../../../utils/scroll";
import { useTranslation } from "react-i18next";
import { runInAction } from "mobx";
import VirtualDevices from "./components/virtual-devices";

interface EditMariProps {
  mariDataStore?: MariDataStore;
  saveLocked?: boolean;
  onSaveClick(): void;
  onCancelClick(): void;
}

function EditMari(props: EditMariProps) {
  let editComponentElement: HTMLTableRowElement;
  const mariDataStore = props.mariDataStore!;
  const { saveLocked, onSaveClick, onCancelClick } = props;
  const { t } = useTranslation("common");

  useEffect(() => {
    scrollIntoViewAndAlign(editComponentElement);

    return function unmount() {
      runInAction(() => {
        mariDataStore.errors = null;
        mariDataStore.errorMessages = null;
        mariDataStore.setValidated(true);
      });
    };
  }, []);

  const render = () => {
    return (
      <tr
        className={styles["edit-mari"]}
        ref={(control: HTMLTableRowElement) => (editComponentElement = control)}
      >
        <td className={styles["first"]}>
          <div className={styles["column"]}>
            <MariNameInput />
          </div>
        </td>
        <td>
          <div className={styles["column"]}>
            <BaseAddressInput />
            <div className={styles["nism-ip-field"]}>
              <NismIp />
            </div>
          </div>
        </td>

        <td>
          <div className={styles["column"]}>
            <UsernameInput />
            <div className={styles["password-field"]}>
              <PasswordInput />
            </div>
          </div>
        </td>

        <td>
          <div className={styles["column"]}>
            <UniteIdInput />
          </div>
        </td>

        <td>
          <div className={styles["column"]}>
            <div className={styles["center-content"]}>
              <VirtualDevices />
            </div>
          </div>
        </td>

        <td className={styles["action-column"]}>
          <div className={styles["action-buttons-container"]}>
            <div className={styles["action-buttons"]}>
              <CancelButton
                onClick={() => {
                  onCancelClick();
                }}
              >
                {t("Cancel")}
              </CancelButton>

              <SaveButton
                onClick={() => {
                  onSaveClick();
                }}
                disabled={saveLocked}
              >
                {t("Save")}
              </SaveButton>
            </div>
          </div>
        </td>
      </tr>
    );
  };
  return render();
}

export default inject("mariDataStore")(observer(EditMari));
