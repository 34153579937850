export interface IAlertData {
  alertId: number | null;
  alertName: string;
  triggerPort?: number | null;
  delayType: number;
  icon: string;
  delayIcon: string;
}

export interface IListAlerts {
  SortOptions: any;
}

export class AlertData implements IAlertData {
  alertId: number | null;
  alertName: string;
  triggerPort?: number | null;
  delayType: number;
  icon: string;
  delayIcon: string;

  iconChanged: boolean;
  newIcon: FormData | null;

  delayIconChanged: boolean;
  newDelayIcon: FormData | null;

  public isNewIcon?: boolean;
  public isNewDelayIcon?: boolean;
}

export function getDelayName(
  availableTypes: DelayType[],
  delayType: number
): string {
  return availableTypes.find((dt) => dt.id === delayType)?.name || "";
}

export interface DelayType {
  id: number;
  name: string;
}
