import * as React from "react";
import { Route } from "react-router-dom";
import AuthorizedRoute, { ROLES } from "../../components/authorized-route";

import Cameras from "./page.highorder";

const ProtectedCameras = (props: any) => (
  <AuthorizedRoute
    requiredRoles={[ROLES.ADMIN, ROLES.NURSEADMIN]}
    path="/"
    component={Cameras}
    {...props}
  />
);
const camerasRoute: React.ReactElement<Route> = (
  <Route exact path="/manage-cameras" component={ProtectedCameras} />
);

export default camerasRoute;
