import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { SelectInput } from "../../../../../components/form-inputs";
import { SubstatusDataStore } from "../../../store/store";
import { StatusIdentifier, SubstatusData } from "../../../store/types";

export interface SubstatusDropdownItem {
  id: number | null | undefined;
  statusName?: string;
  value: string;
}

export interface ParentStatusDropDownInputProps {
  substatusDataStore?: SubstatusDataStore;
}

function ParentStatusDropDown(props: ParentStatusDropDownInputProps) {
  const { t } = useTranslation(["substatus-grid"]);
  const substatusDataStore = props.substatusDataStore!;
  const { errorMessages } = substatusDataStore;
  const statusId = substatusDataStore!.selectedSubstatus.parentStatusId;
  const excludedIds = [
    StatusIdentifier.Inactivity,
    StatusIdentifier.InBedWithSecondPersonInRoom,
    StatusIdentifier.InChair,
    StatusIdentifier.Off,
    StatusIdentifier.Unknown,
    StatusIdentifier.ExternalStatus,
  ];

  const buildStatusDropDownData = () => {
    let statusesList: SubstatusData[] = substatusDataStore.substatuses.filter(
      (substatus: SubstatusData) => {
        return !excludedIds.includes(substatus.statusIdentifier);
      }
    );

    const dropdownData: SubstatusDropdownItem[] = statusesList
      .map((value: SubstatusData) => ({
        id: value.statusId,
        statusName: value.statusName,
        value: t(StatusIdentifier[value.statusIdentifier]),
      }))
      .sort((a: SubstatusDropdownItem, b: SubstatusDropdownItem) => {
        if (a.value < b.value) return -1;
        if (a.value === b.value) return 0;

        return 1;
      });

    dropdownData.unshift({ id: null, value: t("NoParentAssigned") });

    return dropdownData;
  };

  const noParentAssigned = t("NoParentAssigned");
  const statusDropdownData = buildStatusDropDownData();
  const emptyStatus = {
    id: null,
    statusName: noParentAssigned,
    value: noParentAssigned,
  };
  const selectedStatus =
    statusDropdownData.find(
      (value: SubstatusDropdownItem) => value.id === statusId
    ) || emptyStatus;

  return (
    <SelectInput
      options={statusDropdownData}
      label={t("ParentStatusName").toUpperCase()}
      placeholder={t("ParentStatusName")}
      value={selectedStatus}
      onChange={(_event: any, value: SubstatusDropdownItem) => {
        if (value.id === 0 && value.value === noParentAssigned)
          substatusDataStore.setSelectedSubstatusParentData(
            undefined,
            value.statusName || ""
          );
        else
          substatusDataStore.setSelectedSubstatusParentData(
            value.id || 0,
            value.statusName || ""
          );
        substatusDataStore.validateSelectedSubstatus("parentStatusId");
        substatusDataStore.setValidated(true);
      }}
      mandatory
      errorMessage={
        (errorMessages &&
          errorMessages["parentStatusId"] &&
          t("RequiredInformation")) ||
        ""
      }
    ></SelectInput>
  );
}

export default inject("substatusDataStore")(observer(ParentStatusDropDown));
