import gridStyles from "./inactive-clients-grid.module.css";
import searchStyles from "../search-inactive-clients.module.css";

import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { BaseTable, SortHeader } from "../../../../components/table";
import TransitionStore, {
  GlobalDialogData,
} from "../../../../store/transition-store";
import { NoConnectionModal } from "../../../../utils/NoConnectionDialog";
import { ClientDataStore } from "../../store/store";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import webResource from "../../web";
import { TextCell } from "../../../../components/table/cells/TextCell";
import { DateCell } from "../../../../components/table/cells/DateCell";
import { SORT_DIRECTION } from "../../../../components/table/headers/SortHeader";
import { DeleteButtonCell } from "./cells/DeleteButtonCell";
import {
  BasicHeader,
  ColumnAlign,
} from "../../../../components/table/headers/BasicHeader";
import { CreateConfirmationDialog } from "../../../../utils/ConfirmationDialog";
import {
  InactiveResidentIn,
  InactiveResidentsListCallIn,
} from "../../store/typesIn";
import { InactiveClientDataConverter } from "../../store/convertor";

interface IInactiveClientsGrid {
  transitionStore?: TransitionStore;
  clientDataStore?: ClientDataStore;
}

function InactiveClientsGrid(props: IInactiveClientsGrid) {
  const transitionStore = props.transitionStore!;
  const clientDataStore = props.clientDataStore!;
  const { inactiveResidentsSort } = clientDataStore;
  const { t } = useTranslation("client-grid");
  const { isLoading } = transitionStore;

  useEffect(() => {
    readAllInactiveClientData();
    return function unmount() {
      clientDataStore.setHighlightInactiveClient(null);
      clientDataStore.closeExpanded();
    };
  }, []);

  const readAllInactiveClientData = () => {
    const { inactiveResidentsSort } = clientDataStore;
    clientDataStore.setIsDataLoading(true);

    return webResource
      .readAllInactive(inactiveResidentsSort)
      .then((ajaxResponse: any) => {
        const response: InactiveResidentsListCallIn = ajaxResponse.data;
        const inactiveClientsList = response.data.map(
          (inactiveClient: InactiveResidentIn) => {
            return InactiveClientDataConverter.toStore(inactiveClient);
          }
        );
        clientDataStore.setInactiveClients(inactiveClientsList);
      })
      .catch((ajaxError: any) => {
        const dialog: GlobalDialogData = NoConnectionModal(t);

        transitionStore.showGlobalDialog(dialog);
      })
      .finally(() => {
        clientDataStore.setIsDataLoading(false);
      });
  };

  const getSortDirection = (accessor: string) => {
    const { inactiveResidentsSort } = clientDataStore;

    if (accessor !== inactiveResidentsSort[0].id) return SORT_DIRECTION.NONE;

    if (inactiveResidentsSort[0].desc) return SORT_DIRECTION.DESCENDING;
    else return SORT_DIRECTION.ASCENDING;
  };

  const columnData: any[] = [
    {
      header: t("Client name"),
      cellComponent: (value: any) => <TextCell text={value.clientName} />,
      HeaderComponent: (
        <SortHeader
          text={t("Client name")}
          selected={inactiveResidentsSort[0].id === "clientName"}
          sortDirection={getSortDirection("clientName")}
          locked={clientDataStore.hasSelectedClient}
          onClick={() => {
            if (inactiveResidentsSort[0].id === "clientName") {
              clientDataStore.setInactiveResidentsSort([
                { id: "clientName", desc: !inactiveResidentsSort[0].desc },
              ]);
            } else {
              clientDataStore.setInactiveResidentsSort([
                { id: "clientName", desc: false },
              ]);
            }
            readAllInactiveClientData();
          }}
        />
      ),
    },
    {
      header: t("InactiveDate"),
      width: "200px",
      cellComponent: (value: any) => <DateCell date={value.dateInactivated} />,
      HeaderComponent: (
        <SortHeader
          align={ColumnAlign.Center}
          text={t("InactiveDate")}
          selected={inactiveResidentsSort[0].id === "dateInactivated"}
          sortDirection={getSortDirection("dateInactivated")}
          locked={clientDataStore.hasSelectedClient}
          onClick={() => {
            if (inactiveResidentsSort[0].id === "dateInactivated") {
              clientDataStore.setInactiveResidentsSort([
                { id: "dateInactivated", desc: !inactiveResidentsSort[0].desc },
              ]);
            } else {
              clientDataStore.setInactiveResidentsSort([
                { id: "dateInactivated", desc: false },
              ]);
            }
            readAllInactiveClientData();
          }}
        />
      ),
    },
    {
      header: t("Actions"),
      HeaderComponent: (
        <BasicHeader text={t("Actions")} align={ColumnAlign.Center} />
      ),
      width: "188px",
      cellComponent: (value: any) => (
        <DeleteButtonCell
          locked={isLoading}
          onDeleteClick={() => {
            deleteClient(value);
          }}
        />
      ),
    },
  ];

  const deleteClient = (value: any) => {
    const content = (
      <div>
        <span>
          {t("client-grid:DeleteClientDialogQuestion1")}
          <span
            className={
              gridStyles["manage-inactive-clients-grid-popup-client-name"]
            }
          >
            {value.clientName}
          </span>
          {t("client-grid:DeleteClientDialogQuestion2")}
        </span>
      </div>
    );

    const data = CreateConfirmationDialog(
      t("DeletingClient"),
      content,
      t("Delete"),
      t("Cancel"),
      false,
      () => {
        clientDataStore.setIsDeleteLoading(true);
        webResource.removeInactive(value.clientId).then(() => {
          readAllInactiveClientData();
          clientDataStore.setIsDeleteLoading(false);
        });
      }
    );

    transitionStore.showGlobalDialog(data);
  };

  const highlightedItem = clientDataStore.highlightInactiveClient;
  return (
    <BaseTable
      expandedRows={clientDataStore.expandedResident}
      data={clientDataStore.inactiveClients.slice()}
      columns={columnData}
      customRowId={(_index, rowData) => {
        if (rowData.clientId === highlightedItem?.clientId) {
          return "selected-client";
        }
        return "";
      }}
      customRowClass={(_index, rowData) => {
        if (rowData.clientId === highlightedItem?.clientId) {
          return searchStyles["selected-client"];
        }
        return "";
      }}
      expandedRowComponent={null}
    />
  );
}

export default inject(
  "clientDataStore",
  "transitionStore"
)(observer(InactiveClientsGrid));
