import gridStyles from "./manage-maris-grid.module.css";
import searchStyles from "./search-mari.module.css";

import { useTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import EditMari from "./edit-mari/edit-mari";
import Web from "../web";
import { MariDataStore, EmptyMari, MariSort } from "../store/store";
import { MariData } from "../store/types";
import { FeatureStore, TransitionStore } from "../../../store";
import { CreateConfirmationDialog } from "../../../utils/ConfirmationDialog";
import { useEffect } from "react";
import webResource from "../web";
import { GlobalDialogData } from "../../../store/transition-store";
import { NoConnectionModal } from "../../../utils/NoConnectionDialog";
import {
  SortHeader,
  SORT_DIRECTION,
} from "../../../components/table/headers/SortHeader";
import { BUTTON_TYPE } from "../../../components/table/cells/ActionsCell";
import ManageMariActionsCell from "./ManageMariActionsCell";
import { NotificationDialog } from "../../../utils/NotificationDialog";
import { BaseTable, ColumnData } from "../../../components/table/table";
import { TextCell } from "../../../components/table/cells/TextCell";
import { MariDataConverter } from "../store/convertor";
import { MariDataIn } from "../store/typesIn";
import {
  BasicHeader,
  ColumnAlign,
} from "../../../components/table/headers/BasicHeader";
import loginWebResource from "../../login/web";
import { runInAction } from "mobx";
import { VirtualDevicesCell } from "./cells/virtualDevices";

interface IMariGridProps {
  mariDataStore?: MariDataStore;
  transitionStore?: TransitionStore;
  featureStore?: FeatureStore;
}

function ManageMariGrid(props: IMariGridProps) {
  const { t } = useTranslation("mari-grid");
  const mariDataStore = props.mariDataStore!;
  const transitionStore = props.transitionStore!;

  const { mariSort, validated } = mariDataStore;

  useEffect(() => {
    readAllMaris();

    return function unmount() {
      mariDataStore.setHilightMari(null);
    };
  }, []);

  const readAllMaris = async () => {
    const mariData = {
      SortOptions: mariDataStore.mariSort,
    };

    const noEntries: MariData[] = [];

    mariDataStore.setIsDataLoading(true);

    return webResource
      .readAll(mariData)
      .then((ajaxResponse: any) => {
        const response = ajaxResponse.data;

        const mariList = response.data.map((mari: MariDataIn) => {
          return MariDataConverter.toStore(mari);
        });
        mariDataStore.setMaris(mariList);
      })
      .then(() => {
        loginWebResource.readFeatureList().then((callData) => {
          const { data } = callData;
          const { featureStore } = props;

          runInAction(() => {
            featureStore!.features = data.featureList;
          });
        });
      })
      .catch((ajaxError: any) => {
        const dialog: GlobalDialogData = NoConnectionModal(t);

        mariDataStore.setMaris(noEntries);
        transitionStore?.showGlobalDialog(dialog);
      })
      .finally(() => {
        mariDataStore.setIsDataLoading(false);
      });
  };

  const getSortDirection = (accessor: string) => {
    if (accessor !== mariSort[0].id) return SORT_DIRECTION.NONE;

    if (mariSort[0].desc) return SORT_DIRECTION.DESCENDING;
    else return SORT_DIRECTION.ASCENDING;
  };

  const updateSorting = (name: string) => {
    if (mariSort[0].id === name) {
      mariDataStore.setMariSort([new MariSort(name, !mariSort[0].desc)]);
    } else {
      mariDataStore.setMariSort([new MariSort(name, false)]);
    }
    readAllMaris();
  };

  const save = () => {
    const { selectedMariHasChanges } = mariDataStore;

    if (!selectedMariHasChanges) {
      return;
    }

    const mariData = mariDataStore.selectedMari;
    update(mariData);
  };

  const update = (mariData: MariData) => {
    mariDataStore.setIsValidationInProgress(true);

    mariDataStore.setIsSaveLoading(true);
    Web.update(MariDataConverter.toOut(mariData))
      .then(() => {
        clearSelectedMari();
        readAllMaris();
      })
      .catch((error) => {
        resolveAjaxError(error.data);
      })
      .finally(() => {
        mariDataStore.setIsValidationInProgress(false);
        mariDataStore.setIsSaveLoading(false);
      });
  };

  const clearSelectedMari = () => {
    mariDataStore.setIsNewMari(false);
    mariDataStore.setSelectedMari(EmptyMari);
    mariDataStore.closeExpanded();
  };

  const resolveAjaxError = (error: any) => {
    const manageNismErrors = error.ManageNism;

    if (manageNismErrors === undefined || manageNismErrors.length === 0) {
      reportAjaxError(error.message);
      return;
    }

    const errorData = manageNismErrors[0];
    reportAjaxError(errorData);
  };

  const reportAjaxError = (errorMessage: string) => {
    const dialog: GlobalDialogData = NotificationDialog(
      t("ErrorTitleMessage"),
      t(errorMessage),
      t("Ok")
    );

    transitionStore?.showGlobalDialog(dialog);
  };

  const notifyUser = (title: string, message: string) => {
    const dialog: GlobalDialogData = NotificationDialog(
      t(title),
      t(message),
      t("Ok")
    );

    transitionStore?.showGlobalDialog(dialog);
  };

  const mariNameAccessor = "mariName";
  const baseAddressAccessor = "baseAddress";
  const usernameAccessor = "username";
  const uniteIdAccessor = "uniteId";
  const virtualDevicesAccessor = "virtualDevices";

  const columnData: ColumnData[] = [
    {
      header: t("MariName"),
      accesor: mariNameAccessor,
      width: "20%",
      HeaderComponent: (
        <SortHeader
          text={t("MariName")}
          selected={mariSort[0].id === mariNameAccessor}
          sortDirection={getSortDirection(mariNameAccessor)}
          locked={mariDataStore.hasSelectedMari}
          onClick={() => updateSorting(mariNameAccessor)}
        />
      ),
      cellComponent: (value: MariData) => <TextCell text={value.mariName} />,
    },
    {
      header: t("BaseAddress"),
      accesor: baseAddressAccessor,
      width: "20%",
      HeaderComponent: (
        <SortHeader
          text={t("BaseAddress")}
          selected={mariSort[0].id === baseAddressAccessor}
          sortDirection={getSortDirection(baseAddressAccessor)}
          locked={mariDataStore.hasSelectedMari}
          onClick={() => updateSorting(baseAddressAccessor)}
        />
      ),
      cellComponent: (value: MariData) => <TextCell text={value.baseAddress} />,
    },
    {
      header: t("Username"),
      width: "15%",
      accesor: usernameAccessor,
      HeaderComponent: (
        <SortHeader
          text={t("Username")}
          selected={mariSort[0].id === usernameAccessor}
          sortDirection={getSortDirection(usernameAccessor)}
          locked={mariDataStore.hasSelectedMari}
          onClick={() => updateSorting(usernameAccessor)}
        />
      ),
      cellComponent: (value: MariData) => <TextCell text={value.username} />,
    },
    {
      header: t("UniteId"),
      accesor: uniteIdAccessor,
      HeaderComponent: (
        <SortHeader
          text={t("UniteId")}
          selected={mariSort[0].id === uniteIdAccessor}
          sortDirection={getSortDirection(uniteIdAccessor)}
          locked={mariDataStore.hasSelectedMari}
          onClick={() => updateSorting(uniteIdAccessor)}
        />
      ),
      cellComponent: (value: MariData) => <TextCell text={value.uniteId} />,
    },
    {
      header: t("VirtualDevices"),
      width: "14%",
      accesor: virtualDevicesAccessor,
      HeaderComponent: (
        <SortHeader
          align={ColumnAlign.Center}
          text={t("VirtualDevices")}
          selected={mariSort[0].id === virtualDevicesAccessor}
          sortDirection={getSortDirection(virtualDevicesAccessor)}
          locked={mariDataStore.hasSelectedMari}
          onClick={() => updateSorting(virtualDevicesAccessor)}
        />
      ),
      cellComponent: (value: MariData) => (
        <VirtualDevicesCell value={value.virtualDevices} />
      ),
    },
    {
      header: t("Actions"),
      width: "140px",
      HeaderComponent: (
        <BasicHeader text={t("Actions")} align={ColumnAlign.Center} />
      ),
      cellComponent: (value: MariData, index: number) => (
        <ManageMariActionsCell
          selectedMariIndex={index}
          onActionButtonClicked={(buttonType) =>
            onActionButtonClicked(value, index, buttonType)
          }
          onSendFile={(file: FormData) => {
            uploadFile(value.mariId!, file);
          }}
        />
      ),
    },
  ];

  const onActionButtonClicked = (
    mariData: MariData,
    index: number,
    buttonType: BUTTON_TYPE
  ) => {
    switch (buttonType) {
      case BUTTON_TYPE.EDIT: {
        const data: MariData = {
          mariId: mariData.mariId,
          mariName: mariData.mariName,
          username: mariData.username,
          password: mariData.password,
          baseAddress: mariData.baseAddress,
          uniteId: mariData.uniteId,
          nismIp: mariData.nismIp,
          virtualDevices: mariData.virtualDevices,
        };
        mariDataStore.setSelectedMari(data);
        mariDataStore.setSelectedMariHasChanges(false);
        mariDataStore.setExpandedMariIndex(index);

        break;
      }

      case BUTTON_TYPE.DELETE: {
        onDeleteMari(mariData);
        break;
      }
    }
  };

  const onDeleteMari = (mari: MariData) => {
    if (mari.mariId === undefined) return;

    const content = (
      <div>
        <span>
          {t("DeleteMariDialogQuestion1")}
          <span className={gridStyles["manage-mari-grid-popup-client-name"]}>
            {mari.mariName}
          </span>
          {t("DeleteMariDialogQuestion2")}
        </span>
      </div>
    );

    mariDataStore.setIsValidationInProgress(true);
    const data = CreateConfirmationDialog(
      t("RemoveMari"),
      content,
      t("Remove"),
      t("Cancel"),
      false,
      () => {
        mariDataStore.setIsDeleteLoading(true);
        Web.remove(mari.mariId!)
          .then(() => {
            readAllMaris();
          })
          .finally(() => {
            mariDataStore.setIsValidationInProgress(false);
            mariDataStore.setIsDeleteLoading(false);
          });
      }
    );

    transitionStore?.showGlobalDialog(data);
  };

  const saveDisabled = () => {
    const { selectedMari, oldMariData, errors } = mariDataStore;
    let saveAvailability = false;

    if (JSON.stringify(selectedMari) === JSON.stringify(oldMariData)) {
      saveAvailability = true;
    }

    if (!validated) {
      saveAvailability = true;
    }

    if (errors && Object.keys(errors).length !== 0) {
      saveAvailability = true;
    }

    return saveAvailability;
  };

  const uploadFile = (mariId: number, data: FormData): void => {
    mariDataStore.setIsSaveLoading(true);
    data.append("mariId", mariId.toString());
    Web.uploadFile(data)
      .then((data: any) => {
        const content = t("PeripheralsUpdatedNotification", {
          totalCount: data.data.peripheralsCount,
          updatedCount: data.data.updatedPeripheralsCount,
        });

        notifyUser("PeripheralsUploaded", content);
      })
      .catch((ajaxError: any) => {
        resolveAjaxError(ajaxError.data);
      })
      .finally(() => {
        mariDataStore.setIsSaveLoading(false);
      });
  };

  const highlightedItem = mariDataStore.highlightMari;

  return (
    <BaseTable
      expandedRows={mariDataStore.expandedMariIndex}
      data={mariDataStore.maris.slice()}
      columns={columnData}
      customRowId={(_index, rowData: MariData) => {
        if (rowData.mariId === highlightedItem?.mariId) return "selected-mari";
        return "";
      }}
      customRowClass={(_index, rowData: MariData) => {
        if (rowData.mariId === highlightedItem?.mariId)
          return searchStyles["selected-mari"];
        return "";
      }}
      expandedRowComponent={
        <EditMari
          saveLocked={saveDisabled()}
          onSaveClick={() => {
            save();
          }}
          onCancelClick={() => {
            mariDataStore.closeExpanded();
            mariDataStore.setIsValidationInProgress(false);
          }}
        />
      }
    />
  );
}

export default inject(
  "mariDataStore",
  "transitionStore",
  "featureStore"
)(observer(ManageMariGrid));
