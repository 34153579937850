import { FilterField } from "../../../components/form/filter-field";
import { observer, inject } from "mobx-react";
import { EmptySensorData, SensorDataStore } from "../store/store";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Filter } from "../../../components/svg";
import { IRoomData, SensorData } from "../store/types";

export interface FilterInputsProps {
  onFilterChange(): void;
  sensorDataStore?: SensorDataStore;
}

function FilterInputs(props: FilterInputsProps) {
  const [filterValue, setFilterValue] = useState("");
  const [isFilterItemSelected, setIsFilterItemSelected] = useState(false);
  const { sensorDataStore, onFilterChange } = props;
  const { t } = useTranslation("filter-camera");

  const filterInputChange = (value: any) => {
    setIsFilterItemSelected(false);
    setFilterValue(value);

    if (value === "") {
      clearFilterSelection();
    }
  };

  const clearFilterSelection = () => {
    const { sensorDataStore } = props;

    if (!sensorDataStore) return;
    if (!sensorDataStore.hasSelectedFilterId) return;

    sensorDataStore.filteringLocationId = null;
    sensorDataStore.setSelectedSensor(EmptySensorData);
    onFilterChange();
  };

  const getFilterValues = () => {
    if (!filterValue || !sensorDataStore) return [];

    const usedLocations: IRoomData[] = [];
    const addedLocationsMap: any = {};

    sensorDataStore.sensors.map((sensorData: SensorData) => {
      if (!addedLocationsMap[sensorData.locationId!]) {
        addedLocationsMap[sensorData.locationId!] = 1;
        usedLocations.push({
          locationId: sensorData.locationId,
          locationName: sensorData.locationName,
          areaName: sensorData.areaName,
        });
      }
    });

    return usedLocations
      .filter((value: IRoomData) => {
        return value.locationName
          .toLowerCase()
          .includes(filterValue.toLowerCase());
      })
      .sort((a: IRoomData, b: IRoomData) => {
        if (a.locationName === b.locationName) return 0;
        return a.locationName > b.locationName ? 1 : -1;
      })
      .map((val, index) => {
        return { key: index, object: val, displayValue: val.locationName };
      });
  };

  const setFilterSelection = (item: any) => {
    sensorDataStore!.filteringLocationId = item.locationId;
    sensorDataStore!.setSelectedSensor(EmptySensorData);

    setFilterValue(item.locationName);
    setIsFilterItemSelected(true);
    onFilterChange();
  };

  const filterValues = getFilterValues();

  return (
    <FilterField
      icon={Filter}
      placeholder={t("Filter by room")}
      inputValue={filterValue}
      values={filterValues}
      onValueChange={(value) => filterInputChange(value)}
      onSelect={setFilterSelection}
      isOpen={!isFilterItemSelected && filterValues.length !== 0}
      isDisabled={sensorDataStore?.hasSelectedSensor}
    ></FilterField>
  );
}

export default inject("sensorDataStore")(observer(FilterInputs));
