import styles from "./client-profile-alert-settings-content.module.css";

import ClientsSettingsAlertGroup from "../../cells/client-settings-alert-status";
import { TimePicker } from "../../../../components/time-picker";
import { ClientProfileAlertsData, ClientProfileSettings } from "../../store/types";
import { WithTranslation, withTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { TransitionStore } from "../../../../store";
import { Redirect } from "react-router";
import { Component } from "react";

interface IClientProfileSettingsContentProps extends WithTranslation {
  history?: any;
  clientSettings: ClientProfileSettings;
  clientProfileAlerts: ClientProfileAlertsData;
  t: any;
  canUpdateVoiceAlerts: boolean;
  transitionStore?: TransitionStore;
  clientId: number;
  isDaytimeInterval: boolean;
}

@observer
export class ClientProfileAlertSettingsContent extends Component<
  IClientProfileSettingsContentProps,
  any
> {
  public render() {
    if (!this.props.clientSettings.alerts) {
      const linkArr = this.props.history.location.pathname.split("/");

      const link = `/client-profile/${linkArr[linkArr.length - 1]}`;
      return <Redirect exact to={link} />;
    }
    return (
      <div>
        <ul className={styles["flex-container"]}>
          {this.renderTimeIntervalTitle()}
          {this.renderTimeIntervalSettings()}

          {this.renderAlertsDetailsTitle()}
          {this.renderAlertsDetailsSettings()}
        </ul>
      </div>
    );
  }

  private renderTimeIntervalTitle() {
    const { t, history, clientSettings } = this.props;
    const { editingPeriodId } = history.location.state;
    let currentInterval = clientSettings.getInterval(editingPeriodId);

    const timePeriod = currentInterval.isDaytimeInterval
      ? "Day time interval"
      : "Night time interval";

    return (
      <li
        className={`${styles["item"]} ${styles["odd-tab"]} ${styles["settings-odd-tab"]}`}
      >
        <div
          className={`${styles["settings-title"]} ${styles["settings-col-1"]}`}
        >
          {t(timePeriod)}
        </div>
        <div
          className={`${styles["settings-title"]} ${styles["settings-col-2"]}`}
        >
          {t("Start hour")}
        </div>
        <div
          className={`${styles["settings-title"]} ${styles["settings-col-3"]}`}
        >
          {t("End hour")}
        </div>
      </li>
    );
  }

  private renderTimeIntervalSettings() {
    const { t, history, clientSettings } = this.props;
    const { getInterval } = clientSettings;
    const { editingPeriodId } = history.location.state;
    const interval = getInterval(editingPeriodId);
    const endHourAlertTimePrefix = interval.isDaytimeInterval ? "Night" : "Day";

    return (
      <li
        className={`${styles["item"]} ${styles["even-tab"]} ${styles["settings-even-tab"]}`}
      >
        <div
          className={`${styles["settings-text"]} ${styles["settings-col-1"]}`}
        >
          {t("Active interval")}
        </div>
        <div className={styles["settings-col-2"]}>
          <TimePicker
            value={getInterval(editingPeriodId).startTime}
            limitAt={this.getEndHour(editingPeriodId)}
            onChange={(value) => {
              getInterval(editingPeriodId).startTime = value;
            }}
          />
        </div>
        <div
          className={`${styles["settings-text"]} ${styles["settings-col-3"]}`}
        >
          {this.getEndHour(editingPeriodId) +
            " - " +
            t(endHourAlertTimePrefix + " time alerts start")}
        </div>
      </li>
    );
  }

  private renderAlertsDetailsTitle() {
    const { t, history, clientSettings } = this.props;
    const { getInterval } = clientSettings;
    const { editingPeriodId } = history.location.state;
    const interval = getInterval(editingPeriodId);
    const timePeriod = interval.isDaytimeInterval
      ? "Day time alerts"
      : "Night time alerts";

    return (
      <li
        className={`${styles["item"]} ${styles["odd-tab"]} ${styles["settings-odd-tab"]}`}
      >
        <div
          className={`${styles["settings-title"]} ${styles["settings-col-1"]}`}
        >
          {t(timePeriod)}
        </div>
        <div
          className={`${styles["settings-title"]} ${styles["settings-col-2"]}`}
        >
          {t("Active")}
        </div>
        <div
          className={`${styles["settings-title"]} ${styles["settings-col-3"]}`}
        >
          {t("Alert Settings")}
        </div>
        <div
          className={`${styles["settings-title"]} ${styles["settings-col-4"]}`}
        >
          {t("Sensors")}
        </div>
      </li>
    );
  }

  private renderAlertsDetailsSettings() {
    const {
      clientSettings,
      clientProfileAlerts,
      history,
      canUpdateVoiceAlerts,
    } = this.props;

    return (
      <li className={`${styles["item"]} ${styles["settings-even-tab"]}`}>
        <ClientsSettingsAlertGroup
          clientSettings={clientSettings}
          clientProfileAlerts={clientProfileAlerts}
          canUpdateVoiceAlerts={canUpdateVoiceAlerts}
        />
      </li>
    );
  }

  public getEndHour = (periodId: number) => {
    const { clientSettings } = this.props;
    const interval = clientSettings.getInterval(periodId);

    const daytimeInterval = clientSettings.getDaytimeInterval();
    const nighttimeInterval = clientSettings.getNighttimeInterval();

    const endHour = interval.isDaytimeInterval
      ? nighttimeInterval.startTime
      : daytimeInterval.startTime;

    return endHour.length > 5 ? endHour.substring(0, 5) : endHour;
  };
}

export default withTranslation(["client-profile"])(
  inject("transitionStore")(ClientProfileAlertSettingsContent)
);
