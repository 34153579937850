import classNames from "classnames";
import styles from "../daily.module.css";

export interface TodayButtonProps {
  isEnabled: boolean;
  onButtonClick(): void;

  t: any;
}

export function TodayButton(props: any) {
  const availableStyle = styles["available"];
  const { isEnabled, onButtonClick, t } = props;

  return (
    <div
      className={classNames(styles["today"], {
        [availableStyle]: isEnabled,
      })}
      onClick={() => onButtonClick()}
    >
      {t("Today")}
    </div>
  );
}
