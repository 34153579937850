import "./layout.css";
import "./react-table.grid.css";
import "./spinner.css";

import UnexpectedErrorStore from "../store/unexpected-error-store";
import AuthStore from "../store/auth-store";
import LoadingSpinner from "./spinner";
import SideMenu from "./menu/side-menu";
import { observer, inject } from "mobx-react";
import { Redirect } from "react-router";
import { Header } from "./header";
import { Footer } from "./footer";
import { WithTranslation, withTranslation } from "react-i18next";
import { Component } from "react";

export interface ILayoutProps extends WithTranslation {
  children?: React.ReactNode;
  unexpectedErrorStore?: UnexpectedErrorStore;
  authStore?: AuthStore;
  t(value: string): string;
}
export interface ILayoutState {
  authenticating: boolean;
}

@inject("authStore")
@observer
class Layout extends Component<ILayoutProps, ILayoutState> {
  public render() {
    const { authStore, t } = this.props;
    const { isAuthenticated, isAuthenticating } = authStore!;
    const showSideMenu = window.self === window.top;

    if (isAuthenticating) {
      return <LoadingSpinner />;
    }
    if (isAuthenticated === false) {
      return <Redirect exact to="/login" />;
    }

    return (
      <div className={"layout"}>
        <Header t={t} />
        <div className="layout-content-container">
          {showSideMenu && <SideMenu />}

          <div id="main" className={"main"}>
            <div className="min-size-container">{this.props.children}</div>
          </div>
        </div>
        <Footer />

        <LoadingSpinner />
      </div>
    );
  }
}

export default withTranslation(["common"])(Layout);
