import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { CameraDataStore } from "../../../store/store";
import { TextFieldInput } from "../../../../../components/form-inputs";

export interface CameraNameInputProps {
  cameraDataStore?: CameraDataStore;
}

const InputStringMaximumLength = 249;

function CameraNameInput(props: CameraNameInputProps) {
  const cameraDataStore = props.cameraDataStore!;
  const { t } = useTranslation("camera-grid");
  const cameraName = cameraDataStore.selectedCamera.cameraName;
  const { errorMessages } = cameraDataStore;

  return (
    <TextFieldInput
      autoFocus
      mandatory
      value={cameraName}
      errorMessage={
        (errorMessages &&
          errorMessages["cameraName"] &&
          t("Required information")) ||
        ""
      }
      label={t("Camera").toUpperCase()}
      placeholder={t("Camera")}
      onChange={(event: any) => {
        if (!event.target.validity.valid) return;

        const cameraNameValue = event.target.value;
        if (cameraNameValue.length > InputStringMaximumLength) return;

        cameraDataStore.setSelectedCameraName(cameraNameValue);
        cameraDataStore.setValidated(false);
      }}
      onValueChangeDebounced={() => {
        cameraDataStore.validateSelectedCamera("cameraName");
        cameraDataStore.setValidated(true);
      }}
    />
  );
}

export default inject("cameraDataStore")(observer(CameraNameInput));
