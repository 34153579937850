import { Route, Switch } from "react-router";
import AuthorizedRoute from "../../components/authorized-route";
import ClientProfile from "./client-profile.highorder";
import { ClientProfileEditAlerts } from "./client-profile-edit-alerts.highorder";
import { ClientProfileEditAccess } from "./client-profile-edit-access.highorder";
import ClientProfileEditCamera from "./client-profile-edit-camera.highorder";
import { ReactElement } from "react";

const ProtectedClientProfile = (props: any) => {
  return <AuthorizedRoute path="/" component={ClientProfile} {...props} />;
};
const ProtectedClientProfileEditAlerts = (props: any) => {
  return (
    <AuthorizedRoute path="/" component={ClientProfileEditAlerts} {...props} />
  );
};
const ProtectedClientProfileEditAccess = (props: any) => {
  return (
    <AuthorizedRoute path="/" component={ClientProfileEditAccess} {...props} />
  );
};
const ProtectedClientProfileEditCamera = (props: any) => {
  return (
    <AuthorizedRoute path="/" component={ClientProfileEditCamera} {...props} />
  );
};
const clientProfileRoute: ReactElement<Route> = (
  <Switch>
    <Route
      exact
      path="/client-profile/alert-settings/:id"
      component={ProtectedClientProfileEditAlerts}
    />
    <Route
      exact
      path="/client-profile/access-settings/:id"
      component={ProtectedClientProfileEditAccess}
    />
    <Route
      exact
      path="/client-profile/:id"
      component={ProtectedClientProfile}
    />
    <Route
      exact
      path="/client-profile/camera-settings/:id"
      component={ProtectedClientProfileEditCamera}
    />
  </Switch>
);

export default clientProfileRoute;
