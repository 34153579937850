import styles from "./sensor-status.module.css";

import { observer } from "mobx-react";
import classNames from "classnames";
import * as moment from "moment";
import { withTranslation, WithTranslation } from "react-i18next";
import { ReactComponent as Check } from "../../svg/check.svg";
import { ReactComponent as Undefined } from "../../svg/undefined.svg";
import { ReactComponent as BatteryLow } from "../../svg/lowbattery.svg";
import { ReactComponent as Storage } from "../../svg/storage.svg";
import { ReactComponent as Inactive } from "../../svg/inactive.svg";
import { ReactComponent as Disconnected } from "../../svg/disconnected.svg";
import { ReactComponent as Dash } from "../../svg/dash.svg";
import { WarningSign } from "../../../../components/svg";
import { Component } from "react";

interface ISensorStatus extends WithTranslation {
  statusId: number;
  inactivityPeriod: string;
  t: any;
}

export enum SensorState {
  Undefined = 0xff,
  Active = 0x0001,
  Inactive = 0x0002,
  Storage = 0x0004,
  Disconnected = 0x0008,
  BatteryLow = 0x0010,
  NotAvailable = 0x0020,
  SetForTooLong = 0x0040,
}
@observer
export class SensorStatus extends Component<ISensorStatus> {
  public render() {
    const { statusId, inactivityPeriod } = this.props;

    const statusIcon = this.getIcon(statusId);
    const statusText = this.getText(statusId, inactivityPeriod);
    const hasExtraStatus = this.isComposedState(statusId);

    const Icon = {
      Data: statusIcon,
    };

    return (
      <div
        className={classNames(styles["sensor-status"], {
          [styles["orange"]]: this.isOrangeStatus(statusId),
          [styles["red"]]: this.isRedStatus(statusId),
          [styles["blue"]]: this.isBlueStatus(statusId),
          [styles["with-extra-info"]]: this.hasExtraInfo(statusId),
        })}
      >
        <div
          className={classNames(
            styles["status-icon"],
            styles["sensor-status"],
            {
              [styles["orange"]]: this.isOrangeStatus(statusId),
              [styles["red"]]: this.isRedStatus(statusId),
              [styles["blue"]]: this.isBlueStatus(statusId),
            }
          )}
        >
          <Icon.Data />
        </div>
        <div className={styles["status-text-cell"]}>
          <div
            className={`${styles["status-text"]} ${styles["text-visibility"]}`}
          >
            {statusText}
          </div>
          {hasExtraStatus && (
            <div
              className={`${styles["substatus-text"]} ${styles["text-visibility"]}`}
            >
              {this.getSubstatus(statusId)}
            </div>
          )}
        </div>
      </div>
    );
  }

  private getIcon(id: number) {
    if (this.hasFlag(id, SensorState.Undefined)) return Undefined;
    if (this.hasFlag(id, SensorState.Disconnected)) return Disconnected;
    if (this.hasFlag(id, SensorState.Inactive)) return Inactive;
    if (this.hasFlag(id, SensorState.SetForTooLong)) return WarningSign;
    if (this.hasFlag(id, SensorState.BatteryLow)) return BatteryLow;
    if (this.hasFlag(id, SensorState.Active)) return Check;
    if (this.hasFlag(id, SensorState.Storage)) return Storage;
    if (this.hasFlag(id, SensorState.NotAvailable)) return Dash;

    return Undefined;
  }

  private hasFlag(state: number, expectedState: SensorState): boolean {
    return (state & expectedState) === expectedState;
  }

  private getText(id: number, inactivityPeriod: string) {
    const { t } = this.props;

    if (this.hasFlag(id, SensorState.Undefined)) return t("Unknown");
    if (this.hasFlag(id, SensorState.Disconnected)) return t("Disconnected");
    if (this.hasFlag(id, SensorState.Inactive))
      return t("Inactive") + ": " + this.formatStatusSince(inactivityPeriod, 0);
    if (this.hasFlag(id, SensorState.SetForTooLong)) return t("SetForTooLong");
    if (this.hasFlag(id, SensorState.BatteryLow)) return t("Battery low");
    if (this.hasFlag(id, SensorState.Active)) return t("Active");
    if (this.hasFlag(id, SensorState.Storage)) return t("Storage");
    if (this.hasFlag(id, SensorState.NotAvailable)) return "";

    return t("Unknown");
  }

  private isComposedState(id: number): boolean {
    return (
      id !== SensorState.Undefined &&
      this.hasFlag(id, SensorState.BatteryLow) &&
      (this.hasFlag(id, SensorState.Inactive) ||
        this.hasFlag(id, SensorState.SetForTooLong))
    );
  }

  private getSubstatus(id: number) {
    const { t } = this.props;

    if (this.isComposedState(id)) return t("Battery low");
  }

  private isRedStatus(id: number) {
    return this.getColor(id) === "red";
  }

  private isOrangeStatus(id: number) {
    return this.getColor(id) === "orange";
  }

  private isBlueStatus(id: number) {
    return this.getColor(id) === "blue";
  }

  private getColor(id: number) {
    if (id === SensorState.Undefined) return "blue";

    if (
      this.hasFlag(id, SensorState.Inactive) ||
      this.hasFlag(id, SensorState.Disconnected)
    )
      return "red";

    if (
      this.hasFlag(id, SensorState.BatteryLow) ||
      this.hasFlag(id, SensorState.SetForTooLong)
    )
      return "orange";

    return "blue";
  }

  private hasExtraInfo(id: number): boolean {
    return (
      id !== SensorState.Undefined && this.hasFlag(id, SensorState.Inactive)
    );
  }

  formatStatusSince(statusSince: string, defaultValue: any) {
    const timeSpan = moment.duration(statusSince);

    if (timeSpan.asMinutes() < 1) {
      return defaultValue;
    }

    if (timeSpan.asHours() < 1) {
      return `${timeSpan.minutes()}m`;
    }

    if (timeSpan.asDays() < 1) {
      return `${timeSpan.hours()}h ${timeSpan.minutes()}m`;
    }

    return `${Math.trunc(timeSpan.asDays())}d ${timeSpan.hours()}h`;
  }
}

export default withTranslation("sensor-grid")(SensorStatus);
