import * as React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { inject } from "mobx-react";
import AuthStore from "../store/auth-store";
import * as H from "history";
import { FeatureStore, TransitionStore } from "../store";

export enum ROLES {
  ADMIN,
  NURSEADMIN,
  NURSE,
  UNKNOWN,
}

export const ROLE_DEFAULT_PATH: any = {
  ADMIN: "/manage-sensors",
  NURSEADMIN: "/",
  NURSE: "/",
};

interface PrivateRouteProps extends Omit<RouteProps, "component"> {
  authStore?: AuthStore;
  featureStore?: FeatureStore;
  transitionStore?: TransitionStore;
  component: React.ElementType;
  children?: React.ReactNode;
  history?: H.History;
  requiredRoles?: ROLES[];
  shouldRedirect?: boolean;
}

@inject("authStore")
@inject("featureStore")
@inject("transitionStore")
export default class AuthorizedRoute extends React.Component<
  PrivateRouteProps,
  any
> {
  public render = () => {
    const {
      authStore,
      featureStore,
      component: Component,
      location,
      path,
      exact,
      strict,
      shouldRedirect,
      ...rest
    } = this.props;

    return <Route {...rest} render={this.routeRenderer} />;
  };

  routeRenderer = (props: any) => {
    const { authStore, component: Component, shouldRedirect } = this.props;
    const { featureStore } = this.props;
    const { isAuthenticated } = authStore!;

    if (!isAuthenticated) {
      return <Redirect to={{ pathname: "/login" }} />;
    }

    if (
      !featureStore!.features.initialConfigCompleted && authStore?.isAdmin() &&
      window.location.pathname !== "/manage-maris"
    ) {
      return <Redirect to={{ pathname: "/manage-maris" }} />;
    }
    
    if (this.isRoleAllowed() && !shouldRedirect) {
      return <Component {...props} />;
    } else {
      return this.redirectBasedOnRole();
    }
  };

  redirectBasedOnRole = () => {
    const role = this.getUserRole();

    if (role === ROLES.UNKNOWN) return null;

    const roleToString = ROLES[role];
    const path = ROLE_DEFAULT_PATH[roleToString];

    return <Redirect to={{ pathname: path }} />;
  };

  getUserRole = (): number => {
    const { authStore } = this.props;

    if (authStore?.isAdmin()) {
      return ROLES.ADMIN;
    }
    if (authStore?.isNurseAdmin()) {
      return ROLES.NURSEADMIN;
    }
    if (authStore?.isNurse()) {
      return ROLES.NURSE;
    }
    return ROLES.UNKNOWN;
  };

  isRoleAllowed = (): boolean => {
    const { requiredRoles } = this.props;
    const role = this.getUserRole();

    if (!requiredRoles) return true;

    if (requiredRoles.includes(role)) return true;
    return false;
  };
}
