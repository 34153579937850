import styles from "./icon.module.css";
import { IconElement } from "../../../../components/select-icon";
import { ReactNode } from "react";

export interface IAlertIconProps {
  data?: string;
  icon?: ReactNode;
}

export default function AlertIcon(props: IAlertIconProps) {
  const { data, icon } = props;
  if (!data && !icon) return <div />;

  return (
    <div className={styles["alert-icon"]}>
      {data &&
        <IconElement
          data={data}
          isCustom={true}
          className="status-icon"
          width="40px"
          height="40px"
        />}
      {icon && icon}
    </div>
  );
}
