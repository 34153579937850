import { ClientProfileStore } from "./store/store";
import { inject, observer } from "mobx-react";
import webApi from "./web";
import { ClientProfileCameraSettingsPage } from "./components/clientProfileCameraSettings";
import { Lambda, observe } from "mobx";
import { returnToClientProfile } from "./return-to-client-profile";
import { FeatureStore } from "../../store";
import { Component } from "react";
import { ChangeClientProfileCameraStatusCallOut } from "./store/typesOut";

interface IClientProfileEditCameraProps {
  history: any;
  clientProfileStore: ClientProfileStore;
  match: any;
  featureStore?: FeatureStore;
}

@inject("clientProfileStore")
@inject("featureStore")
@observer
export default class ClientProfileEditCamera extends Component<
  IClientProfileEditCameraProps,
  any
> {
  private _observationDisposers: Lambda[] = [];

  public componentDidMount() {
    this.disposeOfObservations();
    this.recordObservations();
  }

  public componentWillUnmount() {
    this.disposeOfObservations();
  }

  private disposeOfObservations = () => {
    if (this._observationDisposers.length === 0) {
      return;
    }
    this._observationDisposers.forEach((disposeObservation) =>
      disposeObservation()
    );
  };

  private recordObservations() {
    const { clientProfileStore } = this.props;
    const { clientProfileCameraSettings } = clientProfileStore;

    let observationDisposer = null;

    observationDisposer = observe(
      clientProfileCameraSettings,
      "optionTypeId",
      (change) => {
        clientProfileCameraSettings.hasChanges = true;
      }
    );
    this._observationDisposers.push(observationDisposer);
  }

  public render() {
    const { clientProfileStore, history, match, featureStore } = this.props;

    if (
      featureStore!.features.manageCameraViewSettings === false ||
      clientProfileStore.clientProfileDetails.clientId === -1
    ) {
      returnToClientProfile(history, match.params.id);
      return <div />;
    }
    return (
      <ClientProfileCameraSettingsPage
        clientProfileStore={clientProfileStore}
        history={history}
        saveCameraSettings={this.saveCameraSettings}
        cancelCameraSettings={this.cancelCameraSettings}
      />
    );
  }

  public saveCameraSettings = () => {
    const { clientProfileStore, history } = this.props;
    const cameraSettings = clientProfileStore.clientProfileCameraSettings;
    const clientDetails = clientProfileStore.clientProfileDetails;

    const data: ChangeClientProfileCameraStatusCallOut = {
      clientId: clientDetails.clientId!,
      cameraOptionTypeId: cameraSettings.optionTypeId,
      isActive: cameraSettings.isActive,
    };
    webApi
      .changeClientProfileCameraStatus(data)
      .then(() => {
        cameraSettings.hasChanges = false;
        history.goBack();
      })
      .catch((error: any) => {
        console.log(error);
        history.goBack();
      });
  };

  public cancelCameraSettings = () => {
    const { clientProfileStore, history } = this.props;
    const cameraSettings = clientProfileStore.clientProfileCameraSettings;

    cameraSettings.hasChanges = false;
    history.goBack();
  };
}
