import styles from "./client-profile-header.module.css";

import * as H from "history";
import { observer } from "mobx-react";
import { WithTranslation, withTranslation } from "react-i18next";
import { ClientProfileDetails } from "../../store/types";
import { PageHeader } from "../../../../components/page";
import { IconButton } from "../../../../components/icon-button";
import { ArrowLeft } from "../../../../components/svg";
import { Component } from "react";
import BackButton from "../../../../components/buttons/BackButton";

interface IClientHeaderProps extends WithTranslation {
  history: H.History;
  clientDetails: ClientProfileDetails;
  t: any;
}
@observer
export class ClientProfileHeader extends Component<IClientHeaderProps, any> {
  public render() {
    const { history, t, clientDetails } = this.props;

    return (
      <PageHeader>
        <div className={styles["title"]}>{t("Client Profile")}</div>
        <div className={styles["client-name"]}>{clientDetails.clientName}</div>
        <div className={styles["client-area"]}>
          <span>{clientDetails.areaName}</span>
          <span> - </span>
          <span className={styles["client-room"]}>
            {clientDetails.locationName}
          </span>
        </div>
        <div className={styles["header-button"]}>
          <BackButton sx={{ height: 40 }} onClick={() => history.push("/")}>{t("Back")}</BackButton>
        </div>
      </PageHeader>
    );
  }
}
export default withTranslation(["client-profile"])(ClientProfileHeader);
