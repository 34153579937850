import * as H from "history";
import ClientProfilePage from "./components/clientProfileDetails/client-profile-page";
import { ClientProfileStore } from "./store/store";
import webApi from "./web";
import { inject, observer } from "mobx-react";
import { runInAction } from "mobx";
import {
  ClientProfileDetails,
  ClientProfileSettings,
  ClientProfileAccessSettings,
  ClientProfileCameraSettings,
} from "./store/types";
import { Component, useEffect, useState } from "react";
import {
  BuildingAccessConverter,
  CameraSettingsConverter,
  ProfileDetailsConverter,
  ProfileSettingsConverter,
} from "./store/convertor";
import {
  ChangeActiveAccessTimeStatusCallOut,
  ChangeActiveAlertTimeStatusCallOut,
  ChangeClientProfileCameraStatusCallOut,
  ToggleClientProfileAccessStatusCallOut,
  ToggleClientProfileClientStatusCallOut,
  ToggleClientProfileRoomAlertsCallOut,
} from "./store/typesOut";
import { ReadClientProfileCallIn } from "./store/typesIn";
import { useTranslation } from "react-i18next";
import { TransitionStore } from "../../store";

interface IClientProfileProps {
  history: H.History;
  clientProfileStore: ClientProfileStore;
  transitionStore?: TransitionStore;
  match: any;
}

function ClientProfile(props: IClientProfileProps) {
  const [loaded, setLoaded] = useState(false);

  const { ready: readyClientProfile } = useTranslation("client-profile");

  const languagesLoaded = readyClientProfile;

  useEffect(() => {
    const transitionStore = props.transitionStore!;
    if (!languagesLoaded) {
      transitionStore.setLoading(true);
    } else {
      transitionStore.setLoading(false);
    }
  });

  useEffect(() => {
    clearClientProfileDetails();
    readClientProfileDetails();

    return () => {};
  }, []);

  const render = () => {
    const { history, clientProfileStore } = props;
    const clientDetails = clientProfileStore.clientProfileDetails;
    const clientSettings = clientProfileStore.clientProfileSettings;
    const clientAccess = clientProfileStore.clientProfileAccessDetails;
    const clientCameraSettings = clientProfileStore.clientProfileCameraSettings;
    const validationStatus = clientProfileStore.dataValidationStatus;

    return (
      <>
        {languagesLoaded && (
          <ClientProfilePage
            history={history}
            clientDetails={clientDetails}
            clientSettings={clientSettings}
            clientAccess={clientAccess}
            clientCameraSettings={clientCameraSettings}
            toggleClientStatus={toggleClientStatus}
            toggleAlertStatus={toggleAlertStatus}
            toggleAccessStatus={toggleAccessStatus}
            toggleCameraStatus={toggleCameraStatus}
            switchAlertTimeStatus={switchAlertTimeStatus}
            switchAccessTimeStatus={switchAccessTimeStatus}
            infoLoaded={loaded}
            dataValidationStatus={validationStatus}
          />
        )}
      </>
    );
  };

  const toggleAccessStatus = () => {
    const { clientProfileDetails } = props.clientProfileStore;
    const { clientId, isAccessProfileEnabled } = clientProfileDetails;
    const toggleData: ToggleClientProfileAccessStatusCallOut = {
      clientId: clientId!,
      enableAreaAccess: isAccessProfileEnabled,
    };

    webApi
      .toggleClientProfileAccessStatus(toggleData)
      .then(() => {
        readClientProfileDetails();
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const toggleClientStatus = () => {
    const { clientProfileStore } = props;
    const clientDetails = clientProfileStore.clientProfileDetails;
    const toggleData: ToggleClientProfileClientStatusCallOut = {
      ClientId: clientDetails.clientId!,
      Enabled: clientDetails.isStatusEnabled,
    };
    webApi
      .toggleClientProfileClientStatus(toggleData)
      .then(() => {
        readClientProfileDetails();
      })
      .catch((error: any) => {
        console.log("Error toggling status: " + error);
      });
  };

  const toggleCameraStatus = () => {
    const { clientProfileStore } = props;
    const cameraSettings = clientProfileStore.clientProfileCameraSettings;
    const clientDetails = clientProfileStore.clientProfileDetails;

    const data: ChangeClientProfileCameraStatusCallOut = {
      clientId: clientDetails.clientId!,
      cameraOptionTypeId: cameraSettings.optionTypeId,
      isActive: cameraSettings.isActive,
    };
    webApi
      .changeClientProfileCameraStatus(data)
      .then(() => {
        readClientProfileDetails();
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const toggleAlertStatus = () => {
    const { clientProfileStore } = props;
    const clientDetails = clientProfileStore.clientProfileDetails;
    const toggleData: ToggleClientProfileRoomAlertsCallOut = {
      ClientId: clientDetails.clientId!,
      enableRoomAlerts: clientDetails.isAlarmProfileEnabled,
    };
    webApi
      .toggleClientProfileRoomAlerts(toggleData)
      .then(() => {
        readClientProfileDetails();
      })
      .catch((error: any) => {
        console.log("Error toggling status: " + error);
      });
  };

  const switchAlertTimeStatus = () => {
    const { clientProfileDetails } = props.clientProfileStore;
    const { clientId, activeAlertPeriodId } = clientProfileDetails;
    const toggleData: ChangeActiveAlertTimeStatusCallOut = {
      clientId: clientId!,
      IntervalId: activeAlertPeriodId,
    };
    webApi
      .changeActiveAlertTimeStatus(toggleData)
      .then(() => {
        readClientProfileDetails();
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const switchAccessTimeStatus = () => {
    const { clientProfileDetails } = props.clientProfileStore;
    const { clientId, activeAccessPeriodId } = clientProfileDetails;
    const toggleData: ChangeActiveAccessTimeStatusCallOut = {
      clientId: clientId!,
      AccessGroupId: activeAccessPeriodId,
    };
    webApi
      .changeActiveAccessTimeStatus(toggleData)
      .then(() => {
        readClientProfileDetails();
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const readClientProfileDetails = () => {
    const clientId = props.match.params.id;

    webApi.readClientProfile(clientId).then((result: any) => {
      const { clientProfileData } = result.data;
      const profileDetails = ProfileDetailsConverter.toStore(
        clientProfileData.profileDetails
      );
      const profileSettings = ProfileSettingsConverter.toStore(
        clientProfileData.profileSettings
      );
      const profileAccess = BuildingAccessConverter.toStore(
        clientProfileData.buildingAccessDetails
      );
      const profileCamera = CameraSettingsConverter.toStore(
        clientProfileData.cameraSettings
      );
      runInAction(() => {
        mapClientDetails(profileDetails);
        mapClientSettings(profileSettings);
        mapClientAccess(profileAccess);
        mapClientCameraSettings(profileCamera);
        mapIsMariReachableSettings(clientProfileData);
        setLoaded(true);
      });
    });
  };

  const mapClientDetails = (data: ClientProfileDetails) => {
    const clientId = props.match.params.id;
    props.clientProfileStore.setClientProfileDetails(clientId, data);
  };

  const mapClientSettings = (data: ClientProfileSettings) => {
    props.clientProfileStore.clientProfileSettings = data;
  };

  const mapClientAccess = (data: ClientProfileAccessSettings) => {
    props.clientProfileStore.clientProfileAccessDetails = data;
  };

  const mapClientCameraSettings = (data: ClientProfileCameraSettings) => {
    props.clientProfileStore.setCameraSettings(data);
  };

  const mapIsMariReachableSettings = (data: ReadClientProfileCallIn) => {
    const { clientProfileStore } = props;
    clientProfileStore.setValidationDetails(data.isMariReachable);
  };

  const clearClientProfileDetails = () => {
    const { clientProfileStore } = props;
    clientProfileStore.clear();
  };

  return render();
}

export default inject(
  "clientProfileStore",
  "transitionStore"
)(observer(ClientProfile));
