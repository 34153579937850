import * as joi from "joi";

export const alertDataSchemaMandatoryTriggerPort = joi.object().keys({
  alertName: joi.string().trim().required(),
  triggerPort: joi.number().positive().required().allow(0),
});

export const alertDataSchema = joi.object().keys({
  alertName: joi.string().trim().required(),
  triggerPort: joi.number().positive().allow(null),
});