import { ClientProfileStore } from "./store/store";
import { inject, observer } from "mobx-react";
import webApi from "./web";
import { ClientProfileAlertSettingsPage } from "./components/clientProfileAlertSettings";
import { observe, Lambda } from "mobx";
import { returnToClientProfile } from "./return-to-client-profile";
import { AlertData } from "./store/types";
import { Component } from "react";
import { UpdateClientProfileAlertSettingsCallOut } from "./store/typesOut";

interface IClientProfileEditAlertsProps {
  history: any;
  clientProfileStore: ClientProfileStore;
  match: any;
}

@inject("clientProfileStore")
@observer
export class ClientProfileEditAlerts extends Component<
  IClientProfileEditAlertsProps,
  any
> {
  private _observationDisposers: Lambda[] = [];

  public componentDidMount() {
    this.disposeOfObservations();
    this.recordObservations();
  }

  public componentWillUnmount() {
    this.disposeOfObservations();
  }

  private disposeOfObservations = () => {
    if (this._observationDisposers.length === 0) {
      return;
    }
    this._observationDisposers.forEach((disposeObservation) =>
      disposeObservation()
    );
  };

  private recordObservations() {
    const { clientProfileStore, history } = this.props;
    if (!history.location.state) return;

    const { editingPeriodId } = history.location.state!;
    const { clientProfileSettings } = clientProfileStore;

    if (clientProfileStore.clientProfileDetails.clientId === -1) {
      return;
    }

    this._observationDisposers = [];
    const currentEditInterval =
      clientProfileSettings.getInterval(editingPeriodId);

    const observationDisposer = observe(currentEditInterval, (change) => {
      clientProfileSettings.hasChanges = true;
    });

    this._observationDisposers.push(observationDisposer);
  }

  public render() {
    const { clientProfileStore, history, match } = this.props;

    if (clientProfileStore.clientProfileDetails.clientId === -1) {
      returnToClientProfile(history, match.params.id);
      return <div />;
    }
    return (
      <ClientProfileAlertSettingsPage
        clientProfileStore={clientProfileStore}
        history={history}
        saveAlertsSettings={this.saveAlertsSettings}
        cancelAlertChanges={this.cancelAlertChanges}
      />
    );
  }

  public saveAlertsSettings = () => {
    const { history } = this.props;
    const { editingPeriodId } = history.location.state;
    const { clientProfileDetails, clientProfileSettings } =
      this.props.clientProfileStore;

    const currentEditInterval =
      clientProfileSettings.getInterval(editingPeriodId);

    const data: UpdateClientProfileAlertSettingsCallOut = {
      clientId: clientProfileDetails.clientId!,
      clientProfileIntervalId: currentEditInterval.intervalId,
      alertsData: this.getAlertsDataToUpdate(),
      startTime: currentEditInterval.startTime,
    };

    webApi
      .updateClientProfileAlertSettings(data)
      .then(() => {
        clientProfileSettings.hasChanges = false;
        history.goBack();
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  private getAlertsDataToUpdate(): AlertData[] {
    const { clientProfileAlerts } = this.props.clientProfileStore;

    const alerts: AlertData[] = [];

    clientProfileAlerts.alerts.forEach((alert) => {
      const entry: AlertData = {
        alertId: alert.alertId,
        delay: alert.delay,
        sound: alert.sound,
        isActive: alert.isActive,
      };
      alerts.push(entry);
    });

    return alerts;
  }

  private cancelAlertChanges = () => {
    const { history, clientProfileStore } = this.props;
    const { clientProfileSettings } = clientProfileStore;

    clientProfileSettings.hasChanges = false;
    history.goBack();
  };
}
