import styles from "./edit-substatus.module.css";

import { inject, observer } from "mobx-react";
import ParentStatusDropdown from "./components/parent-status-dropdown";
import StatusNameInput from "./components/status-name-input";
import BrowseForFile from "./components/upload-file-action";
import AlertDropdown from "./components/alert-dropdown";
import { SubstatusDataStore } from "../../store/store";
import CancelButton from "../../../../components/buttons/CancelButton";
import SaveButton from "../../../../components/buttons/SaveButton";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { scrollIntoViewAndAlign } from "../../../../utils/scroll";
import { runInAction } from "mobx";

interface EditSubstatusProps {
  substatusDataStore?: SubstatusDataStore;
  saveLocked?: boolean;
  onSaveClick(): void;
  onCancelClick(): void;
}

function EditSubstatus(props: EditSubstatusProps) {
  let editComponentElement: HTMLTableRowElement;
  const substatusDataStore = props.substatusDataStore!;
  const { saveLocked, onSaveClick, onCancelClick } = props;
  const { t } = useTranslation("common");

  useEffect(() => {
    scrollIntoViewAndAlign(editComponentElement);

    return function unmount() {
      runInAction(() => {
        substatusDataStore.errors = null;
        substatusDataStore.errorMessages = null;
        substatusDataStore.setValidated(true);
      });
    };
  }, []);

  const render = () => {
    return (
      <tr
        className={styles["edit-substatus"]}
        ref={(control: HTMLTableRowElement) => (editComponentElement = control)}
      >
        <td className={styles["first"]}>
          <div className={styles["column"]}>
            <StatusNameInput />
          </div>
        </td>
        <td>
          <div className={styles["column"]}>
            <ParentStatusDropdown />
          </div>
        </td>
        <td>
          <div className={styles["column"]}>
            <AlertDropdown />
          </div>
        </td>
        <td>
          <div className={styles["column"]}>
            <BrowseForFile />
          </div>
        </td>
        <td className={styles["action-column"]}>
          <div className={styles["action-buttons-container"]}>
            <div className={styles["action-buttons"]}>
              <CancelButton
                onClick={() => {
                  onCancelClick();
                }}
              >
                {t("Cancel")}
              </CancelButton>

              <SaveButton
                onClick={() => {
                  onSaveClick();
                }}
                disabled={saveLocked}
              >
                {t("Save")}
              </SaveButton>
            </div>
          </div>
        </td>
      </tr>
    );
  };

  return render();
}

export default inject("substatusDataStore")(observer(EditSubstatus));
