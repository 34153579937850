import * as React from "react";
import { Route } from "react-router-dom";

import AuthorizedRoute from "../../components/authorized-route";

import ClientDashboard from "./client-dashboard.page";

const ProtectedClientDashboard = (props: any) => (
  <AuthorizedRoute path="/" component={ClientDashboard} {...props} />
);
const clientDashboardRoute: React.ReactElement<Route> = (
  <Route
    exact
    path="/client-dashboard/:id"
    component={ProtectedClientDashboard}
  />
);

export default clientDashboardRoute;
