import { inject, observer } from "mobx-react";
import { IconUploadField } from "../../../../../components/form/edit-fields/icon-upload-field/IconUploadField";
import { FeatureStore } from "../../../../../store";
import { AlertDataStore } from "../../../store/store";
import {
  alertDataSchema,
  alertDataSchemaMandatoryTriggerPort,
} from "../../../validation";

export interface IBrowseForIconProps {
  alertDataStore?: AlertDataStore;
  featureStore?: FeatureStore;
}

function BrowseForIcon(props: IBrowseForIconProps) {
  const alertDataStore = props.alertDataStore!;
  const featureStore = props.featureStore!;

  const validate = () => {
    let schema = alertDataSchema;

    if (featureStore.features.showTriggerPort) {
      schema = alertDataSchemaMandatoryTriggerPort;
    }

    alertDataStore.validateSelectedAlert(schema);
  };

  const onIconChanged = (data: FormData): void => {
    alertDataStore.setSelectedIcon(data);
    validate();
  };

  return (
    <IconUploadField
      currentIcon={alertDataStore.selectedAlert.icon}
      iconMemberName="icon"
      onIconChanged={onIconChanged}
    />
  );
}

export default inject(
  "alertDataStore",
  "featureStore"
)(observer(BrowseForIcon));
