import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SelectInput } from "../../../../../components/form-inputs";
import { ClientDataStore } from "../../../store/store";
import { IRoomData } from "../../../store/types";

export interface ClientLocationDropdownProps {
  clientDataStore?: ClientDataStore;
}

function ClientLocationDropdown(props: ClientLocationDropdownProps) {
  const { clientDataStore } = props;
  const { t } = useTranslation("common");
  const { t: clientT } = useTranslation("client-grid");
  const locationId = clientDataStore!.selectedClient.locationId;
  const [locationDropdownData, setLocationDropdownData] = useState([]);

  const formatRoomDisplay = (room: IRoomData) => {
    return `${room.areaName} - ${room.locationName}`;
  };

  useEffect(() => {
    setLocationDropdownData(buildLocationDropdownData());
  }, []);

  const buildLocationDropdownData = () => {
    const { clientDataStore } = props;
    const { rooms, selectedClient } = clientDataStore!;

    let roomsList: any = rooms;
    if (
      selectedClient.locationId &&
      roomsList.findIndex(
        (loc: any) => loc.locationId === selectedClient.locationId
      ) === -1
    ) {
      roomsList = roomsList.concat(selectedClient);
    }

    const dropdownData = roomsList
      .map((value: any) => ({
        id: value.locationId,
        areaName: value.areaName,
        locationId: value.locationId,
        locationName: value.locationName,
        value: formatRoomDisplay(value),
      }))
      .sort((a: any, b: any) => {
        if (a.value < b.value) {
          return -1;
        }

        if (a.value === b.value) {
          return 0;
        }

        return 1;
      });

    dropdownData.unshift({
      id: null,
      areaName: "",
      locationId: 0,
      locationName: "",
      value: t("NoRoomAssigned"),
    });
    return dropdownData;
  };

  const emptyRoom = {
    id: null,
    areaName: "",
    locationId: 0,
    locationName: "",
    value: t("NoRoomAssigned"),
  };
  const selectedRoom =
    locationDropdownData.find((value: any) => value.id === locationId) ||
    emptyRoom;

  return (
    <SelectInput
      showToolTip
      options={locationDropdownData}
      label={clientT("Room").toUpperCase()}
      placeholder={t("NoRoomAssigned")}
      value={selectedRoom}
      onChange={(_event: any, value: any) => {
        if (value === null) {
          clientDataStore!.setSelectedClientLocationData(0, "", "");
          clientDataStore!.validateSelectedClient();
          return;
        }
        clientDataStore!.setSelectedClientLocationData(
          value.locationId,
          value.locationName,
          value.areaName
        );
        clientDataStore!.validateSelectedClient();
      }}
    ></SelectInput>
  );
}

export default inject("clientDataStore")(observer(ClientLocationDropdown));
