import { useState } from "react";
import { FilterField } from "../../../components/form/filter-field";
import { observer, inject } from "mobx-react";
import { ClientDataStore } from "../store/store";
import { useTranslation } from "react-i18next";
import { Search } from "../../../components/svg";
import { IClientDataInactiveClients } from "../store/types";
export interface SearchInactiveClientProps {
  onSearchChange(item: any): void;
  clientDataStore?: ClientDataStore;
}

const SearchInactiveClient = (props: SearchInactiveClientProps) => {
  const [searchValue, setSearchValue] = useState("");
  const [isSearchItemSelected, setIsSearchItemSelected] = useState(false);
  const { clientDataStore, onSearchChange } = props;
  const { t } = useTranslation("search-client");

  const searchInputChange = (value: any) => {
    setIsSearchItemSelected(false);
    setSearchValue(value);

    if (value === "") {
      clearSearchSelection();
    }
  };

  const clearSearchSelection = () => {
    const { clientDataStore } = props;

    if (!clientDataStore) return;

    clientDataStore.setHighlightInactiveClient(null);
    onSearchChange(null);
  };

  const getSearchValues = () => {
    if (!searchValue || !clientDataStore) return [];

    return clientDataStore.inactiveClients
      .filter((value: IClientDataInactiveClients) => {
        return value.clientName
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      })
      .sort((a: IClientDataInactiveClients, b: IClientDataInactiveClients) => {
        if (a.clientName === b.clientName) return 0;
        return a.clientName > b.clientName ? 1 : -1;
      })
      .map((val, index) => {
        return {
          key: index,
          object: val,
          displayValue: val.clientName,
        };
      });
  };

  const setSearchSelection = (item: any) => {
    setSearchValue(item.clientName);
    setIsSearchItemSelected(true);
    onSearchChange(item);
  };

  const searchValues = getSearchValues();

  return (
    <FilterField
      icon={Search}
      placeholder={t("Search client")}
      inputValue={searchValue}
      values={searchValues}
      onValueChange={(value) => searchInputChange(value)}
      onSelect={setSearchSelection}
      isOpen={!isSearchItemSelected && searchValues.length !== 0}
    ></FilterField>
  );
};

export default inject("clientDataStore")(observer(SearchInactiveClient));
