import { TextField, TextFieldProps } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";

import styles from "./text-field.module.css";

interface TextFieldInputProps {
  label?: string;
  mandatory?: boolean;
  errorMessage?: ReactNode;
  helperMessage?: ReactNode;
  valueChangeDebounceMS?: number;
  onValueChangeDebounced?(): void;
}

const DEFAULT_DEBOUNCE_MS = 300;

function TextFieldInput(props: TextFieldInputProps & TextFieldProps) {
  const {
    errorMessage,
    helperMessage,
    label,
    value,
    valueChangeDebounceMS,
    onValueChangeDebounced,
    mandatory,
    ...rest
  } = props;

  const [initialized, setInitialized] = useState(false);

  const getLabel = () => {
    if (label && mandatory) return label + " *";
    return label;
  };

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      const debounceTimeout = setTimeout(
        () => {
          if (onValueChangeDebounced) onValueChangeDebounced();
        },
        !valueChangeDebounceMS ? DEFAULT_DEBOUNCE_MS : valueChangeDebounceMS
      );
      return () => clearTimeout(debounceTimeout);
    }
  }, [value]);

  let helperText: ReactNode = null;

  if (
    errorMessage !== null &&
    errorMessage !== undefined &&
    errorMessage !== ""
  ) {
    helperText = errorMessage;
  } else {
    helperText = helperMessage;
  }

  const render = () => {
    return (
      <div className={styles["text-field-input"]}>
        <TextField
          value={value}
          label={getLabel()}
          error={
            errorMessage !== null &&
            errorMessage !== undefined &&
            errorMessage !== ""
          }
          autoComplete="off"
          fullWidth
          id="outlined-basic"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          helperText={helperText}
          {...rest}
        />
      </div>
    );
  };

  return render();
}

export default TextFieldInput;
