import styles from "./historical.module.css";

import moment from "moment";
import { observer, inject } from "mobx-react";
import { LastDaysPanel } from "./last-days.panel";
import web from "../../web";
import { IClientStatus } from "../../store/types";
import { TabContainer } from "../../components/tabs/tab-container";
import { Component } from "react";
import { ClientStatusesConverter } from "../../store/convertor";

export interface IHistoricalViewProps {
  clientId: number;
  clientDashboardStore?: any;
  t(value: string): string;
}

@inject("clientDashboardStore")
@observer
export class HistoricalView extends Component<IHistoricalViewProps, any> {
  constructor(props: IHistoricalViewProps) {
    super(props);

    const { clientDashboardStore } = this.props;

    web
      .readStatusSummary(this.props.clientId)
      .then((result: any) => {
        const clientStatuses = result.data.clientStatuses as IClientStatus[];

        const clientStatusesList = clientStatuses.map((clientStatus) => {
          return ClientStatusesConverter.toStore(clientStatus);
        });

        clientDashboardStore.clientStatuses.replace(clientStatusesList);
      })
      .catch(() => {});
  }

  public render() {
    const { clientDashboardStore, t } = this.props;
    const { clientStatuses } = clientDashboardStore;
    const inTheLastSevenDays = this.inTheLastSevenDays();

    const thirtyDaysHoursData = clientStatuses.map(this.mapHours);
    const thirtyDaysTimesData = clientStatuses.map(this.mapTimes);

    const sevenDaysHoursData = clientStatuses
      .filter(inTheLastSevenDays)
      .map(this.mapHours);
    const sevenDaysTimesData = clientStatuses
      .filter(inTheLastSevenDays)
      .map(this.mapTimes);

    const lastStatus = clientDashboardStore.statusesAndAlerts.find(
      (el: any) => {
        return el.type === 1;
      }
    );

    if (lastStatus) {
      if (lastStatus.typeId === 2) {
        sevenDaysTimesData[sevenDaysTimesData.length - 1].timesInBed -= 1;
        thirtyDaysTimesData[thirtyDaysTimesData.length - 1].timesInBed -= 1;
      }
    }

    return (
      <div className={styles["historical-view"]}>
        <TabContainer
          defaultTab={0}
          tabList={[
            {
              component: (
                <LastDaysPanel
                  isSevenDaysView
                  hoursData={sevenDaysHoursData}
                  timesData={sevenDaysTimesData}
                  t={t}
                />
              ),
              title: t("Last 7 days"),
            },
            {
              component: (
                <LastDaysPanel
                  isSevenDaysView={false}
                  hoursData={thirtyDaysHoursData}
                  timesData={thirtyDaysTimesData}
                  t={t}
                />
              ),
              title: t("Last 30 days"),
            },
          ]}
        />
      </div>
    );
  }

  private mapHours(c: IClientStatus) {
    return {
      day: moment(c.timeStamp).format("D"),
      hoursInBathroom: c.hoursInBathroom,
      hoursInBed: c.hoursInBed,
      hoursInRoom: c.hoursInRoom,
      hoursOutOfRoom: c.hoursOutOfRoom,
    };
  }

  private mapTimes(c: IClientStatus) {
    return {
      day: moment(c.timeStamp).format("D"),
      timesInBed: c.timesInBed,
      timesInBathroom: c.timesInBathroom,
      timesInRoom: c.timesInRoom,
      timesOutOfRoom: c.timesOutOfRoom,
    };
  }

  private inTheLastSevenDays() {
    const today = moment().seconds(0).minutes(0).hours(0);
    return (c: IClientStatus) => {
      const entryDate = moment(c.timeStamp);
      const diffInDays = moment.duration(today.diff(entryDate)).asDays();

      return diffInDays < 8;
    };
  }
}
