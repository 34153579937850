import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "mobx-react";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import Routes from "./routes";
import stores from "./store";
import { ErrorBoundary } from "./components/error-boundary";
import { CreateConfirmationDialog } from "./utils/ConfirmationDialog";

class SmartSenseApp extends React.Component<{}, {}> {
  public render() {
    return (
      <ErrorBoundary>
        <Provider {...stores}>
          <I18nextProvider i18n={i18n}>
            <BrowserRouter getUserConfirmation={this.showGlobalModal}>
              <Routes />
            </BrowserRouter>
          </I18nextProvider>
        </Provider>
      </ErrorBoundary>
    );
  }

  private showGlobalModal = (_message: any, cb: any) => {
    const { transitionStore } = stores;

    const data = CreateConfirmationDialog(
      i18n.t("You have unsaved changes!"),
      i18n.t("Do you still want to leave this page?"),
      i18n.t("Leave"),
      i18n.t("Stay"),
      false,
      () => {
        cb(true);
      }
    );
    transitionStore.showGlobalDialog(data);
  };
}

function renderApp() {
  ReactDOM.render(<SmartSenseApp />, document.getElementById("react-app"));
}
renderApp();
