import style from "./client-status.module.css";

import classNames from "classnames";
import * as moment from "moment";
import {
  SelectIcon,
  StatusIcon,
  IconElement,
} from "../../../../../components/select-icon";
import { IClientData } from "../../../store/store";
import { WithTranslation, withTranslation } from "react-i18next";
import { Component } from "react";
import { inject, observer } from "mobx-react";
import { FeatureStore } from "../../../../../store";

interface ClientStatusCellProps extends WithTranslation {
  original: IClientData;
  lastLocations: any;
  statusesTranslation: any;
  t: any;
  showLastLocation(id: number): void;
  featureStore?: FeatureStore;
}

export class ClientStatusCell extends Component<ClientStatusCellProps> {
  public render() {
    const { original, t, lastLocations, featureStore, statusesTranslation } =
      this.props;

    const alertOverride = original.alertId === 6;

    const canShowLastLocation =
      featureStore?.features.showLastLocation === true &&
      lastLocations[original.id!] === undefined &&
      hasLocation(original);

    const hasLastLocationRecord = lastLocations[original.id!] !== undefined;

    const iconProps = {
      alertId: original.alertId,
      alertIcon: original.alertIcon,
      statusId: original.statusId,
      statusIcon: original.statusIcon,
      parentStatusId: original.parentStatusId,
      alertDelayed: original.alertDelayed,
      isNursePresent: original.nursePresent,
    };

    const SelectedIcon = SelectIcon(iconProps);

    let mainTimestamp = original.statusTimestamp;
    let secondaryTimestamp = "";

    if (original.nursePresent) {
      mainTimestamp = original.extraTimestamp;
      if (original.statusId === 2)
        secondaryTimestamp = original.statusTimestamp!;
    } else if (hasVisitor(original)) {
      if (original.alertId !== 0) {
        mainTimestamp = original.alertTimestamp;
        if (hasStatusChanged(original, t))
          secondaryTimestamp = original.statusTimestamp!;
      } else {
        mainTimestamp = original.extraTimestamp;
        secondaryTimestamp = original.statusTimestamp!;
      }
    } else if (original.alertId !== 0) {
      mainTimestamp = original.alertTimestamp;
      if (hasStatusChanged(original, t))
        secondaryTimestamp = original.statusTimestamp!;
    } else {
      mainTimestamp = original.statusTimestamp;
    }

    const hasExtraTimestamp = secondaryTimestamp !== "";

    return (
      <div
        className={classNames(style["client-status-cell"], {
          [style["green"]]: original.nursePresent,
          [style["orange"]]:
            !alertOverride && original.alertId && original.alertDelayed,
          [style["red"]]:
            alertOverride || (original.alertId && !original.alertDelayed),
        })}
      >
        <StatusIcon>
          <IconElement
            data={SelectedIcon.element}
            isCustom={SelectedIcon.isCustomIcon}
            className={SelectedIcon.className}
            alertId={iconProps.alertId}
            width="40px"
            height="40px"
          />
        </StatusIcon>

        <div className={style["status-position"]}>
          <div className={`${style["status-text"]} ${style["top-text"]}`}>
            <div className={style["status"]}>
              {formatStatusText(original, statusesTranslation)}
            </div>
            <div className={style["timestamp"]}>
              {formatStatusSince(mainTimestamp)}
            </div>
          </div>

          {hasExtraTimestamp && (
            <div className={`${style["status-text"]} ${style["bottom-text"]}`}>
              <div className={style["current-status"]}>
                <div className={style["show-current-status"]}>{t("Now")}</div>
                <div className={style["client-status-separator"]}>-</div>
                <div className={style["show-current-status-bottom"]}>
                  {GetSecondaryStatus(original, statusesTranslation)}
                </div>
              </div>
              <div>
                {hasExtraTimestamp && (
                  <div className={style["timestamp"]}>
                    {formatStatusSince(secondaryTimestamp)}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {this.showLocation(canShowLastLocation, hasLastLocationRecord)}
      </div>
    );
  }

  private showLocation(
    canShowLastLocation: boolean,
    hasLastLocationRecord: boolean
  ) {
    const { original, lastLocations, t } = this.props;

    return (
      <div className={style["status-cell-location"]}>
        {canShowLastLocation && (
          <div
            className={style["show-location"]}
            onClick={() => this.props.showLastLocation(original.id!)}
          >
            {t("Show location")}
          </div>
        )}
        {hasLastLocationRecord && (
          <div className={style["status-location"]}>
            {lastLocations[original.id!].name}
          </div>
        )}
      </div>
    );
  }
}

export default inject("featureStore")(
  observer(withTranslation(["client-grid"])(ClientStatusCell))
);

function hasVisitor(original: IClientData) {
  return original.statusId === 3 || original.alertId === 5;
}

function hasLocation(original: IClientData) {
  return (
    [5, 9].includes(original.statusId!) ||
    [5, 9].includes(original.parentStatusId!)
  );
}

function hasStatusChanged(original: any, t: any) {
  const alert = MapAlert(original, t);
  const status = GetCurrentStatus(
    t,
    original.statusId!,
    original.parentStatusId!,
    original.status
  );

  return alert !== status;
}

function formatStatusSince(statusSince: any) {
  const timeSpan = moment.duration(statusSince);

  if (timeSpan.asMinutes() < 1) {
    return "";
  }

  if (timeSpan.asHours() < 1) {
    return `${timeSpan.minutes()}m`;
  }

  if (timeSpan.asDays() < 1) {
    return `${timeSpan.hours()}h ${timeSpan.minutes()}m`;
  }

  return `${Math.trunc(timeSpan.asDays())}d ${timeSpan.hours()}h`;
}

export function formatStatusText(original: IClientData, t: any) {
  if (original.nursePresent) return t("Nurse present");
  if (!!original.alertId) return MapAlert(original, t);
  if (hasVisitor(original)) return t("Visitor detected");

  return GetCurrentStatus(
    t,
    original.statusId!,
    original.parentStatusId!,
    original.status
  );
}

function GetCurrentStatus(
  t: any,
  statusId: number,
  parentStatusId: number,
  defaultStatus?: string
) {
  const hasParentStatus =
    parentStatusId !== null && parentStatusId !== undefined;

  if (hasParentStatus && statusId < 10) {
    if (statusId === 1) return MapStatus(parentStatusId, t);
    return MapStatus(statusId, t);
  } else if (!!statusId) {
    return MapStatus(statusId, t, defaultStatus);
  }
  return t("Unknown");
}

function GetSecondaryStatus(original: IClientData, t: any) {
  if (original.alertId !== 0) {
    return GetCurrentStatus(
      t,
      original.statusId!,
      original.parentStatusId!,
      original.status
    );
  } else {
    const statusId = original.statusId == 3 ? 2 : original.statusId!;
    return GetCurrentStatus(
      t,
      statusId,
      original.parentStatusId!,
      original.status
    );
  }
}

function MapAlert(clientData: IClientData, t: any) {
  switch (clientData.alertId) {
    case 1:
      return t("Out of bed");
    case 2:
      return t("In bathroom");
    case 3:
      return t("Out of room");
    case 4:
      return t("Inactivity");
    case 5:
      return t("Visitor detected");
    case 8:
      return t("In bed");
    case 6:
      return t("Assistance");
    case 7:
      return t("Voice alert");
    default:
      return clientData.alert;
  }
}

function MapStatus(
  statusId: number,
  t: (translateKey: string) => string,
  defaultText?: string
) {
  switch (statusId) {
    case 3:
      return t("Visitor detected");
    case 2:
      return t("In bed");
    case 1:
    case 4:
      return t("In room");
    case 5:
      return t("Out of room");
    case 6:
      return t("In bathroom");
    case 7:
      return t("Unknown");
    case 8:
      return t("Status OFF");
    case 9:
      return t("Out of bed");
    case 10:
      return t("In chair");
    default:
      return defaultText;
  }
}
