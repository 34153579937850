import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import {
  SelectInput,
  TextFieldInput,
} from "../../../../../components/form-inputs";
import { AuthStore } from "../../../../../store";

import { SensorDataStore } from "../../../store/store";

export interface TypeDropDownProps {
  sensorDataStore?: SensorDataStore;
  authStore?: AuthStore;
}

function TypeDropDown(props: TypeDropDownProps) {
  const sensorDataStore = props.sensorDataStore!;
  const authStore = props.authStore!;

  const { t } = useTranslation("sensor-grid");
  const type = sensorDataStore!.selectedSensor.sensorType;
  const { errors } = sensorDataStore;
  const sensorType = sensorDataStore!.selectedSensor.sensorType;

  const buildSensorTypeDropdownData = () => {
    const { availableSensorTypes } = sensorDataStore.selectedSensor;

    const dropdownData = availableSensorTypes
      .map((value) => ({
        id: value,
        value: t(value),
      }))
      .sort((a, b) => {
        if (a.value < b.value) {
          return -1;
        }

        if (a.value === b.value) {
          return 0;
        }

        return 1;
      });

    if (dropdownData.length > 0 && dropdownData[0].id == "VirtualSensor") {
      dropdownData[0] = { id: type, value: type };
    }

    return dropdownData;
  };

  const typeValues = buildSensorTypeDropdownData();
  const selectedRoom =
    typeValues.find((value: any) => value.id === type) || typeValues[0];

  return (
    <>
      {authStore.isAdmin() ? (
        <SelectInput
          mandatory
          options={typeValues}
          label={t("SensorType").toUpperCase()}
          value={selectedRoom}
          disabled={typeValues.length == 1}
          onChange={(_event: any, value: any) => {
            sensorDataStore.setSelectedSensorType(value.id);
            sensorDataStore.setSelectedSensorHasChanges(true);
            sensorDataStore.validateSelectedSensor("sensorId");
          }}
          errorMessage={
            (errors && errors["sensorType"] && t("Required information")) || ""
          }
        ></SelectInput>
      ) : (
        <TextFieldInput
          mandatory
          value={t(sensorType)}
          disabled={true}
          label={t("SensorType").toUpperCase()}
        />
      )}
    </>
  );
}

export default inject("sensorDataStore", "authStore")(observer(TypeDropDown));
