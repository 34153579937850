import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { TextFieldInput } from "../../../../../components/form-inputs";
import { SensorDataStore } from "../../../store/store";

export const ERRORKEY_MAP: Record<string, string> = {
  "string.empty": "Required information",
  IDB: "IDB",
  IDC: "IDC",
  ID6: "ID6",
  "IDA-F": "IDA-F",
  "IDA-Z": "IDA-Z",
};
export interface ClientNameInputProps {
  sensorDataStore?: SensorDataStore;
}

function SensorIdInput(props: ClientNameInputProps) {
  const sensorDataStore = props.sensorDataStore!;
  const { t } = useTranslation("sensor-grid");
  const sensorId = sensorDataStore.selectedSensor.sensorId;
  const { errorMessages } = sensorDataStore;

  const mapedErrors = () => {
    let errorStringList: string[] = [];

    if (errorMessages && errorMessages["sensorId"]) {
      errorStringList = errorMessages["sensorId"].map((error) => {
        return ERRORKEY_MAP[error];
      });
    }

    return errorStringList;
  };

  const renderErrorList = () => {
    const errorList = mapedErrors();

    if (errorList.length == 0) {
      return null;
    }

    const list = errorList.map((errorText) => {
      return <li>{t(errorText)}</li>;
    });

    return (
      <ul style={{ margin: 0, padding: 0, paddingLeft: "5px" }}>{list}</ul>
    );
  };

  return (
    <TextFieldInput
      autoFocus
      value={sensorId}
      disabled={!sensorDataStore.selectedSensor.isNewSensor}
      mandatory
      errorMessage={renderErrorList()}
      label={t("SensorId").toUpperCase()}
      placeholder={t("SensorId")}
      onChange={(event: any) => {
        const { value } = event.target;
        if (!event.target.validity.valid) return;

        sensorDataStore.setSelectedSensorSensorId(value);
        sensorDataStore.setSelectedSensorHasChanges(true);
        sensorDataStore.setValidated(false);
      }}
      onValueChangeDebounced={() => {
        sensorDataStore.validateSelectedSensor("sensorId");
        sensorDataStore.setValidated(true);
      }}
    />
  );
}

export default inject("sensorDataStore")(observer(SensorIdInput));
