import styles from "./client-profile-camera-settings-header.module.css";

import { observer } from "mobx-react";
import { ClientProfileDetails } from "../../store/types";
import { PageHeader } from "../../../../components/page";
import { WithTranslation, withTranslation } from "react-i18next";
import { CrossX, Save } from "../../../../components/svg";
import { IconButton } from "../../../../components/icon-button";
import { Component } from "react";
import SaveButton from "../../../../components/buttons/SaveButton";
import CancelButton from "../../../../components/buttons/CancelButton";

interface IClientCameraHeaderProps extends WithTranslation {
  clientDetails: ClientProfileDetails;
  t: any;
  title: string;
  saveCameraSettings(): void;
  cancelCameraSettings(): void;
}

@observer
export class ClientProfileCameraSettingsHeader extends Component<
  IClientCameraHeaderProps,
  any
> {
  public render() {
    const {
      title,
      t,
      saveCameraSettings,
      cancelCameraSettings,
      clientDetails,
    } = this.props;

    return (
      <PageHeader>
        <div className={styles["title"]}>{t(title)}</div>
        <div className={styles["client-name"]}>{clientDetails.clientName}</div>
        <div className={styles["client-area"]}>
          <span>{clientDetails.areaName}</span>
          <span> - </span>
          <span className={styles["client-room"]}>
            {clientDetails.locationName}
          </span>
        </div>

        <div className={`${styles["header-button"]} ${styles["save-button"]}`}>
          <SaveButton variant="contained" onClick={() => saveCameraSettings()}>
            {t("common:Save")}
          </SaveButton>
        </div>
        <div className={styles["header-button"]}>
          <CancelButton onClick={() => cancelCameraSettings()} variant="contained">
            {t("common:Cancel")}
          </CancelButton>
        </div>

      </PageHeader>
    );
  }
}
export default withTranslation(["client-profile"])(
  ClientProfileCameraSettingsHeader
);
