import "./page-header.css";
import * as React from "react";
import classnames from "classnames";

export default class AmstelPageHeader extends React.Component<
  {
    children: any;
    className?: string;
  },
  any
> {
  private _header: HTMLDivElement;

  public render() {
    const { props } = this;
    return (
      <div
        ref={(header) => (this._header = header as HTMLDivElement)}
        className={classnames("amstel-page-header", props.className, {
          "mari-height": window.parent !== window.self,
        })}
      >
        {props.children}
      </div>
    );
  }
}
