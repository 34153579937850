import LanguageDetector from "i18next-browser-languagedetector";
import i18next from "i18next";
import HttpApi from "i18next-http-backend";

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .init(
    {
      fallbackLng: "en",
      load: "languageOnly",
      detection: {
        order: ["navigator"],
        caches: [],
      },

      react: {
        // wait: true, // globally set to wait for loaded translations in translate hoc
        useSuspense: false,
      },

      // have a common namespace used around the full app
      ns: ["common"],
      defaultNS: "common",

      debug: false,

      interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ",",
        format(value, format, lng) {
          if (format === "uppercase") {
            return value.toUpperCase();
          }
          if (format === "lowercase") {
            return value.toLowerCase();
          }
          return value;
        },
      },
    },
    (err) => {
      if (err) return console.warn("I18n failed to load...", err);
    }
  );

export default i18next;
