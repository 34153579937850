import styles from "./client-profile-content.module.css";

import * as H from "history";
import { observer, inject } from "mobx-react";
import {
  ClientProfileDetails,
  ClientProfileSettings,
  ClientProfileAccessSettings,
  ClientProfileCameraSettings,
  DataValidationStatus,
} from "../../store/types";
import ClientProfileAccessTab from "./client-profile-access-tab";
import ClientProfileAlertsTab from "./client-profile-alerts-tab";
import ClientProfileClientStatusTab from "./client-profile-client-status-tab";
import ClientProfileCameraTab from "./client-profile-camera-tab";
import ClientProfileSensorIssuesTab from "./client-profile-sensor-issues-tab";
import { AuthStore, FeatureStore, TransitionStore } from "../../../../store";
import { Component } from "react";

interface IClientContentProps {
  clientDetails: ClientProfileDetails;
  clientSettings: ClientProfileSettings;
  clientAccess: ClientProfileAccessSettings;
  clientCameraSettings: ClientProfileCameraSettings;
  dataValidationStatus: DataValidationStatus;
  authStore?: AuthStore;
  featureStore?: FeatureStore;
  history: H.History;
  transitionStore?: TransitionStore;
  toggleClientStatus(): void;
  toggleAlertStatus(): void;
  toggleAccessStatus(): void;
  toggleCameraStatus(): void;
  switchAlertTimeStatus(): void;
  switchAccessTimeStatus(): void;
  infoLoaded: boolean;
}

@inject("authStore")
@inject("featureStore")
@inject("transitionStore")
@observer
export default class ClientProfileContent extends Component<
  IClientContentProps,
  any
> {
  public render() {
    const {
      clientDetails,
      clientSettings,
      clientAccess,
      clientCameraSettings,

      history,

      authStore,
      featureStore,
      transitionStore,

      toggleClientStatus,

      toggleAccessStatus,
      switchAccessTimeStatus,

      toggleAlertStatus,
      switchAlertTimeStatus,

      toggleCameraStatus,
      infoLoaded,
      dataValidationStatus,
    } = this.props;

    if (!infoLoaded) return null;

    const { manageAccessSettings } = featureStore!.features;

    return (
      <div>
        <ul className={styles["flex-container"]}>
          <ClientProfileSensorIssuesTab
            sensorIssues={clientDetails.sensorIssues}
            history={history}
          />
          <ClientProfileClientStatusTab
            clientDetails={clientDetails}
            toggleClientStatus={toggleClientStatus}
            authStore={authStore!}
            transitionStore={transitionStore!}
          />
          <ClientProfileAlertsTab
            clientDetails={clientDetails}
            clientSettings={clientSettings}
            history={history}
            switchAlertTimeStatus={switchAlertTimeStatus}
            toggleAlertStatus={toggleAlertStatus}
            authStore={authStore!}
            transitionStore={transitionStore!}
          />
          {manageAccessSettings === true && (
            <ClientProfileAccessTab
              clientAccess={clientAccess}
              clientDetails={clientDetails}
              history={history}
              toggleAccessStatus={toggleAccessStatus}
              switchAccessTimeStatus={switchAccessTimeStatus}
              authStore={authStore!}
              transitionStore={transitionStore!}
              isAccessInformationValid={
                dataValidationStatus.buildingAccessValid
              }
            />
          )}
          <ClientProfileCameraTab
            clientCameraSettings={clientCameraSettings}
            clientDetails={clientDetails}
            history={history}
            toggleCameraStatus={toggleCameraStatus}
            authStore={authStore!}
            featuresStore={featureStore!}
            transitionStore={transitionStore!}
          />
        </ul>
      </div>
    );
  }
}
