import styles from "./search-alert.module.css";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AlertDataStore } from "../store/store";
import { FilterField, ValueData } from "../../../components/form/filter-field";
import { Search } from "../../../components/svg";
import { inject, observer } from "mobx-react";
import { AlertData } from "../store/types";

export interface ISearchAlertProps {
  onSearchChange(): void;
  alertDataStore?: AlertDataStore;
}

function SearchAlert(props: ISearchAlertProps) {
  const [isSearchItemSelected, setIsSearchItemSelected] = useState(false);
  const { onSearchChange } = props;
  const alertDataStore = props.alertDataStore!;

  const { t } = useTranslation("search-alert");

  const searchInputChanged = (value: string) => {
    setIsSearchItemSelected(false);
    alertDataStore.searchValue = value;

    if (value === "") clearSearchSelection();
  };

  const clearSearchSelection = () => {
    alertDataStore.setHilightAlert(null);
    onSearchChange();
  };

  const getSearchValues = () => {
    if (!alertDataStore.searchValue) return [];

    const filteredAlertsList = alertDataStore.alerts.filter(
      (alert: AlertData) => {
        if (alertDataStore.selectedTab === "manage-alerts") {
          if (alert.alertId && alert.alertId <= 8) return false;
          return true;
        } else if (alertDataStore.selectedTab === "default-alerts") {
          if (alert.alertId && alert.alertId <= 8) return true;
          return false;
        }
      }
    );

    return filteredAlertsList
      .filter((value: AlertData) => {
        if (value.alertName === null) return false;

        return value.alertName
          .toLowerCase()
          .includes(alertDataStore.searchValue.toLowerCase());
      })
      .sort((a: AlertData, b: AlertData) => {
        if (a.alertName === b.alertName) return 0;
        return a.alertName > b.alertName ? 1 : -1;
      })
      .map((val, index) => {
        return { key: index, object: val, displayValue: val.alertName };
      });
  };

  const setSearchSelection = (item: any) => {
    alertDataStore.setHilightAlert(item);
    alertDataStore.searchValue = item.alertName;

    setIsSearchItemSelected(true);
    onSearchChange();
  };

  const searchContentRender = (value: ValueData) => {
    return (
      <div>
        <div className={styles["alert-name"]}>{value.object.alertName}</div>
      </div>
    );
  };

  const searchValues = getSearchValues();

  return (
    <FilterField
      icon={Search}
      contentRenderer={searchContentRender}
      placeholder={t("searchAlert")}
      inputValue={alertDataStore.searchValue}
      values={searchValues}
      onValueChange={(value) => searchInputChanged(value)}
      onSelect={setSearchSelection}
      isOpen={!isSearchItemSelected && searchValues.length !== 0}
    />
  );
}

export default inject("alertDataStore")(observer(SearchAlert));
