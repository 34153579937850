import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { TextFieldInput } from "../../../../../components/form-inputs";
import { FeatureStore } from "../../../../../store";
import { AlertDataStore } from "../../../store/store";
import {
  alertDataSchema,
  alertDataSchemaMandatoryTriggerPort,
} from "../../../validation";

export interface AlertNameInputProps {
  alertDataStore?: AlertDataStore;
  featureStore?: FeatureStore;
}

function AlertNameInput(props: AlertNameInputProps) {
  const alertDataStore = props.alertDataStore!;
  const featureStore = props.featureStore!;
  const { t } = useTranslation("alert-grid");
  const alertName = alertDataStore.selectedAlert.alertName;
  const AlertNameMaximumLength = 50;
  const { errorMessages } = alertDataStore;

  return (
    <TextFieldInput
      autoFocus
      value={alertName}
      mandatory
      errorMessage={
        (errorMessages &&
          errorMessages["alertName"] &&
          t("Required information")) ||
        ""
      }
      inputProps={{ pattern: `^[A-Za-z0-9_ ]{0,${AlertNameMaximumLength}}$` }}
      label={t("alertName").toUpperCase()}
      placeholder={t("alertName")}
      onChange={(event: any) => {
        if (!event.target.validity.valid) return;

        alertDataStore.setSelectedAlertName(event.target.value);

        alertDataStore.setValidated(false);
      }}
      onValueChangeDebounced={() => {
        let schema = alertDataSchema;

        if (featureStore.features.showTriggerPort) {
          schema = alertDataSchemaMandatoryTriggerPort;
        }

        alertDataStore.validateSelectedAlert(schema, "alertName");
        alertDataStore.setValidated(true);
      }}
    />
  );
}

export default inject(
  "alertDataStore",
  "featureStore"
)(observer(AlertNameInput));
