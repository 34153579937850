import {
  ObservableMap,
  observable,
  computed,
  action,
  makeObservable,
  runInAction,
} from "mobx";
import {
  flatErrorMap,
  FlatErrorValidationResult,
} from "../../../utils/validation/flatErrorMap";
import { validateData } from "../../../utils/validation/validate";
import { cameraDataSchema } from "../validation";
import { CameraData, ILocationData } from "./types";
import { ExpandedState } from "../../../components/table/table";

export const EmptyCamera: CameraData = observable({
  cameraId: undefined,
  cameraName: "",
  cameraRtsp: "",
  cameraUrl: "",
  locations: [],
  generic: false,
});

export class CameraDataStore {
  constructor() {
    makeObservable(this, {
      validationErrors: observable,
      cameras: observable,
      locations: observable,
      sorting: observable,
      filteringLocationId: observable,
      selectedCameraHasChanges: observable,
      selectedCamera: observable,
      oldCameraData: observable,
      highlightCamera: observable,
      expandedCamera: observable,
      isLoading: observable,
      uniqueListId: observable,
      errors: observable,
      errorMessages: observable,
      validated: observable,
      isTranslationLoading: observable,
      isDataLoading: observable,
      isSaveLoading: observable,
      isDeleteLoading: observable,

      isFilteringActive: computed,
      hasSelectedCamera: computed,
      hasSelectedFilterId: computed,

      setNewCamera: action,
      setSelectedCamera: action,
      resetExpandedCamera: action,
      resetSelectedData: action,
      setFilteringData: action,
      setSelectedCameraHasChanges: action,
      setCameras: action,
      setLocations: action,
      setSelectedCameraName: action,
      setSelectedCameraRtsp: action,
      setHilightCamera: action,
      setSorting: action,
      setExpandedCamera: action,
      setIsLoading: action,
      validateSelectedCamera: action,
      setIsTranslationLoading: action,
      setIsDataLoading: action,
      setIsSaveLoading: action,
      setIsDeleteLoading: action,
      setValidated: action,
    });
  }

  public validationErrors: ObservableMap<{}> = observable.map();
  public cameras: CameraData[] = [];
  public locations: ILocationData[] = [];
  public expandedCamera: number = ExpandedState.COLLAPSED;
  public isLoading: boolean = false;
  public validated: boolean = true;
  public uniqueListId: number = 0;
  public sorting: any[] = [
    {
      id: "distance",
      desc: false,
    },
  ];

  public isTranslationLoading: boolean = false;
  public isDataLoading: boolean = false;
  public isSaveLoading: boolean = false;
  public isDeleteLoading: boolean = false;

  public filteringLocationId?: number = undefined;
  public selectedCameraHasChanges: boolean = false;
  public selectedCamera: CameraData = {
    cameraId: undefined,
    cameraName: "",
    cameraRtsp: "",
    cameraUrl: "",
    locations: [],
    generic: false,
  };
  public oldCameraData: CameraData = {
    cameraId: undefined,
    cameraName: "",
    cameraRtsp: "",
    cameraUrl: "",
    locations: [],
    generic: false,
  };
  public highlightCamera: CameraData | null = {
    cameraId: undefined,
    cameraName: "",
    cameraRtsp: "",
    cameraUrl: "",
    locations: [],
    generic: false,
  };
  public errors: FlatErrorValidationResult | null = null;
  public errorMessages: FlatErrorValidationResult | null = null;

  public get isFilteringActive() {
    return this.filteringLocationId !== null;
  }

  public get hasSelectedCamera() {
    return this.selectedCamera.cameraId !== undefined;
  }

  public get hasSelectedFilterId() {
    return this.filteringLocationId !== undefined;
  }

  public setNewCamera() {
    const data = {
      cameraId: 0,
      cameraName: "",
      cameraRtsp: "",
      cameraUrl: "",
      locations: [
        {
          locationId: 0,
          locationName: "emptyLocation",
          locationPath: "",
          distance: 0,
          listUniqueId: this.getUniqueListId(),
        },
      ],
      generic: false,
    };

    this.selectedCamera = { ...data };
    this.oldCameraData = { ...data };
    this.selectedCameraHasChanges = false;

    this.expandedCamera = ExpandedState.TOP;
  }

  public getUniqueListId() {
    this.uniqueListId++;
    return this.uniqueListId;
  }

  public setSelectedCamera(cameraData: CameraData) {
    this.selectedCamera = { ...cameraData };
    runInAction(() => {
      this.selectedCamera.locations = [...this.selectedCamera.locations];
    });

    this.selectedCamera.locations.map((location) => {
      location.listUniqueId = this.getUniqueListId();
    });

    if (this.selectedCamera.locations.length === 0) {
      runInAction(() => {
        this.selectedCamera.locations = [
          {
            locationId: 0,
            locationName: "emptyLocation",
            locationPath: "",
            distance: 0,
            listUniqueId: this.getUniqueListId(),
          },
        ];
      });
    }

    this.oldCameraData = { ...this.selectedCamera };
    this.oldCameraData.locations = [...this.oldCameraData.locations];
    this.oldCameraData.locations = this.oldCameraData.locations.map(
      (location) => {
        return { ...location };
      }
    );
  }

  public resetSelectedData() {
    this.expandedCamera = ExpandedState.COLLAPSED;
    this.selectedCamera = { ...this.selectedCamera };
    this.selectedCamera = EmptyCamera;
    this.highlightCamera = null;
    this.filteringLocationId = undefined;
  }

  public resetExpandedCamera() {
    this.expandedCamera = ExpandedState.COLLAPSED;
    this.selectedCamera = { ...this.selectedCamera };
    this.selectedCamera = EmptyCamera;
  }

  public setFilteringData(filterData: number | undefined) {
    this.filteringLocationId = filterData;
  }

  public setSelectedCameraHasChanges(value: boolean) {
    this.selectedCameraHasChanges = value;
  }

  public setCameras(value: CameraData[]) {
    this.cameras = value;
  }

  public setLocations(value: ILocationData[]) {
    this.locations = value;
  }

  public setSelectedCameraName(value: string) {
    this.selectedCamera = { ...this.selectedCamera };
    this.selectedCamera.cameraName = value;
    this.selectedCameraHasChanges = true;
  }

  public setSelectedCameraRtsp(value: string) {
    this.selectedCamera = { ...this.selectedCamera };
    this.selectedCamera.cameraRtsp = value;
    this.selectedCameraHasChanges = true;
  }

  public setSelectedCameraGeneric(value: boolean) {
    this.selectedCamera = { ...this.selectedCamera };
    this.selectedCamera.generic = value;
    this.selectedCameraHasChanges = true;
  }

  public setHilightCamera(value: CameraData | null) {
    this.highlightCamera = value;
  }

  public setSorting(value: any[]) {
    this.sorting = value;
  }

  public setExpandedCamera(value: number) {
    this.expandedCamera = value;
  }

  public setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  public setValidated(value: boolean) {
    this.validated = value;
  }

  public setIsTranslationLoading(value: boolean) {
    this.isTranslationLoading = value;
  }

  public setIsDataLoading(value: boolean) {
    this.isDataLoading = value;
  }

  public setIsSaveLoading(value: boolean) {
    this.isSaveLoading = value;
  }

  public setIsDeleteLoading(value: boolean) {
    this.isDeleteLoading = value;
  }

  public validateSelectedCamera(value: string = "") {
    const validationResult = validateData<CameraData>(
      this.selectedCamera,
      cameraDataSchema
    );

    if (validationResult) this.errors = flatErrorMap(validationResult);
    else this.errors = null;

    if (value !== "") {
      if (this.errorMessages === null) this.errorMessages = {};
      this.errorMessages![value] = this.errors![value];
    }
  }
}
