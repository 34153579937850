import { inject, observer } from "mobx-react";
import { SensorDataStore } from "../../../store/store";
import { useTranslation } from "react-i18next";
import { AuthStore } from "../../../../../store";
import { SwitchInput } from "../../../../../components/form-inputs";
import { ChangeEvent } from "react";

export interface StatusToggleProps {
  sensorDataStore?: SensorDataStore;
  authStore?: AuthStore;
}

function StatusToggle(props: StatusToggleProps) {
  const sensorDataStore = props.sensorDataStore!;
  const authStore = props.authStore!;

  const storage = sensorDataStore.selectedSensor.storage;
  const { t } = useTranslation("sensor-grid");

  return (
    <SwitchInput
      disabled={!authStore.isAdmin()}
      label={t("OnOff")}
      checked={!storage}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        sensorDataStore.setSelectedSensorStorage(!event.target.checked);
        sensorDataStore.setSelectedSensorHasChanges(true);
        sensorDataStore.validateSelectedSensor();
      }}
    ></SwitchInput>
  );
}

export default inject("sensorDataStore", "authStore")(observer(StatusToggle));
