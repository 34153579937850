import styles from "../edit-camera.module.css";

import { ILocationData } from "../../../store/types";
import { inject, observer } from "mobx-react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Component } from "react";
import { CameraDataStore } from "../../../store/store";
import { SelectInput } from "../../../../../components/form-inputs";
import { runInAction } from "mobx";

interface EditCameraLocationProps extends WithTranslation {
  cameraDataStore?: CameraDataStore;
}

@inject("cameraDataStore")
@observer
class EditCameraLocation extends Component<EditCameraLocationProps, any> {
  private emptyLocation: ILocationData = {
    locationId: 0,
    locationName: "emptyLocation",
    locationPath: "",
    distance: 0,
  };
  private cameraDataStore = this.props.cameraDataStore!;
  private locationList = this.cameraDataStore.selectedCamera.locations;
  private t = this.props.t;

  public render() {
    return this.content();
  }

  private content() {
    return this.locationList.map((element, index) => {
      const locationId = element.locationId;

      const locationDropdownData = this._buildLocationDropdownData(element);
      const emptyLocation = { id: 0, value: this.t("Add room to assign") };

      const selectedLocation =
        locationDropdownData.find(
          (value: any) => value.id === locationId
        ) || emptyLocation;

      return (
        <div key={index} className={styles["room-field"]}>
          <SelectInput
            showToolTip
            options={locationDropdownData}
            value={selectedLocation}
            onChange={(_event: any, value: any) => {
              this.onLocationChange(
                element.listUniqueId!,
                value.id || emptyLocation.id
              );
            }}
            data={locationDropdownData}
          ></SelectInput>
        </div>
      );
    });
  }

  onLocationChange = (componentId: number, newLocationId: number) => {
    const availableLocations = this.cameraDataStore.locations;

    const elementIndex =
      this.cameraDataStore.selectedCamera.locations.findIndex(
        (locDist) => locDist.listUniqueId === componentId
      );

    if (newLocationId === 0) {
      this.cameraDataStore.selectedCamera.locations.splice(elementIndex, 1, {
        ...this.emptyLocation,
        listUniqueId: componentId,
        distance: 0,
      });
    } else {
      const newLocation = availableLocations.find(
        (loc) => loc.locationId === newLocationId
      );
      runInAction(() => {
        this.cameraDataStore.selectedCamera.locations[elementIndex] = {
          ...this.cameraDataStore.selectedCamera.locations[elementIndex],
          ...newLocation,
          distance:
            this.cameraDataStore.selectedCamera.locations[elementIndex]
              .distance,
        };
      });
    }

    this.cameraDataStore!.setSelectedCameraHasChanges(true);
    this.cameraDataStore!.validateSelectedCamera();
  };

  private _buildLocationDropdownData(element: any) {
    const locations = this.getAvailableLocations();
    const original = element;
    let locationList: any = locations;

    if (
      original.locationId &&
      locationList.findIndex(
        (loc: any) => loc.locationId === original.locationId
      ) === -1
    ) {
      locationList = locationList.concat(original);
    }

    const dropdownData = locationList
      .map((value: any) => ({
        id: value.locationId,
        // locationId: value.locationId,
        // locationName: value.locationName,
        // locationPath: value.locationPath,
        value: this._formatLocationDisplay(value),
      }))
      .sort((a: any, b: any) => {
        if (a.value < b.value) {
          return -1;
        }
        if (a.value === b.value) {
          return 0;
        }
        return 1;
      });
    dropdownData.unshift({ id: 0, value: this.t("Add room to assign") });

    return dropdownData;
  }

  private getAvailableLocations() {
    const { locations, selectedCamera } = this.cameraDataStore;

    const usedLocations = selectedCamera.locations;
    return locations.filter(
      (location) =>
        !usedLocations.some(
          (usedLocation) => usedLocation.locationId === location.locationId
        )
    );
  }

  private _formatLocationDisplay(location: ILocationData) {
    return `${location.locationPath} - ${location.locationName}`;
  }
}

export default withTranslation(["camera-grid"])(EditCameraLocation);
