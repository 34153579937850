import styles from "./alert-delay.module.css";

import { observer } from "mobx-react";
import classnames from "classnames";
import Dropdown from "../../../components/dropdown-grid";
import { WithTranslation, withTranslation } from "react-i18next";
import { IAlertInfo } from "./client-settings-alert-status";
import { DelayType } from "../store/types";
import { Component } from "react";

interface IDelayProps extends WithTranslation {
  alert: IAlertInfo;
  t: any;
  onChange?(): void;
}

@observer
class Delay extends Component<IDelayProps> {
  hourValues = [0.5, 1, 1.5, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  minuteValues = [0, 1, 2, 3, 4, 5, 6, 7, 10, 15, 20, 30];
  secondValues = [0.1, 0.2, 0.5, 0.7, 1, 1.2, 1.5, 1.7, 2, 2.2, 2.5, 3];

  public render() {
    const { alert, t, onChange } = this.props;

    return (
      <div
        className={classnames(styles["delay-col"], {
          [styles["invisible"]]:
            (alert.settings.delay === null ||
              alert.settings.delay === undefined) &&
            alert.settings.delayType === 0,
        })}
      >
        <div className={styles["label"]}>
          <div>{t("Delay")}</div>
          <div className={styles["disabled"]}>({this.getUnits(alert)})</div>
        </div>
        {alert.enabled ? (
          <Dropdown
            defaultValue={this.getDefaultValue(alert)}
            data={this.getDelayValues(alert)}
            onChange={(value) => {
              alert.settings.delay = value;
              if (onChange) {
                onChange();
              }
            }}
          />
        ) : (
          <div className={styles["alert-readonly-settings-value"]}>
            {alert.settings.delay}
          </div>
        )}
      </div>
    );
  }

  private getDefaultValue(alert: IAlertInfo): number | undefined {
    if (alert.settings.delayType == DelayType.None) return;

    if (!alert.settings.delay || isNaN(alert.settings.delay))
      return this.getDelayValues(alert)[0];

    return alert.settings.delay;
  }

  private getUnits(alert: IAlertInfo): string {
    switch (alert.settings.delayType) {
      case DelayType.Seconds:
        return "sec";
      case DelayType.Minutes:
        return "min";
      case DelayType.Hours:
        return "h";
      case DelayType.None:
        return "";

      default:
        return "min";
    }
  }

  private getDelayValues(alert: IAlertInfo): number[] {
    switch (alert.settings.delayType) {
      case DelayType.Seconds:
        return this.secondValues;
      case DelayType.Minutes:
        return this.minuteValues;
      case DelayType.Hours:
        return this.hourValues;
      case DelayType.None:
        return [];

      default:
        return this.minuteValues;
    }
  }
}

export default withTranslation(["client-profile"])(Delay);
