import { IconInputField } from "../../icon-input-field";
import styles from "./icon-upload-field.module.css";

export interface DropDownFieldProps {
  label?: string;
  error?: string;
  currentIcon: string;
  iconMemberName: string;
  onIconChanged(data: FormData): void;
}

export function IconUploadField(props: DropDownFieldProps) {
  const render = () => {
    const {
      label,
      error,
      currentIcon,
      iconMemberName,
      onIconChanged,
    } = props;

    return (
      <>
        {label && <div className={styles["label"]}>{label}</div>}

        <IconInputField
          currentIcon={currentIcon}
          iconMemberName={iconMemberName}
          onIconChanged={onIconChanged}
        />

        {error && <div className={styles["intent-danger"]}>{error}</div>}
      </>
    );
  };

  return render();
}
