import "./about-dialog-content.css";
import { SmartSenseLogo, FooterLogo } from "./svg";

export interface AboutDialogProps {
  version: string;
  buildDate: string;
  t(value: string): string;
}

export function AboutDialog(props: AboutDialogProps) {
  const { version, t, buildDate } = props;

  return (
    <div className="about-dialog-content">
      <div className="left-side">
        <div className="smartsense-logo-and-name">
          <SmartSenseLogo />
          <div className="smartsense-name">Unite SmartSense</div>
        </div>
        <div className="version-information">
          <div className="version-data">
            {t("Version")}: {version} ({buildDate})
          </div>
          <div className="copyright">
            <div className="version-data">©2023 Ascom</div>
            <div className="version-data">{t("AllRightsReserved")}</div>
          </div>
        </div>
      </div>
      <div className="right-side">
        <FooterLogo className="ascom-logo-svg" />
        <div className="ascom-information">
          <div className="ascom-data">Ascom (Sweden) AB</div>
          <div className="ascom-data">Grimbodalen 2</div>
          <div className="ascom-data">SE-417 49 Göteborg</div>
          <div className="ascom-data">SWEDEN</div>
        </div>
      </div>
    </div>
  );
}
