import styles from "./client-profile-alert-settings-header.module.css";

import { observer } from "mobx-react";
import { WithTranslation, withTranslation } from "react-i18next";
import { ClientProfileDetails } from "../../store/types";
import { PageHeader } from "../../../../components/page";
import { IconButton } from "../../../../components/icon-button";
import { CrossX, Save } from "../../../../components/svg";
import { Component } from "react";
import CancelButton from "../../../../components/buttons/CancelButton";
import SaveButton from "../../../../components/buttons/SaveButton";

interface IClientAlertHeaderProps extends WithTranslation {
  clientDetails: ClientProfileDetails;
  t: any;
  title: string;
  saveAlertsSettings(): void;
  cancelAlertChanges(): void;
}

@observer
export class ClientProfileAlertSettingsHeader extends Component<
  IClientAlertHeaderProps,
  any
> {
  public render() {
    const { title, t, clientDetails, saveAlertsSettings, cancelAlertChanges } =
      this.props;

    return (
      <PageHeader className={styles["amstel-alert-header"]}>
        <div className={styles["title"]}>{t(title)}</div>
        <div className={styles["client-name"]}>{clientDetails.clientName}</div>
        <div className={styles["client-area"]}>
          <span>{clientDetails.areaName}</span>
          <span> - </span>
          <span className={styles["client-room"]}>
            {clientDetails.locationName}
          </span>
        </div>
        <div className={`${styles["header-button"]} ${styles["save-button"]}`}>
          <SaveButton variant="contained" onClick={() => saveAlertsSettings()}>
            {t("common:Save")}
          </SaveButton>
        </div>
        <div className={styles["header-button"]}>
          <CancelButton onClick={() => cancelAlertChanges()} variant="contained">
            {t("common:Cancel")}
          </CancelButton>
        </div>

      </PageHeader>
    );
  }
}
export default withTranslation(["client-profile"])(
  ClientProfileAlertSettingsHeader
);
