import { updateRequest, postRequest, deleteRequest } from "../../web";
import {
  ISensorData,
  IListSensors,
  IDeleteSensorData,
  ISensorsStates,
} from "./store/types";
import { SensorListCallIn } from "./store/typesIn";
import { SensorListCallOut, SensorStateListCallOut } from "./store/typesOut";

const resourceUrl = "/api/inventory/manage-sensors";

const webCalls = {
  readAll(sensorData: SensorListCallOut) {
    return postRequest<SensorListCallOut, SensorListCallIn>(
      `${resourceUrl}/list`,
      sensorData
    );
  },

  readSensorStates(sensorData: SensorStateListCallOut) {
    return postRequest<SensorStateListCallOut, SensorListCallIn>(
      `${resourceUrl}`,
      sensorData
    );
  },

  update(sensorData: ISensorData) {
    return updateRequest<ISensorData, null>(resourceUrl, sensorData);
  },

  remove(sensorData: IDeleteSensorData) {
    return deleteRequest<IDeleteSensorData, null>(resourceUrl, sensorData);
  },
};

export default webCalls;
