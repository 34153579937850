import styles from "./page.module.css";

import { observer, inject } from "mobx-react";
import { ClientOverviewStore, EmptyClientData } from "./store/store";
import Header from "./components/header";
import { ViewSwitch, ViewItem } from "../../components/view-switch";
import ResidentStatusGrid from "./components/resident-status-grid/ResidentStatusGrid";
import AlertOverviewGrid from "./components/alert-overview-grid/AlertOverviewGrid";
import { useEffect } from "react";
import { TransitionStore } from "../../store";
import { useTranslation } from "react-i18next";

interface IHomeProps {
  clientOverviewStore?: ClientOverviewStore;
  transitionStore?: TransitionStore;
}

export function Home(props: IHomeProps) {
  const { ready: readyClientGrid } = useTranslation("client-grid");
  const { ready: readySearch } = useTranslation("search-client");
  const clientOverviewStore = props.clientOverviewStore!;
  const { isDataLoading, isTranslationLoading } = clientOverviewStore;

  const languagesLoaded = readyClientGrid && readySearch;

  useEffect(() => {
    return () => {
      const { clientOverviewStore, transitionStore } = props;
      clientOverviewStore!.setSelectedClient(EmptyClientData);
      transitionStore!.setGlobalWarningMessage("");
    };
  }, []);

  useEffect(() => {
    const transitionStore = props.transitionStore!;

    if (!languagesLoaded) {
      clientOverviewStore.setIsTranslationLoading(true);
    } else {
      clientOverviewStore.setIsTranslationLoading(false);
    }

    if (isDataLoading || isTranslationLoading) {
      transitionStore.setLoading(true);
    } else {
      transitionStore.setLoading(false);
    }
  });

  const render = () => {
    const clientOverviewStore = props.clientOverviewStore!;

    return (
      <>
        {languagesLoaded && (
          <>
            <Header />
            <div className={styles["clients-overview-grid-container"]}>
              <ViewSwitch activeKey={clientOverviewStore.selectedTab}>
                <ViewItem itemKey="client-status">
                  <ResidentStatusGrid />
                </ViewItem>
                <ViewItem itemKey="alarm-settings">
                  <AlertOverviewGrid />
                </ViewItem>
              </ViewSwitch>
            </div>
          </>
        )}
      </>
    );
  };

  return render();
}
export default inject("clientOverviewStore", "transitionStore")(observer(Home));
