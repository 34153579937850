import styles from "./search-substatus.module.css";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SubstatusDataStore } from "../store/store";
import { FilterField, ValueData } from "../../../components/form/filter-field";
import { Search } from "../../../components/svg";
import { inject, observer } from "mobx-react";
import { SubstatusData } from "../store/types";

export interface SearchSubstatusProps {
  onSearchChange(): void;
  substatusDataStore?: SubstatusDataStore;
}

function SearchSubstatus(props: SearchSubstatusProps) {
  const [isSearchItemSelected, setIsSearchItemSelected] = useState(false);
  const substatusDataStore = props.substatusDataStore!;
  const { onSearchChange } = props;
  const { t } = useTranslation("search-substatus");

  useEffect(() => {
    return function unmount() {
      clearSearchSelection();
    };
  }, []);

  const searchInputChange = (value: string) => {
    setIsSearchItemSelected(false);
    substatusDataStore.searchValue = value;

    if (value === "") {
      clearSearchSelection();
    }
  };

  const clearSearchSelection = () => {
    if (!substatusDataStore) return;

    substatusDataStore.setHilightSubstatus(null);
    onSearchChange();
  };

  const getSearchValues = () => {
    if (!substatusDataStore.searchValue || !substatusDataStore) return [];

    const filteredSubstatusesList = substatusDataStore.substatuses.filter(
      (substatus: SubstatusData) => {
        if (substatusDataStore.selectedTab === "manage-substatuses") {
          if (substatus.statusId && substatus.statusId <= 10) return false;
          return true;
        } else if (substatusDataStore.selectedTab === "default-substatuses") {
          if (substatus.statusId && substatus.statusId <= 10) return true;
          return false;
        }
      }
    );

    return filteredSubstatusesList
      .filter((value: SubstatusData) => {
        if (value.statusName === null) return false;

        return value.statusName
          .toLowerCase()
          .includes(substatusDataStore.searchValue.toLowerCase());
      })
      .sort((a: SubstatusData, b: SubstatusData) => {
        if (a.statusName === b.statusName) return 0;
        return a.statusName > b.statusName ? 1 : -1;
      })
      .map((val, index) => {
        return { key: index, object: val, displayValue: val.statusName };
      });
  };

  const setSearchSelection = (item: any) => {
    substatusDataStore.setHilightSubstatus(item);

    substatusDataStore.searchValue = item.statusName;
    setIsSearchItemSelected(true);
    onSearchChange();
  };

  const searchContentRender = (value: ValueData) => {
    return (
      <div>
        <div className={styles["substatus-name"]}>
          {value.object.statusName}
        </div>
      </div>
    );
  };

  const searchValues = getSearchValues();

  return (
    <FilterField
      icon={Search}
      contentRenderer={searchContentRender}
      placeholder={t("SearchSubstatus")}
      inputValue={substatusDataStore.searchValue}
      values={searchValues}
      onValueChange={(value) => searchInputChange(value)}
      onSelect={setSearchSelection}
      isOpen={!isSearchItemSelected && searchValues.length !== 0}
    />
  );
}

export default inject("substatusDataStore")(observer(SearchSubstatus));
