import styles from "./client-profile-page.module.css";

import * as H from "history";
import ClientProfileContent from "./client-profile-content";
import ClientProfileHeader from "./client-profile-header";
import { observer } from "mobx-react";
import {
  ClientProfileDetails,
  ClientProfileSettings,
  ClientProfileAccessSettings,
  ClientProfileCameraSettings,
  DataValidationStatus,
} from "../../store/types";
import { Component } from "react";

interface IClientPageProps {
  history: H.History;
  clientDetails: ClientProfileDetails;
  clientSettings: ClientProfileSettings;
  clientAccess: ClientProfileAccessSettings;
  clientCameraSettings: ClientProfileCameraSettings;
  dataValidationStatus: DataValidationStatus;
  toggleClientStatus(): void;
  toggleAlertStatus(): void;
  toggleAccessStatus(): void;
  toggleCameraStatus(): void;
  switchAlertTimeStatus(): void;
  switchAccessTimeStatus(): void;
  infoLoaded: boolean;
}

@observer
export class ClientProfilePage extends Component<IClientPageProps, any> {
  public render() {
    const {
      history,
      clientDetails,
      clientSettings,
      clientAccess,
      clientCameraSettings,
      toggleClientStatus,
      toggleAlertStatus,
      toggleAccessStatus,
      toggleCameraStatus,
      switchAlertTimeStatus,
      switchAccessTimeStatus,
      infoLoaded,
      dataValidationStatus,
    } = this.props;
    return (
      <div>
        <ClientProfileHeader clientDetails={clientDetails} history={history} />
        <div className={styles["client-profile-content"]}>
          <ClientProfileContent
            clientAccess={clientAccess}
            clientSettings={clientSettings}
            clientDetails={clientDetails}
            clientCameraSettings={clientCameraSettings}
            history={history}
            toggleClientStatus={toggleClientStatus}
            toggleAlertStatus={toggleAlertStatus}
            toggleAccessStatus={toggleAccessStatus}
            toggleCameraStatus={toggleCameraStatus}
            switchAlertTimeStatus={switchAlertTimeStatus}
            switchAccessTimeStatus={switchAccessTimeStatus}
            infoLoaded={infoLoaded}
            dataValidationStatus={dataValidationStatus}
          />
        </div>
      </div>
    );
  }
}
export default ClientProfilePage;
