import { AlertSettingsData } from "./store";
import { ClientData } from "./types";
import { AlertDataIn, ClientDataIn } from "./typesIn";

export interface ClientDataConverter {
  toStore(inData: ClientDataIn): ClientData;
}

export interface AlertDataConverter {
  toStore(inData: AlertDataIn): AlertSettingsData;
}

export const ClientDataConverter: ClientDataConverter = {
  toStore(inData: ClientDataIn): ClientData {
    return {
      id: inData.id,
      name: inData.name,

      statusId: inData.statusId || 0,
      parentStatusId: inData.parentStatusId || 0,
      parentStatus: inData.parentStatus || "",
      status: inData.status || "",
      statusIcon: inData.statusIcon || "",

      alertId: inData.alertId || 0,
      alert: inData.alert || "",
      alertDelayed: inData.alertDelayed || false,
      areaId: inData.areaId || 0,
      areaName: inData.areaName || "",
      alertIcon: inData.alertIcon || "",

      locationId: inData.locationId || 0,
      locationName: inData.locationName || "",

      statusTimestamp: inData.statusTimestamp || "",
      alertTimestamp: inData.alertTimestamp || "",
      extraTimestamp: inData.extraTimestamp,

      nursePresent: inData.nursePresent || false,
      hasTemporaryProfileChange: inData.hasTemporaryProfileChange || false,
      sensorIssues: inData.sensorIssues || 0,
    };
  },
};

export const AlertDataConverter: AlertDataConverter = {
  toStore(inData: AlertDataIn): AlertSettingsData {
    return {
      id: inData.clientId,
      clientName: inData.clientName,

      areaName: inData.areaName,
      locationName: inData.locationName,

      alertSettings: {
        isActive: inData.areProfileAlertsActive,
        daytimeAlerts: inData.daytimeAlertsSettings,
        nighttimeAlerts: inData.nighttimeAlertsSettings,
      },
    };
  },
};
