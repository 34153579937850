import { updateRequest, postRequest, deleteRequest } from "../../web";
import { CameraDataOut, CameraListCallOut } from "./store/typesOut";
import { IListCameras } from "./store/types";
import { CameraListCallIn } from "./store/typesIn";

const resourceUrl = "/api/inventory/manage-cameras";

const webCalls = {
  readAll(cameraData: CameraListCallOut) {
    return postRequest<CameraListCallOut, CameraListCallIn>(
      `${resourceUrl}/list`,
      cameraData
    );
  },

  update(cameraData: CameraDataOut) {
    return updateRequest<CameraDataOut, null>(resourceUrl, cameraData);
  },

  remove(cameraId: number) {
    return deleteRequest<{ cameraId: number }, null>(resourceUrl, { cameraId });
  },
};

export default webCalls;
