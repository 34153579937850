import { AlertData, DelayType } from "./types";
import { AlertDataIn, DelayTypeIn } from "./typesIn";

export interface AlertDataConverter {
  toStore(inData: AlertDataIn): AlertData;
}

export interface DelayTypeConverter {
  toStore(inData: DelayTypeIn): DelayType;
}

export const AlertDataConverter: AlertDataConverter = {
  toStore(inData: AlertDataIn) {
    return {
      alertId: inData.alertId,
      alertName: inData.alertName,
      delayIcon: inData.delayIcon,
      delayType: inData.delayType,
      icon: inData.icon,
      triggerPort: inData.triggerPort,

      delayIconChanged: false,
      iconChanged: false,
      newDelayIcon: null,
      newIcon: null,
      isNewDelayIcon: false,
      isNewIcon: false,
    };
  },
};

export const DelayTypeConverter: DelayTypeConverter = {
  toStore(inData: DelayTypeIn) {
    return {
      id: inData.id,
      name: inData.name,
    };
  },
};
